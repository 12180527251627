import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import {toast} from "react-toastify";
import TextField from "@mui/material/TextField";
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Pagination from "@mui/lab/Pagination";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from "@mui/material";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import API from '../../utils/api';
import {api2ChildGetFinanceLog, api2PublicStorage} from "../../utils/operations";
import StyledTableRow from "../../components/StyledTableRow";

import {dateTimeFormatter, moneyFormatter, renderTransactionType} from "../../utils/misc";

const defaultFilter = {
  name: '',
  from: '',
  to: '',
  type: ''
}

const Transactions = (props) => {
  const orgId = props.Auth.orgId

  const [data, setData] = useState([])
  const [pages, setPages] = useState(1)
  const [page, setPage] = useState(1)
  const [refreshId, setRefreshId] = useState(1)

  const [filter, setFilter] = useState(defaultFilter);

  const handleChange = (event) => {
    const name = event.target.name;
    setFilter({ ...filter, [name]: event.target.value });
  };

  const handleChangeDateFrom = (event) => {
    setFilter({ ...filter, from: event.target.value });
  }

  const handleChangeDateTo = (event) => {
    setFilter({ ...filter, to: event.target.value });
  }

  const loadData = async () => {
    try {
      const response = await API.post(api2ChildGetFinanceLog, {
        filter: {
          ...filter,
          orgId
        },
        page
      })
      setData(response.data.data)
      setPages(response.data.pages)
    } catch (e) {
      toast.success('Error on data loading!');
    }
  }

  useEffect(() => { loadData() }, [page, refreshId])

  const handleFilterFormSubmit = (event) => {
    event.preventDefault()
    loadData()
  }

  const handleFilterClear = () => {
    setFilter(defaultFilter)
    setPage(1)
    setRefreshId(refreshId + 1)
  }


  return (<LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className='transactions'>
        <form onSubmit={handleFilterFormSubmit}>
          <div className="filters">
            <TextField
              label='Child Name'
              name='name'
              value={filter.name}
              onChange={handleChange}
              size="small"
              variant="standard"
            />
            <TextField
              id="min-date"
              onChange={handleChangeDateFrom}
              label="Min Date"
              type="date"
              value={filter.from}
              InputLabelProps={{ shrink: true }}
              size="small"
              variant="standard"
            />
            <TextField
              id="max-date"
              onChange={handleChangeDateTo}
              label="Max Date"
              type="date"
              value={filter.to}
              InputLabelProps={{ shrink: true }}
              size="small"
              variant="standard"
            />
            <TextField
              fullWidth id="select" label="Transaction type" select
              name='type' value={filter.type} onChange={handleChange}
              size="small"
              variant="standard"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="stripe">Stripe</MenuItem>
              <MenuItem value="depo">Cash/Check</MenuItem>
              <MenuItem value="nextmonthpayment">Next month payment</MenuItem>
              <MenuItem value="futuredepo">Future deposit</MenuItem>
              <MenuItem value="nextyeardepo">Next year deposit</MenuItem>
            </TextField>
            <Button variant="contained" type="submit">Filter</Button>
            <Button variant="outlined" onClick={handleFilterClear}>Clear</Button>
          </div>
        </form>

        <div style={{ overflowX: 'auto'}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Child ID</TableCell>
                <TableCell>Child Name</TableCell>
                <TableCell>Transaction Type</TableCell>
                <TableCell>Transaction Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Invoice</TableCell>
                <TableCell>Payment Method</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => {
                const childName = row.child ? row.child.firstname + ' ' + row.child.lastname : ''
                const invoicefile = row.archive ? row.archive.invoicefile : ( row.bill ? row.bill.invoicefile : '')
                const description = row.data ? row.data.description : ''

                return <StyledTableRow key={row.id}>
                  <TableCell>{row.child_id}</TableCell>
                  <TableCell>{childName}</TableCell>
                  <TableCell>{renderTransactionType(row.type)}</TableCell>
                  <TableCell>{dateTimeFormatter(row.created_at)}</TableCell>
                  <TableCell>{moneyFormatter(row.amount)}</TableCell>
                  <TableCell>
                    {invoicefile && <a href={api2PublicStorage + invoicefile} target="_blank">
                      <img src={require("../../images/pdf.jpg")} alt="Download PDF" />
                    </a>}
                  </TableCell>
                  <TableCell>{description}</TableCell>
                </StyledTableRow>
              })}
            </TableBody>
          </Table>
        </div>

        <Pagination
          className='paginationContainer' variant="outlined" shape="rounded"
          page={page} count={pages} onChange={(e, page) => setPage(page)}
        />

      </div>
    </LocalizationProvider>
  );
}

export default inject("Auth")(observer(Transactions))
