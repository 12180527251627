import {
  getTokenUrl,
  profile,
  dreamUsers, getAllOrg, api2AdminByEmail
} from "../utils/operations";
import {makeObservable, observable, computed, action, runInAction} from "mobx";
import API from '../utils/api';

class AuthClass {
  drawer = false;
  user = null;
  show_finance = false;
  edit_finance = false;
  profile = null;
  role = null;
  availableOrgs = [];
  allOrgs = {};
  orgId = null;
  orgName = null;
  child_id = null;
  snackBar = false;
  menuVisible = false;
  pdfFile = "";
  addParentToChildId = null;

  constructor() {
    makeObservable(this, {
      drawer: observable,
      user: observable,
      show_finance: observable,
      edit_finance: observable,
      profile: observable,
      role: observable,
      availableOrgs: observable,
      allOrgs: observable,
      orgId: observable,
      orgName: observable,
      child_id: observable,
      snackBar: observable,
      menuVisible: observable,
      pdfFile: observable,
      addParentToChildId: observable,
      isLoggedIn: computed,
      isSuperAdmin: computed,
      isInvitationsSentForCurrentOrg: computed,
      init: action,
      login: action,
      getProfile: action,
      checkIfEmailExist: action,
      getOrgName: action,
      getAllOrgs: action,
      setUser: action,
      setOrgId: action,
      setMenuVisible: action,
      getRole: action,
      can: action,
      logout: action,
    })
  }
  get isLoggedIn() {
    return !!this.user;
  }

  get isSuperAdmin() {
    return this.role === "superadmin";
  }

  get isInvitationsSentForCurrentOrg() {
    return this.allOrgs.hasOwnProperty(this.orgId) && this.allOrgs[this.orgId].invitation_sent
  }

  async init() {
    if (localStorage.hasOwnProperty("token")) {
      runInAction(() => {
        this.orgId = localStorage.getItem('orgId', 1)
        this.user = JSON.parse(localStorage.getItem('user'))
      })
      await this.getProfile()
      await this.getOrgName()
    }
  }

  login(email, password) {
    return API.post(getTokenUrl, {
        email: email,
        password: password
      })
  }

  async getProfile() {
    await this.getRole();
    return API.get(api2AdminByEmail, {params: {email: this.user.email}})
      .then(res => {
        const gotProfile = res.data.admin;
        const gotId = gotProfile.organisation_id === 0 ? 1 : gotProfile.organisation_id;

        localStorage.setItem("profile", JSON.stringify(gotProfile));

        runInAction(() => {
          this.profile = gotProfile;
          this.availableOrgs = res.data.organizations;

          if (this.role === "superadmin") {
            this.show_finance = true
            this.edit_finance = true
          } else {
            this.show_finance = this.role === "admin" ? res.data.admin.show_finance : false
            this.edit_finance = this.role === "admin" ? res.data.admin.edit_finance : false
          }
        })

        this.setOrgId(localStorage.getItem("orgId") || gotId)

        return gotProfile;
      });
  }

  async checkIfEmailExist(email) {
    try {
      const res = await API.get(dreamUsers + `?filter=email%3D'${email}'`);
      return res.data.resource.length > 0;
    } catch (e) {
      return false;
    }
  }

  getOrgName() {
    if (this.allOrgs.hasOwnProperty(this.orgId)) {
      this.orgName = this.allOrgs[this.orgId].name

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve("success");
        }, 100);
      });
    } else {
      return API.get(getAllOrg).then(res => {
        let allOrgs = {}
        res.data.resource.forEach(item => allOrgs[item.id] = item)

        runInAction(() => {
          this.allOrgs = allOrgs
          this.orgName = this.allOrgs[this.orgId].name
        })
      });
    }
  }

  getAllOrgs() {
    return API.get(getAllOrg).then(res => {
      let allOrgs = {}
      res.data.resource.forEach(item => allOrgs[item.id] = item)
      runInAction(() => {
        this.allOrgs = allOrgs
      })
    });
  }

  setUser(value) {
    this.user = value
    localStorage.setItem("token", value.session_token);
    localStorage.setItem("user", JSON.stringify(value));
  }

  setOrgId(value) {
    this.orgId = value.toString()
    localStorage.setItem("orgId", this.orgId)
  }

  setMenuVisible(value) {
    this.menuVisible = value
  }

  getRole() {
    return API.get(profile).then(res => {
      runInAction(() => {
        this.role = res.data.phone
      })

      return res.data.phone
    });
  }

  can(permission) {
    if (this.role === "superadmin") return true;

    if (this.profile) {
      switch (permission) {
        case 'invoices':
        case 'programs':
        case 'sendarchive':
        case 'mealmenu':
          return this.profile.show_finance;

        case 'allfeatureinvoices':
          return this.profile.show_future_invoices;

        case 'dashboard':
          return this.profile.show_charts;

        default:
          return false;
      }
    }

    return false;
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("profile");
    localStorage.removeItem("role");
    localStorage.removeItem("orgId");
    localStorage.removeItem("last_action_time");
    this.role = null;
    this.user = null;
    this.profile = null;
    this.orgId = null;
    this.orgName = null;
    this.child_id = null;
    this.pdfFile = null;
    this.snackBar = false;
    this.addParentToChildId = null;
  }
}

const Auth = new AuthClass();
export default Auth;

