import React, { Component } from "react";
import axios from "axios";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import LinearProgress from "material-ui/LinearProgress";
import DropDownMenu from "material-ui/DropDownMenu";
import API from '../../utils/api';

import {
  getCurrentOrg,
  getAllClasses,
  api2Class, api2ClassesByOrganizationId
} from "../../utils/operations.js";
import { observer, inject } from "mobx-react";
import { getAllChildren } from "../../utils/operations";
import { toast } from 'react-toastify';

const divProgressHide = {
  display: "none"
};
const divProgressShow = {
  display: "inline"
};

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        organisation_id: "",
        name: ""
      },
      organisations: [], // Список организаций
      opendialog: false, // Диалог с ошибками
      openerrordialog: false,
      texterrors: "", // Список ошибок
      showProgress: true,
      changeClass: null,
      gotClasses: null
    };
  }

  handleOpen = () => {
    this.setState({ opendialog: true });
  };

  handleClose = () => {
    this.setState({ showProgress: false });
    this.setState({ opendialog: false });
  };

  // Записать State в state.data.st
  handleStateChange = (event, index, value) => {
    const data = this.state.data;
    data.st = value;
    this.setState({ data });
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const itemId = this.props.match.params.id;
    let orgId = this.props.Auth.orgId;

    let that = this;
    axios
      .all([
        API.get(getAllClasses + itemId),
        API.get(getCurrentOrg),
        API.get(api2ClassesByOrganizationId(orgId))
      ])
      .then(
        axios.spread(function(classes, organisations, allclasses) {
          const data = classes.data;
          const organisationsList = organisations.data.resource;
          const allClasses = allclasses.data.data;

          let currentClass = itemId;
          let gotClasses = [];
          for (let i = 0; i < allClasses.length; i++) {
            if (allClasses[i].id != currentClass) {
              gotClasses.push(allClasses[i]);
            }
          }

          that.setState({ gotClasses });
          that.setState({ data: data, organisations: organisationsList });
          that.setState({ showProgress: false });
          if (gotClasses.length > 0) {
            that.setState({ changeClass: gotClasses[0].id });
          }
          that.forceUpdate();
        })
      );
  };

  deleteData = () => {
    this.setState({ opendialog: false });

    const base_url = api2Class(this.state.data.id);
    API.delete(base_url).then(() => {
      toast.success('Class deleted successfully!')
      this.props.history.push("/classes");
    }).catch((error) => {
      let errorMessage = error.response.data.error;

      let table = "Information table";

      if (errorMessage.includes('bills')) {
        table = "Invoices";
      }  else if (errorMessage.includes('teachers')) {
        table = "Teachers";
      }  else if (errorMessage.includes('childs')) {
        table = "Children";
      }  else if (errorMessage.includes('archive')) {
        table = "Archive";
      }

      let textError = "You can't delete class '" + this.state.data.name + "' ";
      textError += "while '" + table + "' corresponding rows exist";

      toast.error(textError, {autoClose: 5000})
    });
  };

  saveData = () => {
    this.setState({ showProgress: true });

    let data = {
      name: this.state.data.name,
      // organisation_id: this.state.data.organisation_id
    };

    const base_url = getAllClasses + this.state.data.id;
    API.patch(base_url, data).then(() => {
      toast.success('Class edited successfully!')
      this.props.history.push("/classes");
    }).catch(function() {
      toast.error('Something wrong!')
    });
  };

  handleOrgChange = (event, index, value) => {
    const data = this.state.data;
    data.organisation_id = value;
    this.setState({ data });
  };

  handleChange = event => {
    const data = this.state.data;
    data[event.target.id] = event.target.value;
    this.setState({ data });
  };

  goPreviousPage = () => {
    this.props.history.goBack();
  };

  transferClass = () => {
    let that = this;
    let classId = this.props.match.params.id;
    let filter = `?filter=(organisation_id%3D${
      this.props.Auth.orgId
    })AND(classes_id%3D${classId})`;

    API.get(getAllChildren + filter).then(res => {
      let children = res.data.resource;
      let newdata = [];
      for (let i = 0; i < children.length; i++) {
        newdata.push({
          id: children[i].id,
          classes_id: this.state.changeClass
        });
      }

        API.put(getAllChildren, { resource: newdata }).then(() => {
          setTimeout(that.props.history.push("/classes"), 1000);
        });
      });
  };

  render() {
    const actions = [
      <FlatButton key='btn-cancel' label="Cancel" primary={true} onClick={this.handleClose} />,
      <FlatButton key='btn-ok' label="OK" primary={true} onClick={this.deleteData} />
    ];
    const errors = [
      <FlatButton key='btn-ok' label="Ok" primary={true} onClick={this.handleClose} />
    ];
    return (
      <div style={{ width: 500, margin: "auto" }}>
        {this.state.gotClasses && this.state.changeClass ? (
          <div>
            Transfer class to:
            <br />
            <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
              <DropDownMenu
                value={this.state.changeClass}
                onChange={(e, i, v) => {
                  this.setState({ changeClass: v });
                }}
              >
                {this.state.gotClasses.map(row => (
                  <MenuItem
                    key={row.id}
                    value={row.id}
                    primaryText={row.name}
                  />
                ))}
              </DropDownMenu>
              <FlatButton
                label="Transfer"
                primary={true}
                onClick={this.transferClass}
              />
            </div>
            <br />
            <br />
          </div>
        ) : null}

        <div
          style={this.state.showProgress ? divProgressShow : divProgressHide}
        >
          <LinearProgress mode="indeterminate" />
        </div>
        <TextField
          id="name"
          hintText="Class Name"
          errorText="This field is required"
          fullWidth
          value={this.state.data.name}
          onChange={this.handleChange}
        />
        <br />
        <SelectField
          id="organisation"
          floatingLabelText="School"
          errorText="This field is required"
          fullWidth
          disabled
          value={this.state.data.organisation_id}
          onChange={this.handleOrgChange}
        >
          {this.state.organisations.map(row => (
            <MenuItem key={row.id} value={row.id} primaryText={row.name} />
          ))}
        </SelectField>
        <br />
        <div
          style={{
            display: "flex",
            gap: 10,
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 20,
            paddingBottom: 20
          }}
        >
          <RaisedButton
            label="Save"
            primary={true}
            onClick={this.saveData}
          />
          <RaisedButton
            label="Cancel"
            primary={true}
            style={{ backgroundColor: "#ffd699" }}
            onClick={this.goPreviousPage}
          />
          {this.props.Auth.isSuperAdmin && <RaisedButton
            label="Delete"
            secondary={true}
            onClick={this.handleOpen}
          />}
        </div>

        <Dialog
          actions={actions}
          modal={false}
          contentStyle={{ width: "20%", maxWidth: "none" }}
          open={this.state.opendialog}
          onRequestClose={this.handleClose}
        >
          Are you sure you want to delete the class? This action cannot be undone.
        </Dialog>
        <Dialog
          actions={errors}
          modal={false}
          contentStyle={{ width: "20%", maxWidth: "none" }}
          open={this.state.openerrordialog}
          onRequestClose={this.handleClose}
        >
          {this.state.texterrors}
        </Dialog>
      </div>
    );
  }
}

export default inject("Auth")(observer(EditUser))
