import React, { Component } from "react";
import moment from "moment";

import { getMailing } from "../../utils/operations.js";
import { observer, inject } from "mobx-react";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";
import TimePicker from "material-ui/TimePicker";
import DropDownMenu from "material-ui/DropDownMenu";
import MenuItem from "material-ui/MenuItem";
import Toggle from "material-ui/Toggle";
import API from '../../utils/api'
import {toast} from "react-toastify";


class InvoiceSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      textSubject: "",
      textMail: "",
      organisation_id: 1,
      newDay: "",
      newTime: ""
    };
  }

  componentDidMount() {
    this.props.Auth.snackBar = false;
    this.loadData();
  }

  loadData = async () => {
    let res = await API.get(getMailing);

    this.setState({ data: res.data.resource });
  };

  getHours = time => {
    if (!time) return 0;

    let hour = time.split(":");
    return Number(hour[0]);
  };

  getMinutes = time => {
    if (!time) return 0;

    let minutes = time.split(":");
    return Number(minutes[1]);
  };

  handleSubject = event => {
    let index = Number(event.target.id);
    const data = this.state.data;
    data[index].subject = event.target.value;

    this.setState({ data });
  };

  handleText = event => {
    let index = Number(event.target.id);
    const data = this.state.data;
    data[index].text = event.target.value;

    this.setState({ data });
  };

  changeDay = (event, index, val, value) => {
    let data = this.state.data;
    data[index].day = value;
    this.setState({ data });
  };

  handleTime = index => (event, value) => {
    let data = this.state.data;
    data[index].time = moment(value).format("hh:mm:ss");
  };

  handleFee = (index, value) => {
    const data = this.state.data;
    data[index].apply_fee = value;

    this.setState({ data });
  };

  saveData = async (id) => {
    try {
      const mailingToChange = this.state.data.find(m => m.id === id)
      await API.patch(getMailing, { resource: [mailingToChange] })
      toast.success('The Schedule "' + mailingToChange.subject + '" was changed successfully.')
    } catch (e) {
      toast.error("Something wrong.")
    }
  };

  render() {
    const days = [];
    const daysInMonth = moment().daysInMonth();
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(<MenuItem value={i} key={i} primaryText={`${i}`} />);
    }

    return (
      <div style={{ maxWidth: 800, margin: "auto" }}>
        {this.state.data.map((row, index) => (
          <div key={index} style={{ margin: "auto", border: '1px solid lightgrey', padding: '20px', borderRadius: '10px', marginBottom: '10px' }}>
            <div>
              <TextField
                id={index.toString()}
                floatingLabelText="Mail Subject"
                fullWidth={true}
                value={row.subject}
                onChange={this.handleSubject}
              />
            </div>
            {row.is_scheduled && <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
              <div style={{flex: 1}}>
                <span style={{color: "gray"}}>Select day:</span>
                <br/>
                <DropDownMenu
                  id={row.id}
                  name={row.id}
                  maxHeight={300}
                  value={row.day}
                  onChange={(e, value, val) => this.changeDay(e, index, value, val)}
                >
                  {days}
                </DropDownMenu>
              </div>
              <div style={{flex: 1}}>
                <span style={{color: "gray"}}>Select time:</span>
                <br/>
                <TimePicker
                  id={index.toString()}
                  name={row.id}
                  value={
                    new Date(
                      2019,
                      1,
                      1,
                      this.getHours(row.time),
                      this.getMinutes(row.time),
                      0,
                      0
                    )
                  }
                  minutesStep={5}
                  onChange={this.handleTime(index)}
                  style={{paddingTop: 8}}
                />
              </div>
              {(row.id > 1) && <div style={{flex: 1}}>
                <span style={{color: "gray"}}>Apply Fee:</span>
                <br/>
                <div style={{paddingTop: '24px'}}>
                  <Toggle
                    defaultToggled={row.apply_fee}
                    onToggle={(event) => this.handleFee(index, event.target.checked)}
                  />
                </div>
              </div>}
            </div>}
            <div>
              <TextField
                id={index.toString()}
                name={row.id}
                floatingLabelText="Mailing Text"
                fullWidth={true}
                multiLine={true}
                rows={4}
                rowsMax={10}
                value={row.text}
                onChange={this.handleText}
              />
            </div>
            <RaisedButton
              label="Save"
              primary={true}
              onClick={() => this.saveData(row.id)}
            />
          </div>
        ))}
      </div>
    );
  }
}

export default inject("Auth")(observer(InvoiceSchedule))
