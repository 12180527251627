import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import moment from "moment";
import get from "lodash/get";
import { isEmpty } from 'lodash';
import html2pdf from "html2pdf.js";

import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";
import LinearProgress from "material-ui/LinearProgress";
import { divProgressHide, divProgressShow } from "../../css/additional.js";

import withStyles from '@mui/styles/withStyles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import TableSortLabel from '@mui/material/TableSortLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from "@mui/material";

import {
  getCurrentPlans,
  api2Children, api2ClassesByOrganizationId
} from "../../utils/operations.js";
import API from '../../utils/api';
import { api2ChildDroppedByOrganizationId } from '../../utils/operations';
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from '@mui/material/InputLabel';
import Box from "@mui/material/Box";

const style = {
  marginLeft: 'calc(50% - 20px)',
  marginTop: 30,
};

const ClickableRow = props => {
  const { childID, history, ...restProps } = props;

  const editData = value => {
    history.push("/editchild/" + value);
  };

  return (
    <TableRow {...restProps} onMouseDown={() => editData(childID)} style={{cursor: 'pointer'}}>
      {props.children}
    </TableRow>
  );
};

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(142, 195, 242, 0.4)',
    },
    '&:hover': {
      backgroundColor: '#f5f5f5',
    }
  },
}))(ClickableRow);


class Children extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orgId: props.Auth.orgId,
      data: [],
      childrenDroppedData: [],
      childrenData: [],
      name: [],
      plans: [],
      credits: [],
      classes: [],
      invoices: [],
      usephoto: [],
      afterschool: [],
      filteredItems: [],
      showInvoice: 0,
      showProgress: true,
      disableInput: false,
      disableDropdown: false,
      programEnabled: false,
      classEnabled: 0
    };
  }

  async componentDidMount() {
    this.timer = null
    this.props.Auth.child_id = null
    this.props.ChildrenPageState.setFilter({
      childId: '',
      childName: '',
      childEmail: '',
    })
    await this.prepareAllData(this.props.Auth.orgId)
    if (this.props.ChildrenPageState.kind === "By drop date") {
      this.showDropDate()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.Auth.orgId !== this.state.orgId) {
      this.setState({ orgId: prevProps.Auth.orgId })
      this.prepareAllData(prevProps.Auth.orgId)
    }
  }

  componentWillUnmount() {
    this.setState({ filteredItems: [], childrenData: null });
  }

  loadChildrenData = orgId => {
    return API.get(api2Children, {
      params: {
        with: 'specials,parents,classes,futurePlans',
        organisation_id: orgId,
        children_page: true,
      }
    });
  }

  loadPlansData = () => {
    return API.get(getCurrentPlans + "?filter=in_filter%3D1");
  }

  loadCreditPlans = () => {
    return API.get(getCurrentPlans + "?filter=plan_group_id%3D3");
  }

  loadClassesData = orgId => {
    return API.get(api2ClassesByOrganizationId(orgId));
  }

  organizeChildData = dbData => {
    let data = [];
    for (let item of dbData.data) {
      let parentEmails = "";
      if (item.parents.length > 0) {
        for (let parent of item.parents) {
          parentEmails += parent.email + ",";
        }
      }

      let childPlans = item.future_plans.map(plan => plan.id);

      data.push({
        id: item.id,
        firstname: item.firstname,
        lastname: item.lastname,
        birthday: item.birthday,
        email: parentEmails,
        usephoto: item.usephoto,
        _created: item._created,
        startdate: item.startdate,
        dropdate: item.dropdate,
        isUpcoming: moment(item.startdate).isSameOrAfter(moment()),
        acs: item.acs,
        afterschool: item.afterschool,
        specials: item.specials,
        className: item.classes ? item.classes.name : null,
        childPlans: childPlans,
      });
    }

    return data;
  }

  prepareAllData = async orgId => {
    this.setState({ showProgress: true });

    const [
      allData,
      allPlans,
      creditPlans,
      allClasses
    ] = await Promise.all([
      this.loadChildrenData(orgId),
      this.loadPlansData(),
      this.loadCreditPlans(),
      this.loadClassesData(orgId)
    ]);

    this.setState({ showProgress: false });

    if (!allData.data || allData.data.length === 0) return;

    let childrenData = this.organizeChildData(allData);

    this.setState({
      childrenData,
      filteredItems: childrenData,
      plans: allPlans.data.resource,
      credits: creditPlans.data.resource,
      classes: allClasses.data.data
    });

    this.timer = setTimeout(() => {
      this.applyFilters()
    }, 1000)
  }

  showACS = () => {
    this.props.ChildrenPageState.setKind("ACS")
    this.props.ChildrenPageState.setFilter({
      acs: 'true',
      afterschool: '',
    })

    this.timer = setTimeout(() => {
      this.applyFilters()
    }, 1000)
  };

  showAfterschool = () => {
    this.props.ChildrenPageState.setKind("Afterschool")
    this.props.ChildrenPageState.setFilter({
      acs: '',
      afterschool: 'true',
    })

    this.timer = setTimeout(() => {
      this.applyFilters()
    }, 1000)
  }

  showUpcoming = () => {
    this.props.ChildrenPageState.setKind("Upcoming")
    this.props.ChildrenPageState.setFilter({
      upcoming: true,
    })

    this.timer = setTimeout(() => {
      this.applyFilters()
    }, 1)
  }

  showDropDate = () => {
    // this.clearFilter()

    API.get(api2ChildDroppedByOrganizationId(this.props.Auth.orgId), {
      params: {
        with: 'specials,parents,classes,futurePlans',
      }
    })
      .then(res => {
        let childrenDroppedData = this.organizeChildData(res);
        this.setState({ childrenDroppedData });
        this.props.ChildrenPageState.setKind("By drop date")
        this.applyFilters()
      });
  }

  handleChangeFilter = (event) => {
    clearTimeout(this.timer)
    const name = event.target.name
    const value = event.target.value

    this.props.ChildrenPageState.setFilter({
      [name]: value
    })

    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        [name]: value
      }
    }))

    this.timer = setTimeout(() => {
      this.applyFilters()
    }, 1000)
  }

  handleChangeCheckbox = (event) => {
    const name = event.target.name;

    this.props.ChildrenPageState.setFilter({
      [name]: event.target.checked
    })


    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        [name]: event.target.checked
      }
    }))

    this.timer = setTimeout(() => {
      this.applyFilters()
    }, 1000)
  };

  applyFilters = () => {
    let filteredItems = this.props.ChildrenPageState.kind === 'By drop date' ? this.state.childrenDroppedData : this.state.childrenData;
    const filter = this.props.ChildrenPageState.filter
    const filterKeys = Object.keys(filter)

    filterKeys.forEach(filterName => {
      const filterValue = (typeof filter[filterName] === 'string' ? filter[filterName].toLowerCase() : filter[filterName])
      if (filterValue !== "" && filterValue !== false) {
        filteredItems = filteredItems.filter(child => {
          switch (filterName) {
            case 'childEmail':
              return get(child, 'email').toLowerCase().includes(filterValue)
            case 'childId':
              return get(child, 'id') === +filterValue
            case 'childName':
              return get(child, 'firstname').toLowerCase().includes(filterValue) || get(child, 'lastname').toLowerCase().includes(filterValue)
            case 'class':
              return get(child, 'className').toLowerCase() === filterValue
            case 'program':
              return child.childPlans && get(child, 'childPlans').includes(+filterValue)
            case 'credits':
              return child.childPlans && get(child, 'childPlans').includes(+filterValue)
            case 'acs':
              return get(child, 'acs') === true
            case 'upcoming':
              return get(child, 'isUpcoming') === true
            case 'afterschool':
              return !isEmpty(get(child, 'afterschool'))
            case 'hasSpecials':
              return !isEmpty(get(child, 'specials.additionally')) || !isEmpty(get(child, 'specials.allergies'))
            default:
              return true
          }
        })
      }
    })

    if (filter.upcoming) {
      filteredItems = filteredItems.sort((a, b) => {
        return moment(a.startdate) - moment(b.startdate)
      })
    } else {
      filteredItems = filteredItems.sort(this.getSortFunction())
    }

    this.setState({ filteredItems });
  }

  getSortFunction = () => {
    const sortBy = this.props.ChildrenPageState.sortBy
    const sortWay = this.props.ChildrenPageState.sortWay

    switch (sortBy) {
      case '_created':
        return (a, b) => {
          if (!b._created) { return -1; }

          return sortWay === 'asc' ? moment(a._created) - moment(b._created) : moment(b._created) - moment(a._created)
        }

      case 'startdate':
        return (a, b) => {
          return sortWay === 'asc' ? moment(a.startdate) - moment(b.startdate) : moment(b.startdate) - moment(a.startdate)
        }

      case 'birthday':
        return (a, b) => {
          return sortWay === 'asc' ? moment(a.birthday) - moment(b.birthday) : moment(b.birthday) - moment(a.birthday)
        }

      case 'usephoto':
        return (a, b) => {
          return sortWay === 'asc' ? a.usephoto - b.usephoto : b.usephoto - a.usephoto
        }

      case 'lastname':
        return (a, b) => {
          return sortWay === 'asc' ? a.lastname.localeCompare(b.lastname) : b.lastname.localeCompare(a.lastname)
        }
    }

    return (a, b) => {
      return sortWay === 'asc' ? a.id - b.id : b.id - a.id
    }
  }

  changeSort = (sortBy) => {
    let newSortBy = this.props.ChildrenPageState.sortBy
    let newSortWay
    if (this.props.ChildrenPageState.sortBy === sortBy) {
      newSortWay = this.props.ChildrenPageState.sortWay === 'asc' ? 'desc' : 'asc'
    } else {
      newSortBy = sortBy
      newSortWay = 'asc'
    }

    this.props.ChildrenPageState.setSorting(newSortBy, newSortWay)

    this.timer = setTimeout(() => {
      this.applyFilters()
    }, 100)
  }

  resetAll = () => {
    this.props.ChildrenPageState.setKind("All")
    this.clearFilter()

    this.setState({
      filteredItems: this.state.childrenData,
      disableInput: false,
      disableDropdown: false,
      programEnabled: false,
      classEnabled: 0,
    });
  };

  clearFilter = () => {
    this.props.ChildrenPageState.setFilter({
      upcoming: '',
      acs: '',
      afterschool: '',
      childId: '',
      childName: '',
      childEmail: '',
      credits: '',
      program: '',
      class: '',
      hasSpecials: false,
    })
  };

  newChild = () => {
    const newUrl = "/newchild";
    this.props.history.push(newUrl);
  };

  Print = () => {
    let classNames = {};
    for (let item of this.state.classes) {
      classNames[item.id] = item.name;
    }

    let plansNames = {};
    for (let item of this.state.plans) {
      plansNames[item.id] = item.name;
    }

    let header = "";
    switch (this.props.ChildrenPageState.kind) {
      case "filter_programs":
        header = `<h1>${this.props.ChildrenPageState.filter.program}</h1>`;
        break;
      case "filter_classes":
        header = `<h1>${this.props.ChildrenPageState.filter.class}</h1>`;
        break;
      case "filter_classes_programs":
        header = `<h1>Plan: ${this.props.ChildrenPageState.filter.program}</h1>`;
        header += `<h2>Class: ${this.props.ChildrenPageState.filter.class}</h2>`;
        break;
      default:
        header = `<h1>${this.props.ChildrenPageState.kind}</h1>`;
        break;
    }

    let source = header;
    source += '<table style="width: 100%; border: 1px solid black; border-collapse: collapse">';
    source +=
      "<tr>" +
      "<td style='border: 1px solid black; border-collapse: collapse; padding: 2px 4px;'>First Name</td>" +
      "<td style='border: 1px solid black; border-collapse: collapse; padding: 2px 4px;'>Last Name</td>" +
      "<td style='border: 1px solid black; border-collapse: collapse; padding: 2px 4px;'>Class</td>" +
      "<td style='border: 1px solid black; border-collapse: collapse; padding: 2px 4px;'>Start Date</td>" +
      "<td style='border: 1px solid black; border-collapse: collapse; padding: 2px 4px;'>Allergies and special needs</td>" +
      "</tr>";

    this.state.filteredItems.map(rows => {
      source += "<tr>";
      source += `<td style="border: 1px solid black; border-collapse: collapse; padding: 2px 4px;">${rows.firstname}</td>`;
      source += `<td style="border: 1px solid black; border-collapse: collapse; padding: 2px 4px;">${rows.lastname}</td>`;
      source += `<td style="border: 1px solid black; border-collapse: collapse; padding: 2px 4px;">${
        typeof rows.className === "undefined"
          ? classNames[rows.classes_id]
          : rows.className
      }</td>`;
      source += `<td style="border: 1px solid black; border-collapse: collapse; padding: 2px 4px;">${moment(rows.startdate).format("MM/DD/YYYY")}</td>`;
      source += `<td style="border: 1px solid black; border-collapse: collapse; padding: 2px 4px;">Special needs:  ${rows.specials?.additionally}<br/>Allergies: ${rows.specials?.allergies}</td>`;
      source += "</tr>";
    });
    source += "</table>";

    html2pdf(source, {
      margin: 0.4,
      filename: "file.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" }
    });
  };

  render() {
    const edit = this.props.history;
    return (
      <div className="children">
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Button
            variant={this.props.ChildrenPageState.kind === 'Afterschool' ? 'contained' : 'outlined'}
            color="primary"
            onClick={this.showAfterschool}
            disabled={this.state.disableDropdown || this.state.disableInput}
          >Filter by Afterschool Program</Button>

          <Button
            variant={this.props.ChildrenPageState.kind === 'ACS' ? 'contained' : 'outlined'}
            color="primary"
            onClick={this.showACS}
            disabled={this.state.disableDropdown || this.state.disableInput}
          >Filter by ACS</Button>
          <Button
            variant={this.props.ChildrenPageState.kind === 'Upcoming' ? 'contained' : 'outlined'}
            color="primary"
            onClick={this.showUpcoming}
            disabled={this.state.disableDropdown || this.state.disableInput}
          >Upcoming Students</Button>
          <Button
            variant={this.props.ChildrenPageState.kind === 'By drop date' ? 'contained' : 'outlined'}
            color="primary"
            onClick={this.showDropDate}
            disabled={this.state.disableDropdown || this.state.disableInput}
          >Dropped Students</Button>
        </Box>

        <br />
        <div className="childrenFilters">
          <TextField
            fullWidth={true}
            name="childId"
            placeholder="Client ID"
            value={this.props.ChildrenPageState.filter.childId}
            onChange={this.handleChangeFilter}
            size="small"
            variant="standard"
          />

          <TextField
            fullWidth={true}
            name="childName"
            placeholder="Name"
            value={this.props.ChildrenPageState.filter.childName}
            onChange={this.handleChangeFilter}
            size="small"
            variant="standard"
          />

          <TextField
            fullWidth={true}
            name="childEmail"
            placeholder="Email"
            value={this.props.ChildrenPageState.filter.childEmail}
            onChange={this.handleChangeFilter}
            size="small"
            variant="standard"
          />

          <FormControl fullWidth>
            <InputLabel>Filter by Program:</InputLabel>
            <Select
              name="program"
              value={this.props.ChildrenPageState.filter.program}
              onChange={this.handleChangeFilter}
              size="small"
              variant="standard"
            >
              {this.state.plans.map(row => (
                <MenuItem key={'program-' + row.id} value={row.id} >{row.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Filter by Credits:</InputLabel>
            <Select
              name="credits"
              value={this.props.ChildrenPageState.filter.credits}
              onChange={this.handleChangeFilter}
              size="small"
              variant="standard"
            >
              {this.state.credits.map(row => (
                <MenuItem key={'credit-' + row.id} value={row.id} >{row.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Filter by Class:</InputLabel>
            <Select
              name="class"
              value={this.props.ChildrenPageState.filter.class}
              onChange={this.handleChangeFilter}
              size="small"
              variant="standard"
            >
              {this.state.classes.map(row => (
                <MenuItem key={'class-' + row.id} value={row.name}>{row.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControlLabel control={<Checkbox name='hasSpecials' checked={this.props.ChildrenPageState.filter.hasSpecials} onChange={this.handleChangeCheckbox} />} label="Allergies and special needs" />
        </div>

        <div className="childrenFiltersButtons">
          <Button variant="outlined" onClick={this.resetAll} >Clear Filter</Button>
          <Button variant="outlined" style={{ marginLeft: 10 }} onClick={this.Print} >Print</Button>
        </div>

        <br />
        <div style={{ overflowX: 'scroll'}}>
        <Table className="childrenList">
          <TableHead>
            <TableRow>
              <TableCell width={30}></TableCell>
              <TableCell>
                <TableSortLabel
                  active={this.props.ChildrenPageState.sortBy === 'id'}
                  direction={this.props.ChildrenPageState.sortBy === 'id' ? this.props.ChildrenPageState.sortWay : 'asc'}
                  onClick={() => this.changeSort('id')}
                >
                  ID
                </TableSortLabel>
              </TableCell>
              <TableCell className="minWidthCell">First Name</TableCell>
              <TableCell className="minWidthCell">
                <TableSortLabel
                  active={this.props.ChildrenPageState.sortBy === 'lastname'}
                  direction={this.props.ChildrenPageState.sortBy === 'lastname' ? this.props.ChildrenPageState.sortWay : 'asc'}
                  onClick={() => this.changeSort('lastname')}
                >
                  Last Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={this.props.ChildrenPageState.sortBy === 'usephoto'}
                  direction={this.props.ChildrenPageState.sortBy === 'usephoto' ? this.props.ChildrenPageState.sortWay : 'asc'}
                  onClick={() => this.changeSort('usephoto')}
                >
                  Use Photo
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={this.props.ChildrenPageState.sortBy === 'birthday'}
                  direction={this.props.ChildrenPageState.sortBy === 'birthday' ? this.props.ChildrenPageState.sortWay : 'asc'}
                  onClick={() => this.changeSort('birthday')}
                >
                  Birthday
                </TableSortLabel>
              </TableCell>
              <TableCell>
                {this.props.ChildrenPageState.kind === 'By drop date' && 'Drop Date'}
                {this.props.ChildrenPageState.kind !== 'By drop date' && <TableSortLabel
                  active={this.props.ChildrenPageState.sortBy === 'startdate'}
                  direction={this.props.ChildrenPageState.sortBy === 'startdate' ? this.props.ChildrenPageState.sortWay : 'asc'}
                  onClick={() => this.changeSort('startdate')}
                >
                  Start Date
                </TableSortLabel>}
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={this.props.ChildrenPageState.sortBy === '_created'}
                  direction={this.props.ChildrenPageState.sortBy === '_created' ? this.props.ChildrenPageState.sortWay : 'asc'}
                  onClick={() => this.changeSort('_created')}
                >
                  Created Date
                </TableSortLabel>
              </TableCell>
              <TableCell className="minWidthCell">Allergies and special needs</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.filteredItems &&
              this.state.filteredItems.map((row, index) => (
                <StyledTableRow key={'child-' + index} childID={row.id} history={edit}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.firstname}</TableCell>
                  <TableCell>{row.lastname}</TableCell>
                  <TableCell>{row.usephoto ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    {moment(row.birthday).format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell>
                    {this.props.ChildrenPageState.kind === 'By drop date' &&
                      (row.dropdate === null
                        ? null
                        : moment(row.dropdate).format("MM/DD/YYYY"))
                    }
                    {this.props.ChildrenPageState.kind !== 'By drop date' &&
                      (row.startdate === null
                        ? null
                        : moment(row.startdate).format("MM/DD/YYYY"))
                    }
                  </TableCell>
                  <TableCell>
                    {row._created ? moment(row._created).format("MM/DD/YYYY") : null }
                  </TableCell>
                  <TableCell>
                    Special needs:  {row.specials?.additionally}
                    <br/>Allergies: {row.specials?.allergies}
                  </TableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
        </div>
        <div style={this.state.showProgress ? divProgressShow : divProgressHide}>
          <LinearProgress mode="indeterminate" />
        </div>
        <FloatingActionButton style={style} secondary={true} onClick={this.newChild}>
          <ContentAdd />
        </FloatingActionButton>
      </div>
    );
  }
}

export default inject("Auth", "ChildrenPageState")(observer(Children))
