import React, { Component} from 'react';

import Paper from 'material-ui/Paper';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';

import { observer, inject } from 'mobx-react';

import {
    blue800,
} from 'material-ui/styles/colors';

const style = {
    height: 350,
    width: 400,
    margin: 20,
    borderRadius: 10,
    textAlign: 'center',
    display: 'inline-block',
};

class ChangePassword extends Component {

    render() {
        return (
            <div style={{width: 500, margin: 'auto'}}>
                <Paper style={style} zDepth={1}>
                    <div style={{width: 250, margin: 'auto'}}>
                        <br/>
                        <span style={{color: blue800, fontSize: 20}}>Change password</span>
                        <br/>
                        <br/>
                        <TextField
                            id="password"
                            hintText="Old password"
                            fullWidth={true}
                            type="password"
                        />
                        <br />
                        <br />
                        <TextField
                            id="password"
                            hintText="New password"
                            fullWidth={true}
                            type="password"
                        />
                        <br />
                        <TextField
                            id="password"
                            hintText="Repeat new password"
                            fullWidth={true}
                            type="password"
                        />
                        <br />
                        <br />
                        <RaisedButton label="Clear" primary={true} style={{marginRight: 10}} />
                        <RaisedButton label="Change" primary={true} style={{marginLeft: 10}} />
                    </div>
                </Paper>
            </div>
        )
    }
}

export default inject("Auth")(observer(ChangePassword))
