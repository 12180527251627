import React, {useEffect, useState} from "react";

import { observer, inject } from "mobx-react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {toast} from "react-toastify";
import {
	api2ChildDeleteMedicalRecord,
	api2ChildEditMedicalRecord,
	api2ChildStoreMedicalRecord
} from "../../utils/operations";

import API from '../../utils/api';
import Loader from "../../components/Loader";

const MedicalRecord = (props) => {

	const [loading, setLoading] = useState(false)
	const [id, setId] = useState(null)
	const [title, setTitle] = useState('')
	const [expirationDate, setExpirationDate] = useState(null)

	useEffect(() => {
		const medicalRecordId = props.match.params.medicalRecordId

		if (medicalRecordId) {
			loadMedicalRecord()
			setId(medicalRecordId)
		}
	}, [])

	const loadMedicalRecord = async () => {
		const childId = props.match.params.id
		const medicalRecordId = props.match.params.medicalRecordId
		setLoading(true)
		try {
			const result = await API.get(api2ChildEditMedicalRecord(childId, medicalRecordId))
			setTitle(result.data.data.title)
			setExpirationDate(new Date(result.data.data.expiration_date))
		} catch (e) {
			toast.error('Something went wrong!')
		}
		setLoading(false)
	}

	const deleteMedicalRecord = async () => {
		if (!confirm('Are you sure ? Want to delete medical record ?')) {
			return
		}

		const childId = props.match.params.id
		const goUrl = "/editchild/" + childId;
		const medicalRecordId = props.match.params.medicalRecordId

		setLoading(true)
		try {
			await API.delete(api2ChildDeleteMedicalRecord(childId, medicalRecordId))
			toast.success('Medical Record successfully deleted!')

			setTimeout(props.history.push(goUrl), 1000);
		} catch (e) {
			toast.error('Something went wrong!')
		}
		setLoading(false)
	}

	const goPreviousPage = () => {
		const childId = props.match.params.id
		const goUrl = "/editchild/" + childId;
		setTimeout(props.history.push(goUrl), 1000);
	};

	const saveMedicalRecord = async () => {
		const childId = props.match.params.id
		const goUrl = "/editchild/" + props.Auth.child_id;

		try {
			await API.post(api2ChildStoreMedicalRecord(childId), {
				id: id,
				title: title,
				expiration_date: expirationDate?.toDateString(),
			})

			toast.success('Medical Record successfully added!')
			setTimeout(props.history.push(goUrl), 1000);
		} catch (e) {
			toast.error('Something went wrong!')
		}
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<div style={{ maxWidth: 500, margin: "auto" }}>
				<Loader loaded={!loading}>
					<h3>{id ? 'Edit Medical Record' : 'Add Medical Record'}</h3>
					<Stack gap="20px">
						<TextField
							label='Title'
							value={title}
							onChange={(event) => setTitle(event.target.value)}
							size="small"
							variant="standard"
						/>

						<DatePicker
							slotProps={{ textField: { variant: 'standard' }}}
							views={['year', 'month', 'day']}
							label="Expiration date"
							value={expirationDate}
							onChange={setExpirationDate}
						/>
					</Stack>

					<Stack direction="row" sx={{ padding: "20px 0"}} gap="20px">
						<Button variant="contained" onClick={() => saveMedicalRecord()} >Save</Button>
						<Button variant="outlined" onClick={goPreviousPage} >Cancel</Button>
						{id && <Button variant="contained" color="error" onClick={deleteMedicalRecord}>Delete</Button>}
					</Stack>
				</Loader>
			</div>
		</LocalizationProvider>
	)
}


export default inject("Auth")(observer(MedicalRecord))
