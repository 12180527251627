import React, { Component } from "react";
import AppBar from "material-ui/AppBar";
import LeftMenu from "./LeftMenu.jsx";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import DropDownMenu from "material-ui/DropDownMenu";
import IconButton from "material-ui/IconButton";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import Drawer from "material-ui/Drawer";
import styles from "../css/main.css";
import classNames from "classnames/bind";
import NavigationMenu from "material-ui/svg-icons/navigation/menu";
import {withRouter} from "react-router-dom";
import { observer, inject } from "mobx-react";
import {isMobile} from "react-device-detect";

let classnames = classNames.bind(styles);

let timer = null

const LoggedAdmin = props => {
  return <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
    <DropDownMenu
      value={props.orgId}
      onChange={props.handleChangeOrg}
      labelStyle={{ color: "#fff" }}
      underlineStyle={{ display: "none" }}
    >
      {props.role === 'admin' && props.availableOrgs.map((org) => {
        return <MenuItem key={org.id} value={org.id.toString()} primaryText={org.name} />
      })}
      {props.role === 'superadmin' && Object.values(props.allOrgs).map((org) => {
        return <MenuItem key={org.id} value={org.id.toString()} primaryText={org.name} />
      })}
    </DropDownMenu>

    <span className="baseWellCome">
      {props.user ? `Welcome, ${props.user.name}!` : ""}
    </span>

    <IconMenu
      iconButtonElement={
        <IconButton style={{ color: "white" }}>
          <MoreVertIcon color={"white"} />
        </IconButton>
      }
      targetOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <MenuItem
        primaryText="Change password"
        onClick={() => props.goTo("/changepassword")}
      />
      <MenuItem
        primaryText="Sign out"
        onClick={() => props.signOut()}
      />
    </IconMenu>
  </div>
}

class Layout extends Component {

  maxTime = 30 * 60 * 1000

  constructor(props) {
    super(props);

    this.state = {
    };

    this.props.Auth.init().then(() => {
      const last_action_time = parseInt(localStorage.getItem('last_action_time'))
      if (!this.props.Auth.isLoggedIn || (Date.now() - last_action_time > this.maxTime)) {
        this.props.Auth.logout()
        if (this.props.history.location.hash !== '#/login') {
          this.props.history.push("/login");
        }
      } else {
        this.props.history.push(this.props.history.location)
      }
    }).catch(() => {
      this.props.Auth.logout()
      this.props.history.push("/login")
    })
  }

  goTo = path => this.props.history.push(path)

  signOut = async () => {
    await this.props.Auth.logout()
    this.props.Auth.setMenuVisible(false)
    this.props.history.push("/login");
  }

  handleToggle = () => {
    this.props.Auth.setMenuVisible(!this.props.Auth.menuVisible)
  };

  componentDidMount() {
    this.props.Auth.setMenuVisible(this.props.Auth.isLoggedIn)
    window.addEventListener('click', this.saveLastAction);
    window.addEventListener('keypress', this.saveLastAction);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (isMobile) {
        this.props.Auth.setMenuVisible(false)
      } else {
        this.props.Auth.setMenuVisible(this.props.Auth.isLoggedIn)
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.saveLastAction);
    window.removeEventListener('keypress', this.saveLastAction);
  }

  saveLastAction = () => {
    localStorage.setItem('last_action_time', Date.now().toString())
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.props.Auth.logout()
      this.props.history.push("/login")
    }, this.maxTime)
  }

  handleChangeOrg =  async (event, index, value) => {
    localStorage.setItem("orgId", value)
    this.props.Auth.setOrgId(value)
    await this.props.Auth.getOrgName()

    this.props.Auth.child_id = null
    // this.props.history.push(this.props.history.location)
    setTimeout(this.props.history.push("/children"), 1000);
  };

  render() {
    switch (this.props.Auth.role) {
      case "admin":
      case "superadmin":
      default:
        return (
          <div>
            <AppBar
              id="app-bar"
              className="app-bar"
              titleStyle={{ flex: 0}}
              iconElementLeft={this.props.Auth.isLoggedIn ?
                <IconButton onClick={this.handleToggle} >
                  <NavigationMenu />
                </IconButton> : null
              }
            >
              <h2 className="app-title">{this.props.Auth.orgName || "Steps to Success"}</h2>
              {this.props.Auth.isLoggedIn && <LoggedAdmin
                role={this.props.Auth.role}
                user={this.props.Auth.profile}
                orgId={this.props.Auth.orgId}
                allOrgs={this.props.Auth.allOrgs}
                availableOrgs={this.props.Auth.availableOrgs}
                handleChangeOrg={this.handleChangeOrg}
                goTo={this.goTo}
                signOut={this.signOut}
              />}
            </AppBar>
            <Drawer id="app-menu" className="no-print" width={210} open={this.props.Auth.menuVisible}>
              <AppBar title="Menu"
                iconElementLeft={<IconButton onClick={this.handleToggle}><NavigationMenu/></IconButton>}
              />
              <LeftMenu />
            </Drawer>
            <div className={classnames("app-content", { expanded: this.props.Auth.menuVisible })} >
              <div style={{ width: "96%", margin: "auto" }}>{this.props.children}</div>
            </div>
          </div>
        );
    }
  }
}
export default inject("Auth")(withRouter(observer(Layout)))
