import React from "react";

export const AttendanceClassesTabs = ({ classes, selectedClass, savedClasses, onChange }) => {
	return (
		<div className="attendanceClasses">
			{classes.map( orgClass => {
				return <div
					key={orgClass.id}
					className={`attendanceClassesItem ${selectedClass === orgClass.id && 'attendanceClassesItem--active'} ${savedClasses.includes(orgClass.id) && 'attendanceClassesItem--saved'}`}
					onClick={() => onChange(orgClass.id)}>
					{orgClass.name}
				</div>
			})}
		</div>
	)
}
