import React, { Component} from 'react';

import Paper from 'material-ui/Paper';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';

import { observer, inject } from 'mobx-react';

import {
  blue800,
} from 'material-ui/styles/colors';

const errorStyle = {
  fontSize: 13,
  color: 'red'
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: ''
    };
  }

  handleClear = () => {
    this.setState({email: '', password: ''})
  };

  handleSubmit = () => {
    this.props.Auth.login(this.state.email, this.state.password)
      .then((res) => {
        this.props.Auth.setUser(res.data)

        this.props.Auth.getProfile().then(() => {
          if (this.props.Auth.role === 'admin' || this.props.Auth.role === 'superadmin') {
            this.props.Auth.getOrgName().then(() => this.props.history.push('/children'))
          } else {
            this.props.history.push('/login');
          }
        });
      })
      .catch((err) => {
        switch(err.response.status) {
          case 400:
            this.setState({error: 'Fill Email and/or Password fields'});
            break;
          case 401:
            this.setState({error: 'Wrong Email and/or Password fields'});
            break;
          default:
            this.setState({error: err.message});
        }
      });
  };

  keyPressed = (event) => {
    if (event.key === "Enter") {
      this.handleSubmit();
    }
  };

  render() {
    return (
      <Paper zDepth={1} className="loginPaper">
        <span style={{color: blue800, fontSize: 20}}>Login</span>
        <br/>
        <br/>
        <span style={errorStyle}>{this.state.error}</span>
        <br/>
        <TextField
          id="email"
          hintText="Email"
          fullWidth={true}
          value={this.state.email}
          onChange={(e) => this.setState({email: e.target.value})}
        />
        <br />
        <TextField
          id="password"
          hintText="Password"
          fullWidth={true}
          type="password"
          value={this.state.password}
          onChange={(e) => this.setState({password: e.target.value})}
          onKeyPress={this.keyPressed}
        />
        <br />
        <br />
        <RaisedButton
          label="Clear"
          primary={true}
          style={{marginRight: 10}}
          onClick={this.handleClear}

        />
        <RaisedButton
          label="Login" primary={true}
          style={{marginLeft: 10}}
          onClick={this.handleSubmit}
        />
      </Paper>
    )
  }
}

export default inject("Auth")(observer(Login))
