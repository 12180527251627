import React, { Component } from "react";

import {
  getSendArchive,
  getChildsByOrg
} from "../../utils/operations.js";
import { observer, inject } from "mobx-react";
import TextField from "material-ui/TextField";
import DropDownMenu from "material-ui/DropDownMenu";
import MenuItem from "material-ui/MenuItem";
import AutoComplete from "material-ui/AutoComplete";
import FlatButton from "material-ui/FlatButton";
import moment from "moment";
import Dialog from "material-ui/Dialog";
import RaisedButton from "material-ui/RaisedButton";
import Snackbar from "material-ui/Snackbar";
import { api2ChildGetArchive, api2ChildSendArchive, api2PublicStorage } from "../../utils/operations";
import API from '../../utils/api';
import { toast } from 'react-toastify';

const dataSourceConfig = {
  text: "textKey",
  value: "valueKey"
};

const today = new Date();
const currentYear = parseInt(today.getFullYear(), 0);

class SendArchive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: currentYear,
      data: {},
      children: [],
      search: "",
      child: 0,
      invoices: [],
      opendialog: false,
      error: "",
      send: false,
      snackBar: false
    };
  }

  componentDidMount() {
    if (!this.props.Auth.can('sendarchive')) return this.props.history.push("/children");
    this.props.Auth.snackBar = false;
    this.loadData();
  }

  loadData = async () => {
    let orgId = this.props.Auth.orgId;
    let org = await API.get(getSendArchive + `/${orgId}?related=organisations_by_org_id`);

    let res = await API.get(getChildsByOrg + orgId);

    let children = [];
    for (let item of res.data.resource) {
      children.push({
        textKey: item.firstname + " " + item.lastname,
        valueKey: item.id
      });
    }

    this.setState({
      data: org.data,
      children
    });
  };

  clearData = async () => {
    this.setState({
      year: 2021,
      data: {},
      children: [],
      search: "",
      child: 0,
      invoices: [],
      opendialog: false,
      error: "",
      send: false,
      snackBar: false
    });

    await this.loadData();
  };

  sendData = async () => {
    try {
      await API.get(api2ChildSendArchive(this.state.child), {
        params: {
          year: this.state.year
        }
      });

      toast.success('Archive was sent successfully!')

      this.setState({
        snackBar: true
      });
    } catch (e) {
      if (e.response.data.error.message) {
        this.setState({
          error: e.response.data.error.message,
          opendialog: true
        });
      }
    }
  };

  changeYear = (event, index, value) => {
    this.setState({ year: value });
    if (this.state.send) {
      setTimeout(() => {this.showPreview()}, 100)
    }
  };

  autoCompleteChild = value => {
    this.setState({ child: value.valueKey });
    if (this.state.send) {
      setTimeout(() => {this.showPreview()}, 100)
    }
  };

  base64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  showPreview = async () => {
    try {
      let res_invoices = await API.get(api2ChildGetArchive(this.state.child), {
        params: {
          year: this.state.year
        }
      });

      this.setState({
        send: true,
        invoices: res_invoices.data
      });
    } catch (e) {
      if (e.response.data.error.message) {
        this.setState({
          error: e.response.data.error.message,
          opendialog: true
        });
      }
    }
  };

  saveData = async () => {
    try {
      let data = {
        director: this.state.data.director,
        text: this.state.data.text
      };

      await API.patch(getSendArchive + "/" + this.state.data.id, data)
    } catch (e) {
      console.log(e);
    }
  };

  closeError = () => {
    this.setState({
      error: "Error happens",
      opendialog: false
    });
  };

  snackRequestClose = () => {
    this.setState({
      snackBar: false
    });
  };

  handleText = event => {
    let data = this.state.data;
    data[event.target.id] = event.target.value;
    this.setState({ data });
  };

  render() {
    const years = [];
    for (let i = 0; i < 6; i++) {
      years.push(
        <MenuItem value={currentYear - i} key={currentYear - i} primaryText={`${currentYear - i}`} />
      );
    }

    const actions = [
      <FlatButton label="OK" primary={true} onClick={this.closeError} />
    ];

    return (
      <div style={{ maxWidth: 500, margin: "auto" }}>
        <div style={{ flex: 1 }}>
          <span style={{ color: "gray" }}>Select year:</span>
          <br />
          <DropDownMenu
            maxHeight={300}
            value={this.state.year}
            onChange={(e, value, val) => this.changeYear(e, value, val)}
          >
            {years}
          </DropDownMenu>
          <br />
          {this.state.children.length > 0 && (
            <AutoComplete
              floatingLabelText="Child (Firstname Lastname)"
              filter={AutoComplete.caseInsensitiveFilter}
              fullWidth={true}
              dataSource={this.state.children}
              dataSourceConfig={dataSourceConfig}
              onNewRequest={value => this.autoCompleteChild(value)}
            />
          )}
        </div>
        {this.state.data && (
          <div>
            <TextField
              id="text"
              floatingLabelText="Mailing Text"
              fullWidth={true}
              multiLine={true}
              rows={4}
              value={this.state.data.text}
              onChange={this.handleText}
            />
            <br />
            <TextField
              id="director"
              floatingLabelText="Director Name"
              fullWidth={true}
              value={this.state.data.director}
              onChange={this.handleText}
            />
            <RaisedButton label="Save" primary={true} onClick={this.saveData} />
          </div>
        )}
        <br />
        <div style={{ borderTopWidth: 1, borderColor: "gray" }}>
          <span style={{ color: "gray" }}>Year: {this.state.year}</span>
          <br />
          {this.state.invoices.length > 0 &&
            this.state.invoices.map((invoice, index) => (
              <div>
                <a
                  key={index}
                  href={api2PublicStorage + invoice.invoicefile}
                  target="_blank"
                >
                  <img src={require("../../images/pdf.jpg")} alt="Download PDF" />
                </a>
                &nbsp;&nbsp;&nbsp;
                {moment(invoice._created).format("MMMM")}
              </div>
            ))}
        </div>
        <br />
        {this.state.send ? (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <RaisedButton label="Send" primary={true} onClick={this.sendData} />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <RaisedButton
              label="Clear"
              secondary={true}
              onClick={this.clearData}
            />
          </div>
        ) : (
          <FlatButton
            label="Show Preview"
            primary={true}
            onClick={this.showPreview}
          />
        )}
        <br />
        <br />
        <br />

        <Dialog
          actions={actions}
          modal={false}
          contentStyle={{ width: "20%", maxWidth: "none" }}
          open={this.state.opendialog}
          onRequestClose={this.closeError}
        >
          {this.state.error}
        </Dialog>
        <Snackbar
          open={this.state.snackBar}
          message="Mail sent"
          autoHideDuration={3000}
          onRequestClose={this.snackRequestClose}
        />
      </div>
    );
  }
}

export default inject("Auth")(observer(SendArchive))
