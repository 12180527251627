import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import {PlusIcon} from "../../components/icons/PlusIcon";
import Button from "@mui/material/Button";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {AttendanceSwitch} from "../../components/AttendanceSwitch";
import withStyles from "@mui/styles/withStyles";
import appRoutes from "../../utils/appRoutes";
import API from "../../utils/api";
import {api2VaccineRecord, api2VaccineRecords} from "../../utils/operations";
import {toast} from "react-toastify";
import Loader from "../../components/Loader";

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(142, 195, 242, 0.4)',
    },
    '&:hover': {
      backgroundColor: '#f5f5f5',
    }
  },
}))(TableRow);

const VaccineRecords = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])


  const loadData = async () => {
    setLoading(true)
    try {
      const response = await API.get(api2VaccineRecords)
      setData(response.data.data)
    } catch (e) {
      toast.error('Error on data loading!');
    }
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  const handleRecordSwitch = async (recordId, event) => {
    const checked = event.target.checked

    try {
      await API.post(api2VaccineRecord(recordId), {is_completed: checked})
      loadData()
    } catch (e) {
      toast.error('Error on data loading!');
    }
  }

  return <div className="medicalRecords">
    <h1 className="pageTitle">Medical Records</h1>
    <Button href={appRoutes.addVaccineRecord} component="button" variant="text" sx={{ color: "black" }} startIcon={<PlusIcon />}>
      Add new record
    </Button>
    <Loader loaded={!loading}>
      <Table sx={{ mt: 4, minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow sx={{ fontSize: 16, fontWeight: "bold" }}>
            <TableCell>Medical record name</TableCell>
            <TableCell sx={{ textAlign: "center"}}>Doses needed</TableCell>
            <TableCell sx={{ textAlign: "center"}}>Completed status</TableCell>
            <TableCell>Completed doses</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(vaccine => {
            return <StyledTableRow key={'vaccine' + vaccine.id} >
              <TableCell scope="row">
                {vaccine.title}
              </TableCell>
              <TableCell sx={{ textAlign: "center"}} scope="row">
                {vaccine.doses_needed}
              </TableCell>
              <TableCell sx={{ textAlign: "center"}} scope="row">
                <AttendanceSwitch
                  color="success"
                  checked={vaccine.is_completed}
                  onChange={(event) => handleRecordSwitch(vaccine.id, event)}
                />
              </TableCell>
              <TableCell scope="row">
                {vaccine.doses_completed}
              </TableCell>
              <TableCell scope="row">
                <Button href={appRoutes.editVaccineRecord(vaccine.id)} variant="text" >
                  Edit
                </Button>
              </TableCell>
            </StyledTableRow>
          })}

        </TableBody>
      </Table>
    </Loader>
  </div>
}


export default inject("Auth")(observer(VaccineRecords))
