import React, { Component } from "react";

import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";
import Toggle from "material-ui/Toggle";
import LinearProgress from "material-ui/LinearProgress";
import { observer, inject } from "mobx-react";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import API from '../../utils/api';

import {
  getCurrentParent,
  getCurrentOrg,
  getAllCTP, api2ParentSendInvitationToCabinet, api2Parent, api2ParentRemoteLogin
} from "../../utils/operations.js";
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import {Link} from "react-router-dom";
import {checkEmail, checkParentHasPaymentRegistration} from "../../utils/misc";
import {checkIfParentExistWithEmail} from "../../utils/apiUtils";

const divProgressHide = {
  display: "none"
};
const divProgressShow = {
  display: "inline"
};

class EditParent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        photo: "undefined", // Файл
        photofile: "", // Картинка в аватаре
        firstname: "",
        lastname: "",
        organisation_id: "",
        email: "",
        phone: "",
        phone2: "",
        phone3: "",
        address: "",
        childs: "",
        notify_on: true
      },
      oldEmail: "",
      hasPaymentsProfile: false,
      hasActiveChildren: false,
      organisations: [], // Список организаций
      opendialog: false, // Диалог с ошибками
      texterrors: "", // Список ошибок
      showProgress: true,
      children: null
    };
  }

  handleOpen = () => {
    this.setState({ opendialog: true });
  };

  handleClose = () => {
    this.setState({ showProgress: false });
    this.setState({ opendialog: false });
  };


  async componentDidMount() {
    if (!this.props.Auth.isLoggedIn) {
      this.props.history.push("/login");
    } else {
      await this.loadData();
    }
  }

  hasDifferentOrganisation() {
    return !!(this.state.children && this.state.children.find(c => c.organisation_id != this.state.data.organisation_id));
  }

  loadCurrentParent = async () => {
    const itemId = this.props.match.params.id;

    try {
      return await API.get(api2Parent(itemId), {
        params: {
          with: 'children',
          append: 'stripe_customer,stripe_payment_methods',
        }
      });
    } catch (error) {
      return null;
    }
  };

  loadCurrentOrganisation = async () => {
    try {
      return await API.get(getCurrentOrg + this.props.Auth.orgId);
    } catch (error) {
      return null;
    }
  };

  loadData = async () => {
    this.setState({ showProgress: true });

    let [parent] = await Promise.all([
      this.loadCurrentParent(),
    ]);

    let hasPaymentsProfile = false;
    let hasActiveChildren = false;

    let parentData = parent.data.data;
    let oldEmail = parentData.email;
    let children = parentData.children;

    if (checkParentHasPaymentRegistration(parentData)) {
      hasPaymentsProfile = true;
    }

    if (children.find(c => c.dropdate == null)) {
      hasActiveChildren = true
    }

    this.setState({
      data: parentData,
      children,
      hasPaymentsProfile,
      hasActiveChildren,
      oldEmail: oldEmail
    });

    this.setState({ showProgress: false });
    // this.forceUpdate();
  };

  deleteData = async () => {
    this.setState({ opendialog: false });
    const base_url = getCurrentParent + this.state.data.id;

    try {
      await API.delete(getAllCTP + "?filter=parent_id%3D" + this.state.data.id.toString())
      await API.delete(base_url)

      toast.success('Parent deleted successfully!');
      const goUrl = "/children";
      this.props.history.push(goUrl);
    } catch (e) { /* empty */ }
  };

  sendInvitation = async () => {
    if (!window.confirm('Are you sure ? \nParent will receive invitation!')) return;

    try {
      await API.get(api2ParentSendInvitationToCabinet(this.state.data.id))
      toast.success("Invitation was sent successfully")
    } catch (e) {
      toast.error(e.response.data.error)
    }
  };

  remoteLogin = async () => {
    if (!window.confirm('Are you sure ? \nYou will be logged in parent portal in new tab!')) return;

    try {
      const response = await API.get(api2ParentRemoteLogin(this.state.data.id))
      window.open(response.data.parents_url, '_blank');

    } catch (e) {
      toast.error(e.response.data.error)
    }
  };

  saveData = async () => {
    let existInAPI = false
    const email = this.state.data.email
    const oldEmail = this.state.oldEmail

    if (!checkEmail(email)) {
      return toast.error('Email must be valid!', { autoClose: 5000 })
    }

    if (email !== oldEmail) {
      existInAPI = await checkIfParentExistWithEmail(this.state.data.email);
      if (existInAPI) {
        return toast.error('Parent with this email is already existed. Please use another one!', { autoClose: 5000 })
      }
    }

    this.setState({ showProgress: true })

    const data = {
      firstname: this.state.data.firstname,
      lastname: this.state.data.lastname,
      organisation_id: this.state.data.organisation_id,
      email: this.state.data.email,
      phone: this.state.data.phone,
      phone2: this.state.data.phone2,
      phone3: this.state.data.phone3,
      address: this.state.data.address,
      notify_on: this.state.data.notify_on
    };

    try {
      await API.patch(api2Parent(this.state.data.id), data)
      toast.success('Parent saved successfully!');
      // setTimeout(this.props.history.push("/parents"), 1000);
    } catch (e) {
      toast.error('Error on parent saving!');
    } finally {
      this.setState({ showProgress: false });
    }
  };

  handleOrgChange = (event, index, value) => {
    const data = this.state.data;
    data.organisation_id = value;
    this.setState({ data });
  };

  handleChange = event => {
    const data = this.state.data;
    data[event.target.id] = event.target.value;
    this.setState({ data });
  };

  handleToggle = () => {
    let data = this.state.data;
    data.notify_on = !data.notify_on;

    this.setState({ data });
  };

  render() {
    const actions = [
      <FlatButton key={'hc'} label="Cancel" primary={true} onClick={this.handleClose} />,
      <FlatButton key={'hd'} label="OK" primary={true} onClick={this.deleteData} />
    ];
    return (
      <div style={{ width: 500, margin: "auto" }}>
        <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
          <div style={{ flex: 7 }}>&nbsp;</div>
          <div style={{ flex: 1 }}>
            <Toggle
              label="Toggle notification"
              defaultToggled={this.state.data.notify_on}
              onToggle={this.handleToggle}
              style={{ marginBottom: 16 }}
            />
          </div>
        </div>
        <div>
          <p>Children:</p>
          {this.state.children
            ? this.state.children.map(row => (
                <p key={row.id}>
                  <Link to={`/editchild/${row.id}`}>{row.firstname} {row.lastname} {row.dropdate != null && ' (DROPPED)'}</Link>
                </p>
              ))
            : null}
        </div>
        <br />
        <div style={this.state.showProgress ? divProgressShow : divProgressHide}>
          <LinearProgress mode="indeterminate" />
        </div>
        {this.hasDifferentOrganisation() && <div className='alert alert--error'>Has a different organization than children! Please fix and save!</div>}
        <TextField
          id="firstname"
          hintText="First Name"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.firstname}
          onChange={this.handleChange}
        />
        <TextField
          id="lastname"
          hintText="Last Name"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.lastname}
          onChange={this.handleChange}
        />
        <SelectField
          id="organisation"
          floatingLabelText="School"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.organisation_id}
          onChange={this.handleOrgChange}
        >
          {Object.values(this.props.Auth.allOrgs).map(row => (
            <MenuItem key={row.id} value={row.id} primaryText={row.name} />
          ))}
        </SelectField>
        <TextField
          id="email"
          hintText="Email"
          fullWidth={true}
          value={this.state.data.email}
          onChange={this.handleChange}
        />
        <InputMask
          mask="999-999-9999 ext. 999"
          fullWidth={true}
          value={this.state.data.phone}
          onChange={this.handleChange}
          id="phone" hintText="Phone 1"
          disabled={false}
        >
          {(inputProps) => <TextField {...inputProps} />}
        </InputMask>
        <InputMask
          mask="999-999-9999 ext. 999"
          fullWidth={true}
          value={this.state.data.phone2}
          onChange={this.handleChange}
          id="phone2" hintText="Phone 2"
          disabled={false}
        >
          {(inputProps) => <TextField {...inputProps} />}
        </InputMask>
        <InputMask
          mask="999-999-9999 ext. 999"
          fullWidth={true}
          value={this.state.data.phone3}
          onChange={this.handleChange}
          id="phone3" hintText="Phone 3"
          disabled={false}
        >
          {(inputProps) => <TextField {...inputProps} />}
        </InputMask>
        <TextField
          id="address"
          hintText="Address"
          fullWidth={true}
          value={this.state.data.address}
          onChange={this.handleChange}
        />
        <br />
        <table>
          <tbody>
            <tr>
              <td>Payment Portal Status:</td>
              <td>{this.state.hasPaymentsProfile ? 'Registered' : 'Unregistered'}</td>
            </tr>
            <tr>
              <td>Payment Methods:</td>
              <td>
                {this.state.data.stripe_payment_methods && this.state.data.stripe_payment_methods.map(method => {
                  return <p key={method.id}>{method.name}{method.is_auto_pay && ' - Autopay'}</p>
                })}
              </td>
            </tr>
            {this.state.hasPaymentsProfile && <tr>
              <td colSpan={2}>
                <a style={{ cursor: 'pointer' }} onClick={this.remoteLogin}>Log in as a parent to the Parent portal</a>
              </td>
            </tr>}
          </tbody>
        </table>

        {this.state.hasActiveChildren && !this.state.hasPaymentsProfile && <RaisedButton
          style={{ marginBottom: '20px', marginTop: '10px' }}
          label="Send Payment Invitation"
          primary={true}
          onClick={this.sendInvitation}
        />}

        <br />
        <div style={{ display: 'flex', gap: '10px', marginTop: '30px' }} >
          <RaisedButton
            label="Save"
            primary={true}
            onClick={this.saveData}
          />
          <RaisedButton
            label="Cancel"
            onClick={() => this.props.history.push("/parents")}
          />
          <RaisedButton
            label="Delete"
            secondary={true}
            onClick={this.handleOpen}
          />
        </div>
        <Dialog
          actions={actions}
          modal={false}
          contentStyle={{ width: "20%", maxWidth: "none" }}
          open={this.state.opendialog}
          onRequestClose={this.handleClose}
        >
          Delete user?
        </Dialog>
      </div>
    );
  }
}

export default inject("Auth")(observer(EditParent))
