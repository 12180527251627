import React, { Component } from "react";
import RaisedButton from "material-ui/RaisedButton";
import Dialog from "material-ui/Dialog";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";

import { observer, inject } from "mobx-react";

import { getEmergency } from "../../utils/operations.js";
import API from "../../utils/api";
import {toast} from "react-toastify";

const style = {
  width: 500,
  margin: 20,
  padding: 20,
  textAlign: "center",
  display: "inline-block",
  borderWidth: 1,
  borderRadius: "20px",
  boxShadow: "inset 0px 0px 20px rgba(0,0,0,0.2)"
};

class EditEmergency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgId: 0,
      opendialog: false, // Диалог с ошибками
      texterrors: "", // Список ошибок
      school: "",
      name: "",
      relation: "",
      phone: "",
      id: 0,
      allergies: "",
      isEdit: false
    };
  }

  componentDidMount() {
    let pathname = this.props.location.pathname;
    let itemId = pathname.replace("/editemergency/:", "");
    if (itemId !== "new") {
      this.setState({ isEdit: true });
      this.setState({ id: itemId });
      this.loadEmergency(itemId);
    }
  }

  loadEmergency = async (itemId) => {
    try {
      const res = await API.get(getEmergency + "/" + itemId)
      let emergency = res.data;
      this.setState({ name: emergency.name, relation: emergency.relation, phone: emergency.phone  });
    } catch (e) {
      console.log("Error loading emergency", e.message);
    }
  };

  handleOpen = () => {
    this.setState({ opendialog: true });
  };

  handleClose = () => {
    this.setState({ opendialog: false });
  };

  handleChangeName = e => {
    this.setState({ name: e.target.value });
  };

  handleChangePhone = e => {
    this.setState({ phone: e.target.value });
  };

  handleChangeRelation = e => {
    this.setState({ relation: e.target.value });
  };

  deleteEmergency = async () => {
    try {
      await API.delete(getEmergency + "/" + this.state.id);

      const goUrl = "/editchild/" + this.props.Auth.child_id;
      setTimeout(this.props.history.push(goUrl), 1000);
    } catch (e) {
      this.setState({ texterrors: e.message });
      this.handleOpen();
    }
  };

  saveInfo = async () => {
    const emergencyData = {
      child_id: this.props.Auth.child_id,
      name: this.state.name,
      relation: this.state.relation,
      phone: this.state.phone
    };

    try {
      if (this.state.isEdit) {
        await API.patch(getEmergency + "/" + this.state.id, emergencyData);
        toast.success("Emergency was updated successfully")
      } else {
        const data = {resource: [emergencyData]};
        await API.post(getEmergency, data);
        toast.success("Emergency was added successfully")
      }

      const goUrl = "/editchild/" + this.props.Auth.child_id;
      setTimeout(this.props.history.push(goUrl), 2000);
    } catch (e) {
      console.log("Error saving emergency", e.message);
      this.setState({ texterrors: e.message });
      this.handleOpen();
    }
  };

  goPreviousPage = () => {
    this.props.history.goBack();
  };

  render() {
    const actions = [
      <FlatButton label="Cancel" primary={true} onClick={this.handleClose} />,
      <FlatButton label="OK" primary={true} onClick={this.deleteEmergency} />
    ];

    return (
      <div style={{ margin: "auto", width: 700 }}>
        <div style={style}>
          <span>Emergency contacts</span>
          <br />
          <TextField
            hintText="Name"
            floatingLabelText="Name"
            fullWidth={true}
            value={this.state.name}
            onChange={this.handleChangeName}
          />
          <br />
          <TextField
            hintText="Relation"
            floatingLabelText="Relation"
            fullWidth={true}
            value={this.state.relation}
            onChange={this.handleChangeRelation}
          />
          <br />
          <TextField
            hintText="Phone Number"
            floatingLabelText="Phone Number"
            fullWidth={true}
            value={this.state.phone}
            onChange={this.handleChangePhone}
          />
          <br />
          <br />
        </div>
        <br />
        <div
          style={{
            margin: "auto",
            width: 400,
            paddingTop: 20,
            paddingBottom: 20
          }}
        >
          <RaisedButton
            label="Save"
            primary={true}
            style={{ marginRight: 10 }}
            onClick={this.saveInfo}
          />
          <RaisedButton
            label="Cancel"
            primary={true}
            style={{ marginLeft: 10 }}
            onClick={this.goPreviousPage}
          />
          <RaisedButton
            label="Delete"
            secondary={true}
            style={{ marginLeft: 10 }}
            onClick={this.handleOpen}
          />
          <Dialog
            actions={actions}
            modal={false}
            contentStyle={{ width: "30%", maxWidth: "none" }}
            open={this.state.opendialog}
            onRequestClose={this.handleClose}
          >
            {this.state.texterrors
              ? this.state.texterrors
              : "Delete Emergency?"}
          </Dialog>
        </div>
      </div>
    );
  }
}

export default inject("Auth")(observer(EditEmergency))
