import React, {useEffect, useState} from "react";

import { observer, inject } from "mobx-react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Stack from '@mui/material/Stack';

import {toast} from "react-toastify";
import {
  api2VaccineRecord, api2VaccineRecords
} from "../../utils/operations";

import API from '../../utils/api';
import Loader from "../../components/Loader";
import appRoutes from "../../utils/appRoutes";

const NewVaccineRecord = (props) => {

  const [loading, setLoading] = useState(false)
  const [id, setId] = useState(null)
  const [title, setTitle] = useState('')
  const [dosesNeeded, setDosesNeeded] = useState(0)
  const [dosesCompleted, setDosesCompleted] = useState(0)

  useEffect(() => {
    const recordId = props.match.params.recordId


    if (recordId) {
      setId(recordId)
      loadVaccineRecord()
    }
  }, [])

  const loadVaccineRecord = async () => {
    const recordId = props.match.params.recordId
    setLoading(true)
    try {
      const result = await API.get(api2VaccineRecord(recordId))
      setTitle(result.data.data.title)
      setDosesNeeded(result.data.data.doses_needed)
      setDosesCompleted(result.data.data.doses_completed)
    } catch (e) {
      toast.error('Something went wrong!')
    }
    setLoading(false)
  }

  const goPreviousPage = () => {
    setTimeout(props.history.push(appRoutes.vaccineRecords), 1000);
  };

  const saveVaccineRecord = async () => {
    const recordId = props.match.params.recordId
    const requestUrl = recordId ? api2VaccineRecord(recordId) : api2VaccineRecords;

    try {
      await API.post(requestUrl, {
        title: title,
        doses_completed: dosesCompleted,
        doses_needed: dosesNeeded,
      })

      toast.success('Vaccine Record successfully saved!')
      setTimeout(props.history.push(appRoutes.vaccineRecords), 1000);
    } catch (e) {
      toast.error('Something went wrong!')
    }
  }

  return (

      <div style={{ maxWidth: 500, margin: "auto" }}>
        <Loader loaded={!loading}>
          <h3>{id ? 'Medical Record' : 'New Medical Record'}</h3>
          <Stack gap="20px">
            <TextField
              label='Title'
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              size="small"
              variant="standard"
              required
            />

            <TextField
              label='Doses neded'
              value={dosesNeeded}
              onChange={(event) => setDosesNeeded(event.target.value)}
              size="small"
              variant="standard"
              type="number"
              step="1"
              required
            />

            {id && <TextField
              label='Doses completed'
              value={dosesCompleted}
              onChange={(event) => setDosesCompleted(event.target.value)}
              size="small"
              type="number"
              step="1"
              variant="standard"
            />}

          </Stack>

          <Stack direction="row" sx={{ padding: "20px 0"}} gap="20px">
            <Button variant="contained" onClick={() => saveVaccineRecord()} >Save</Button>
            <Button variant="outlined" onClick={goPreviousPage} >Cancel</Button>
          </Stack>
        </Loader>
      </div>

  )
}


export default inject("Auth")(observer(NewVaccineRecord))
