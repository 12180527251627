import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import get from "lodash/get";
import moment from "moment";
import axios from "axios";
import html2pdf from "html2pdf.js";
import saveAs from "file-saver";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import Switch from '@mui/material/Switch';
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ClearIcon from '@mui/icons-material/Clear';
import Pagination from '@mui/lab/Pagination';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from "@mui/material";

import {
  invoicesUrl,
  getAllDepos,
  getArchive,
  getAllChildren,
  api2SendOverdueReminder, api2SendFailedReminder, api2SendUnpaidReminder, api2ClassesByOrganizationId,
} from "../../utils/operations.js";

import {
  red500,
  green500,
  orange500,
  grey500,
  indigo500
} from "material-ui/styles/colors";

import API from '../../utils/api'
import {
  api2Bills,
  api2ChildLogFinance,
  api2ChildPatch,
  api2OrganizationInvoicesByClass,
  api2PublicStorage
} from '../../utils/operations';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import { LATE_FEE_AMOUNT } from '../../utils/constants';
import { base64toBlob, moneyFormatter } from '../../utils/misc';
import StyledTableRow from "../../components/StyledTableRow";

const AriaModal = require("react-aria-modal");

const rows = [];

let searchTimer = null

class Invoices extends Component {
  constructor(props) {
    super(props);

    this.searchRef = React.createRef();

    this.state = {
      page: 1,
      perPage: 50,
      loaded: false,
      data: [],
      rowdata: [],
      modifiedData: [],
      immutableData: [],
      filteredItems: [],
      selectedItems: [],
      classes: [],
      rows: rows,
      children: [],
      searchText: "",
      errorText: "",
      autoOk: true,
      disableYearSelection: false,
      orgId: 0,
      search: "",
      searchEmail: "",
      amount: 0,
      paid: 0,
      datefilter: false,
      childDict: {},
      minDate: moment().format("YYYY-MM-DD"),
      maxDate: moment().format("YYYY-MM-DD"),
      showClasses: false,
      kind: "all",
      name: [],
      showChild: null,
      searchAuto: "",
      searchID: "",
      errorSearchID: false,
      monthlyBilled: 0,
      monthlyPaid: 0,
      monthlyCredits: 0,
      monthlyPending: 0,
    };
  }

  componentDidMount() {
    let orgId = this.props.Auth.orgId;
    this.setState({ orgId });
    this.loadData();

    let today = Number(moment().format("D")) + 1;
    let toDate = moment().format("YYYY-MM-DD");

    if (today < 28) {
      toDate = moment()
        .subtract(1, "months")
        .date(28)
        .format("YYYY-MM-DD")
    } else {
      toDate = moment().format("MM") + "-28-" + moment().format("YYYY")
    }

    this.setState({
      minDate: moment(toDate).subtract(1, "months").format("YYYY-MM-DD"),
      maxDate: moment(toDate).format("YYYY-MM-DD"),
    });
  }

  handleCalculate = data => {
    let monthlyBilled = 0;
    let monthlyPaid = 0;
    let monthlyCredits = 0;
    let monthlyPending = 0;

    data.forEach(item => {
      monthlyBilled += item.amount;
      monthlyPaid += item.paid;
      monthlyCredits += item.credits;
      monthlyPending += item.pending;
    });

    this.setState({
      monthlyBilled,
      monthlyPaid,
      monthlyCredits,
      monthlyPending,
    });
  };

  loadData = () => {
    let orgId = this.props.Auth.orgId;

    axios
      .all([
        API.get(api2Bills, {
          params: {with: 'child,classes', organisation_id: orgId, withCredits: true, withPending: true}
        }),
        API.get(getAllChildren, {
          params: {fields: 'id', related: 'parents_by_childtoparent', filter: 'organisation_id=' + orgId}
        }),
        API.get(api2ClassesByOrganizationId(orgId))
      ])
      .then(
        axios.spread((inv, childs, gotClasses) => {
          let gotData = inv.data;
          let children = childs.data.resource;
          let classes = gotClasses.data.data;

          // Dictionary {'child_id': 'parent_emails'}
          let childDict = {};
          for (let i = 0; i < children.length; i++) {
            let id = children[i].id;
            let parents = children[i].parents_by_childtoparent;
            childDict[id] = parents.map(p => p.email).join(',');
          }

          let data = [];
          for (let i = 0; i < gotData.length; i++) {
            data.push({
              id: gotData[i].id,
              uuid: gotData[i].uuid,
              classes: gotData[i].classes.name,
              nameForSearch: gotData[i].child.firstname.toLowerCase() + ' ' + gotData[i].child.lastname.toLowerCase(),
              firstname: gotData[i].child.firstname,
              lastname: gotData[i].child.lastname,
              acs: gotData[i].child.acs,
              suspend_notification: gotData[i].child.suspend_notification,
              child_id: gotData[i].child_id,
              status: gotData[i].status,
              fee: gotData[i].fee,
              total: gotData[i].total,
              amount: gotData[i].amount,
              credits: gotData[i].credits,
              paid: gotData[i].paid,
              pending: gotData[i].pending,
              gendate: moment(gotData[i].gendate).format("MM/DD/YYYY"),
              invoicefile: gotData[i].invoicefile,
              emails: childDict[gotData[i].child_id]
            });
          }

          let immutableData = JSON.parse(JSON.stringify(data));

          this.setState({
            data,
            classes,
            filteredItems: data,
            immutableData,
            datefilter: false,
            kind: "all",
            loaded: true
          });

          this.handleCalculate(data)
        })
      );
  };

  handleChangeMinDate = (event) => this.setState({ minDate: event.target.value });
  handleChangeMaxDate = (event) => this.setState({ maxDate: event.target.value });

  handleDateFilter = () => {
    this.clearSearchesState()
    this.setState({ datefilter: true });
    let that = this;
    let related = "?related=childs_by_child_id%2Cclasses_by_classes_id";
    let fromDate = `(gendate%3E%3D${moment(this.state.minDate).format(
      "YYYY-MM-DD"
    )})`;
    let tillDate = `(gendate%3C%3D${moment(this.state.maxDate).format(
      "YYYY-MM-DD"
    )})`;
    let orgId = "(organisation_id%3D" + this.props.Auth.orgId + ")";
    let invoiceUrl = `${getArchive}${related}&filter=${fromDate}AND${tillDate}AND${orgId}`;

    this.setState({loaded: false})
    API.get(invoiceUrl)
      .then(res => {
        const dataNew = res.data.resource.map((row) => {
          const firstname = row.childs_by_child_id ? row.childs_by_child_id.firstname : 'DELETED'
          const lastname = row.childs_by_child_id ? row.childs_by_child_id.lastname : 'DELETED'
          const classname = row.classes_by_classes_id ? row.classes_by_classes_id.name : 'DELETED'
          return {
            id: row.id,
            classes: classname,
            nameForSearch: firstname.toLowerCase() + ' ' + lastname.toLowerCase(),
            firstname: firstname,
            lastname: lastname,
            acs: row.childs_by_child_id ? row.childs_by_child_id.acs : false,
            child_id: row.child_id,
            status: row.status,
            fee: row.fee,
            total: row.total,
            amount: row.amount,
            paid: row.paid === 0 ? "" : row.paid,
            gendate: moment(row.gendate).format("MM/DD/YYYY"),
            invoicefile: row.invoicefile,
            emails: '',
            payToAdd: ''
          }
        })

        let immutableData = JSON.parse(JSON.stringify(dataNew));
        that.setState({ filteredItems: dataNew, immutableData, data: dataNew, loaded: true });
      });
  };

  renderStatus = (status, paid) => {
    if (status === "paid") {
      return <Avatar sx={{ backgroundColor: green500 }} size={30} title="PAID">&nbsp;</Avatar>;
    } else if (status === "overdue") {
      return <Avatar sx={{ backgroundColor: red500 }} size={30} title="OVERDUE">&nbsp;</Avatar>;
    } else if (status === "pending") {
      return <Avatar sx={{ backgroundColor: indigo500 }} size={30} title="PENDING">&nbsp;</Avatar>;
    } else if (status === "failed") {
      return <Avatar sx={{ backgroundColor: red500 }} size={30} title="FAILED">&nbsp;</Avatar>;
    }  else if (paid > 0) {
      return <Avatar sx={{ backgroundColor: orange500 }} size={30} title="UNPAID">&nbsp;</Avatar>;
    } else {
      return <Avatar sx={{ backgroundColor: grey500 }} size={30} title="UNPAID">&nbsp;</Avatar>;
    }
  };

  filterInvoicesByStatus = paidType => {
    this.setState({ search: "" });

    let filteredItems = [];
    let data = this.state.data;

    switch (paidType) {
      case "all":
        filteredItems = data;
        break;

      case "paid":
        filteredItems = data.filter(i => i.status === "paid")
        break;

      case "unpaid":
        filteredItems = data.filter(i => i.status === "unpaid")
        break;

      case "partial":
        filteredItems = data.filter(i => i.status === "partial")
        break;

      case "overdue":
        filteredItems = data.filter(i => i.status === "overdue")
        break;

      case "pending":
        filteredItems = data.filter(i => i.status === "pending")
        break;

      case "failed":
        filteredItems = data.filter(i => i.status === "failed")
        break;

      default:
        filteredItems = data
        break;
    }

    this.setState({
      selectedItems: [],
      filteredItems,
      kind: paidType
    });
  };

  editInvoice = id => {
    const editUrl = "/editinvoice/" + id;
    this.props.history.push(editUrl);
  };

  handleChangeSearch = event => {
    let searchString = event.target.value.toLowerCase();

    this.setState({
      search: event.target.value
    });

    clearTimeout(searchTimer)
    searchTimer = setTimeout(() => {
      let filteredItems = this.state.data;

      filteredItems = filteredItems.filter(item => {
        return item.nameForSearch.includes(searchString)
      });
      this.setState({ filteredItems });
    }, 350)
  };

  handleChangeSearchEmail = event => {
    let searchString = event.target.value.toLowerCase();

    this.setState({
      searchEmail: event.target.value
    });

    let filteredItems = this.state.data;

    filteredItems = filteredItems.filter(item => {
      return get(item, "emails", "")
        .toString()
        .toLowerCase()
        .includes(searchString);
    });
    this.setState({ filteredItems });
  };

  saveData = async () => {
    let allItems = this.state.immutableData;
    let invoices = [];
    let depoUpdated = [];
    let financeLog = [];

    for (let item of this.state.filteredItems) {
      if (item.changed) {
        let id = allItems.findIndex(x => x.id === item.id);
        let loadedInvoice = this.state.immutableData.find(x => x.id === item.id);

        let prevDepo = Number(loadedInvoice.paid);
        let nextDepo = Number(item.paid);
        let currentPay =  nextDepo - prevDepo;

        allItems[id].paid = nextDepo;
        allItems[id].status = item.status;
        allItems[id].payToAdd = '';

        invoices.push({
          id: item.id,
          paid: nextDepo,
          action: "to_generate_new"
        });

        depoUpdated.push({
          invoice_id: item.id,
          amount: currentPay,
          user_id: this.props.Auth.profile.id,
          description: 'Payment'
        });

        financeLog.push({
          child_id: item.child_id,
          bill_uuid: item.uuid,
          amount: currentPay,
          type: 'depo'
        })
      }
    }

    if (invoices.length > 0) {
      try {
        await Promise.all([
          API.patch(invoicesUrl, { resource: invoices }),
          API.post(getAllDepos, { resource: depoUpdated }),
        ]);
        toast.success('Invoices updated successfully!')
      } catch (e) {
        let texterrors = "";
        if (e.response.data._issues) {
          let issues = e.response.data._issues;
          for (let key in issues) {
            texterrors += key + ": " + issues[key] + "\n";
          }
        } else {
          texterrors = e.response.data.error;
        }
        toast.error(texterrors)
      }

      financeLog.forEach(item => {
        API.post(api2ChildLogFinance(item.child_id), item)
      })
    }

    let immutableData = JSON.parse(JSON.stringify(allItems));

    this.setState({
      immutableData,
      search: "",
      data: allItems,
      filteredItems: allItems
    });

    this.handleCalculate(allItems);

    this.searchRef.current.focus()
    window.scrollTo({ top: 100, behavior: "smooth" });
  };

  handleChangeOrg = (event) => {
    const value = event.target.value
    this.props.Auth.setOrgId(value)
    this.props.Auth.getOrgName()
    this.loadData()
  };

  handleChangeSuspendNotification = (childId, value) => {
    API.patch(api2ChildPatch(childId), { suspend_notification: value })
      .then(() => {
        toast.success("Suspend Notification was changed!")
        let allItems = this.state.immutableData;
        allItems = allItems.map(item => {
          if (item.child_id === childId) {
            return {
              ...item,
              suspend_notification: value
            }
          }
          return item
        })
        let immutableData = JSON.parse(JSON.stringify(allItems));
        this.setState({ immutableData, data: immutableData });
      }).catch(() => {
      toast.error("Something went wrong!")
    })
  }

  handleChangeFee = (invoiceId, value) => {
    const newFee = value ? LATE_FEE_AMOUNT : 0;
    const invoice = this.state.immutableData.find(item => item.id === invoiceId);
    let newStatus = invoice.status;
    if ( invoice.paid >= invoice.amount + newFee ) {
      newStatus = "paid";
    } else if (newFee > 0) {
      newStatus = "overdue";
    }

    const invoiceData = {
      id: invoiceId,
      action: "to_generate_new",
      fee: newFee,
    };

    API.patch(invoicesUrl, { resource: [invoiceData] })
      .then(() => {
        toast.success("Fee was changed!")
        let allItems = [...this.state.immutableData].map(item => {
          return  item.id === invoiceId ? {
            ...item,
            fee: newFee,
            status: newStatus,
          } : item
        })
        let immutableData = JSON.parse(JSON.stringify(allItems));
        let filteredItems = [...this.state.filteredItems].map(item => {
          return  item.id === invoiceId ? {
            ...item,
            fee: value ? LATE_FEE_AMOUNT : 0,
            status: newStatus,
          } : item
        })
        this.setState({ immutableData, data: immutableData, filteredItems });
      }).catch(() => {
      toast.error("Something went wrong!")
    })
  }

  handleChangeDepositWithTimeout = (event) => {
    let id = Number(event.target.id)
    let value = event.target.value

    if (isNaN(value)) {
      return false;
    }

    this.handleChangeDeposit(id, value)
  }

  handleChangeDeposit = (id, value ) => {
    const loadedInvoice = this.state.immutableData.find(invoice => invoice.id === id);
    const prevDepo = loadedInvoice.paid

    const filteredItems = this.state.filteredItems.map(filteredItem => {
      if (filteredItem.id === id) {
        filteredItem.payToAdd = value;
        filteredItem.paid = prevDepo + Number(value);
        filteredItem.changed = Number(value) > 0;

        if ( Number(value) + prevDepo >= filteredItem.amount + filteredItem.fee ) {
          filteredItem.status = "paid";
        } else if (filteredItem.fee > 0) {
          filteredItem.status = "overdue";
        }
      }

      return filteredItem;
    })

    this.setState({ filteredItems });
  };

  // Save data by 'Enter' key
  depoPressed = async event => {
    if (event.key === "Enter") {
      event.preventDefault()
      await this.saveData()
    }
  };

  switchOrg = () => {
    switch (this.props.Auth.role) {
      case "teacher":
        return <div />;
      case "admin":
        return <div />;
      case "superadmin":
        return (
          <div className="invoicesSelectSchool">
            <div className="invoicesSelectSchool_label">Select default school:</div>
            <TextField
              select
              value={this.props.Auth.orgId}
              onChange={this.handleChangeOrg}
              size="small"
              variant="standard"
            >
              {Object.values(this.props.Auth.allOrgs).map((org) => {
                return <MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
              })}
            </TextField>
          </div>
        );
      default:
        return <div />;
    }
  };
  printClass = id => {
    const orgId = this.props.Auth.orgId
    API.get(api2OrganizationInvoicesByClass(orgId, id))
      .then(res => {
        let file = res.data.file;
        if (file) {
          let blob = base64toBlob(file, "application/pdf");
          saveAs(blob, "invoices.pdf");
        }
      }).catch(error => {
      toast.error("Something wrong. " + error.response.data.error)
    });
  };

  switchClass = () => {
    if (this.state.classes.length > 0) {
      switch (this.props.Auth.role) {
        case "teacher":
          return <div />;
        case "admin":
          return (
            <div style={{ marginRight: 400 }}>
              <span>Select class:</span>
              <br />

              <div style={{ width: 200 }}>
                {this.state.classes.map(row => (
                  <div key={row.id}>
                    <Button
                      primary
                      sx={{ width: 190 }}
                      onClick={() => this.printClass(row.id)}
                    >{row.name}</Button>
                  </div>
                ))}
              </div>
            </div>
          );
        case "superadmin":
          return (
            <div style={{ marginRight: 400 }}>
              <span>Select class:</span>
              <br />

              <div style={{ width: 200 }}>
                {this.state.classes.map(row => (
                  <div key={row.id}>
                    <Button
                      primary
                      sx={{ width: 190 }}
                      onClick={() => this.printClass(row.id)}
                    >{row.name}</Button>
                  </div>
                ))}
              </div>
            </div>
          );
        default:
          return <div />;
      }
    }
  };

  handleRemoveSearch = () => {
    this.clearSearchesState()
    this.filterInvoicesByStatus("all");
  };

  clearSearchesState = () => {
    this.setState({
      search: "",
      searchEmail: "",
      searchAuto: "",
      searchID: "",
      errorSearchID: false,
    });
  }

  Print = () => {
    let source = `<p>${this.state.kind} invoices</p>`;
    source += '<table style="width: 90%" border="1">';
    source +=
      "<tr>" +
      "<th>First Name</th>" +
      "<th>Last Name</th>" +
      "<th>Class</th>" +
      "<th>Fee</th>" +
      "<th>Amount</th>" +
      "<th>Paid</th>" +
      "<th>Date</th>" +
      "</tr>";

    this.state.kind === "all"
      ? this.state.data.map(rows => {
        source += "<tr>";
        source += `<td>${rows.firstname}`;
        source += `<td>${rows.lastname}`;
        source += `<td>${rows.classes}`;
        source += `<td>${rows.fee.toString()}`;
        source += `<td>${rows.amount.toString()}`;
        let paid = rows.paid.toString() === "" ? "0" : rows.paid.toString();
        source += `<td>${paid}`;
        source += `<td>${rows.gendate}`;
        source += "</tr>";
      })
      : this.state.filteredItems.map(rows => {
        source += "<tr>";
        source += `<td>${rows.firstname}`;
        source += `<td>${rows.lastname}`;
        source += `<td>${rows.classes}`;
        source += `<td>${rows.fee.toString()}`;
        source += `<td>${rows.amount.toString()}`;
        let paid = rows.paid.toString() === "" ? "0" : rows.paid.toString();
        source += `<td>${paid}`;
        source += `<td>${rows.gendate}`;
        source += "</tr>";
      });
    source += "</table>";

    html2pdf(source, {
      margin: 1,
      filename: "file.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" }
    });
  };

  searchByID = () => {
    this.setState({
      search: "",
      searchEmail: "",
      searchAuto: "",
      errorSearchID: false
    });
    this.filterInvoicesByStatus("all");

    let searchID = Number(this.state.searchID);
    this.setState({
      filteredItems: this.state.data.filter(i => i.child_id === searchID)
    });

  };

  handleACSInvoices = () => {
    this.setState({
      kind: 'all',
      filteredItems: this.state.data.filter(item => item.acs === true)
    });
  }

  isCheckboxVisible = () => {
    return ['overdue', 'failed', 'unpaid'].includes(this.state.kind)
  }

  isCheckboxChecked = (id) => this.state.selectedItems.includes(id)

  handleCheckBoxAllClick = (checked) => {
    if (checked) {
      this.setState({ selectedItems: this.state.filteredItems.map(i => i.id)})
    } else {
      this.setState({ selectedItems: []})
    }
  }
  handleCheckBoxClick = (id, checked) => {
    let selectedItems = [...this.state.selectedItems]
    if (checked) {
      this.setState({ selectedItems: [...selectedItems, id]})
    } else {
      selectedItems.splice(selectedItems.indexOf(id), 1)
      this.setState({ selectedItems: selectedItems})
    }
  }

  isSendOverdueReminderVisible = () => {
    return this.state.kind === 'overdue'
  }

  handleSendOverdueReminder = () => {
    const ids = this.state.selectedItems

    if (ids.length === 0) {
      return toast.error("There are no selected invoices.");
    }

    const conf = window.confirm(`Are you sure? Do you want to send overdue reminder email for ${ids.length} ${this.state.kind} invoices ?`);
    if (!conf) return;


    API.post(api2SendOverdueReminder, ids).then(() => {
      toast.success("The task was scheduled successfully.")
    }).catch(() => toast.error("Something wrong."));

  }

  isSendFailedReminderVisible = () => {
    return this.state.kind === 'failed'
  }

  handleSendFailedReminder = () => {
    const ids = this.state.selectedItems

    if (ids.length === 0) {
      return toast.error("There are no selected invoices.");
    }

    const conf = window.confirm(`Are you sure? Do you want to send failed reminder email for ${ids.length} ${this.state.kind} invoices ?`);
    if (!conf) return;

    API.post(api2SendFailedReminder, ids).then(() => {
      toast.success("The task was scheduled successfully.")
    }).catch(() => toast.error("Something wrong."));

  }

  isSendUnpaidReminderVisible = () => {
    return this.state.kind === 'unpaid' || this.state.kind === 'overdue'
  }

  handleSendUnpaidReminder = () => {
    const ids = this.state.selectedItems

    if (ids.length === 0) {
      return toast.error("There are no selected invoices.");
    }

    const conf = window.confirm(`Are you sure? Do you want to send unpaid reminder email for ${ids.length} ${this.state.kind} invoices ?`);
    if (!conf) return;

    API.post(api2SendUnpaidReminder, ids).then(() => {
      toast.success("The task was scheduled successfully.")
    }).catch(() => toast.error("Something wrong."));

  }

  render() {
    const modal = this.state.showClasses ? (
      <AriaModal
        titleText="select class"
        onExit={() => this.setState({ showClasses: false })}
        focusDialog={true}
        escapeExits={false}
        underlayStyle={{ paddingTop: 20 }}
        aria-describedby="describer"
        data-test-id="test-id"
      >
        <div
          style={{
            background: "#fff",
            outline: 0,
            minWidth: 250,
            maxWidth: 700,
            padding: 50,
            borderRadius: 4
          }}
        >
          <div style={{ padding: 30 }}>{this.switchClass()}</div>
          <Button
            primary
            onClick={() => this.setState({ showClasses: false })}
          >Close</Button>
        </div>
      </AriaModal>
    ) : null;

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div style={{ margin: "auto" }}>
          <div style={{ display: "flex", justifyContent: 'space-between' }}>
            {this.switchOrg()}
            <div>
              <table>
                <tbody>
                <tr>
                  <td>Total Monthly Credits:</td>
                  <td style={{paddingRight: '10px'}}>{moneyFormatter(this.state.monthlyCredits)}</td>
                  <td>Amount Billed:</td>
                  <td>{moneyFormatter(this.state.monthlyBilled)}</td>
                </tr>
                <tr>
                  <td>Total Pending:</td>
                  <td style={{paddingRight: '10px'}}>{moneyFormatter(this.state.monthlyPending)}</td>
                  <td>Total Collected:</td>
                  <td>{moneyFormatter(this.state.monthlyPaid)}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>Outstanding Balance:</td>
                  <td>{moneyFormatter(this.state.monthlyBilled - this.state.monthlyPaid)}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <Paper variant="outlined" sx={{ mt: 2}}>
            <div className="invoicesStatusFilter" style={{ display: "flex" }}>
              <div style={{ flex: 2, display: "flex", gap: "20px" }}>
                <Button
                  primary={true}
                  onClick={() => this.filterInvoicesByStatus("all")}
                >All</Button>
                <Button
                  style={{ color: green500 }}
                  onClick={() => this.filterInvoicesByStatus("paid")}
                >Paid</Button>
                <Button
                  style={{ color: grey500 }}
                  onClick={() => this.filterInvoicesByStatus("unpaid")}
                >Unpaid</Button>
                <Button
                  style={{ color: orange500 }}
                  onClick={() => this.filterInvoicesByStatus("partial")}
                >Partial</Button>
                <Button
                  style={{ color: red500 }}
                  onClick={() => this.filterInvoicesByStatus("overdue")}
                >Overdue</Button>
                <Button
                  primary={true}
                  onClick={() => this.filterInvoicesByStatus("pending")}
                >Pending</Button>
                <Button
                  style={{ color: red500 }}
                  onClick={() => this.filterInvoicesByStatus("failed")}
                >Failed</Button>
                <Button
                  className={"active"}
                  primary
                  onClick={() => this.handleACSInvoices()}
                >ACS only</Button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  gap: "20px"
                }}
              >
                {this.isSendOverdueReminderVisible() && <Button
                  primary
                  onClick={() => this.handleSendOverdueReminder()}
                >Overdue reminder</Button>}
                {this.isSendFailedReminderVisible() && <Button
                  primary
                  onClick={() => this.handleSendFailedReminder()}
                >Failed reminder</Button>}
                {this.isSendUnpaidReminderVisible() && <Button
                  primary
                  onClick={() => this.handleSendUnpaidReminder()}
                >Unpaid reminder</Button>}
                <Button
                  primary
                  onClick={() => this.Print("all")}
                >Print</Button>
                <Button
                  primary
                  onClick={() => this.setState({ showClasses: true })}
                >Print by class</Button>
              </div>
            </div>
          </Paper>
          <br />
          <Paper variant="outlined">
            <div className="invoicesDateFilter">
              <TextField
                id="min-date"
                onChange={this.handleChangeMinDate}
                label="Min Date"
                type="date"
                value={this.state.minDate}
                InputLabelProps={{ shrink: true }}
                size="small"
                variant="standard"
              />
              <TextField
                id="max-date"
                onChange={this.handleChangeMaxDate}
                label="Max Date"
                type="date"
                value={this.state.maxDate}
                InputLabelProps={{ shrink: true }}
                size="small"
                variant="standard"
              />
              <Button
                primary={true}
                onClick={this.handleDateFilter}
              >Filter by Date Range</Button>
              <Button
                primary={true}
                onClick={this.loadData}
              >Clear Date Filter</Button>
            </div>

          </Paper>
          <br />
          <Paper variant="outlined">
            <div style={{ display: "flex", flex: 1, alignItems: "center", gap: "20px", marginLeft: "20px" }}>
              <TextField
                label="Name"
                value={this.state.search}
                onChange={this.handleChangeSearch}
                inputRef={this.searchRef}
                size="small"
                variant="standard"
              />
              <TextField
                label="Email"
                value={this.state.searchEmail}
                onChange={this.handleChangeSearchEmail}
                size="small"
                variant="standard"
              />
              <IconButton title="Clear" onClick={this.handleRemoveSearch}><ClearIcon color={"primary"} /></IconButton>
            </div>
          </Paper>
          <br />
          <Paper variant="outlined">
            <div style={{ display: "flex", flex: 1, alignItems: "center", gap: "20px", marginLeft: "20px" }}>
              <TextField
                label="Client ID"
                value={this.state.searchID}
                onChange={e => this.setState({ searchID: e.target.value })}
                size="small"
                variant="standard"
              />
              <Button primary onClick={this.searchByID} >Search</Button>
              <Button primary onClick={this.handleRemoveSearch}>Clear</Button>
            </div>
            {this.state.errorSearchID && (
              <div style={{ margin: 10 }}>
              <span style={{ fontSize: 13, color: "red" }}>
                Client ID not found
              </span>
                &nbsp;
              </div>
            )}
          </Paper>
          <br />
          {this.state.datefilter &&
            <Pagination variant="outlined" shape="rounded"
                        onChange={(e,page) => this.setState({page})}
                        page={this.state.page} count={Math.ceil(this.state.filteredItems.length/this.state.perPage)} />
          }
          <div style={{ overflowX: 'auto'}}>
            <Loader loaded={this.state.loaded}>
              <Table>
                <TableHead>
                  <TableRow>
                    {this.isCheckboxVisible() && <TableCell width={50}>
                      <Checkbox
                        defaultChecked={false}
                        onChange={(event) => this.handleCheckBoxAllClick(event.target.checked)}
                        title="Check all"
                      />
                    </TableCell>}
                    <TableCell>#</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Class</TableCell>
                    <TableCell style={{ width: 50 }}>Fee</TableCell>
                    <TableCell style={{ width: 50 }}>Invoiced</TableCell>
                    <TableCell style={{ width: 50 }}>Paid</TableCell>
                    <TableCell style={{ width: 50 }}>Balance Due</TableCell>
                    <TableCell style={{ width: 30 }}>Pay</TableCell>
                    <TableCell style={{ width: 30 }}>Credits</TableCell>
                    <TableCell style={{ width: 50 }}>Date</TableCell>
                    <TableCell style={{ width: 50 }}>Suspend Email</TableCell>
                    <TableCell>Edit</TableCell>
                    <TableCell style={{ width: 70 }}>Download</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.datefilter
                    ? this.state.filteredItems.slice((this.state.page-1)*this.state.perPage, ((this.state.page-1)*this.state.perPage + this.state.perPage)).map((row, index) => (
                      <StyledTableRow key={row.id}>
                        <TableCell>{index+1}</TableCell>
                        <TableCell>{this.renderStatus(row.status, row.paid)}</TableCell>
                        <TableCell>{row.firstname}</TableCell>
                        <TableCell>{row.lastname}</TableCell>
                        <TableCell>{row.classes}</TableCell>
                        <TableCell style={{ width: 50 }}>
                          {row.fee}
                        </TableCell>
                        <TableCell style={{ width: 50 }}>
                          {row.amount + row.fee}
                        </TableCell>
                        <TableCell style={{ width: 50 }}>
                          {row.paid}
                        </TableCell>
                        <TableCell style={{ width: 50 }}>
                          {row.amount + row.fee - row.paid}
                        </TableCell>
                        <TableCell style={{ width: 30 }}></TableCell>
                        <TableCell style={{ width: 30 }}></TableCell>
                        <TableCell style={{ width: 50 }}>{row.gendate}</TableCell>
                        <TableCell style={{ width: 50 }}></TableCell>
                        <TableCell>
                          <Button
                            style={{ marginLeft: 10 }}
                            primary
                            disabled
                          >EDIT</Button>
                        </TableCell>
                        <TableCell style={{ width: 70 }}>
                          <a href={api2PublicStorage + row.invoicefile} target="_blank">
                            <img
                              src={require("../../images/pdf.jpg")}
                              alt="Download PDF"
                            />
                          </a>
                        </TableCell>
                      </StyledTableRow>
                    ))
                    : this.state.filteredItems.map((row, index) => (
                      <StyledTableRow key={row.id}>
                        {this.isCheckboxVisible() && <TableCell width={50}>
                          <Checkbox
                            checked={this.isCheckboxChecked(row.id)}
                            onChange={(event) => this.handleCheckBoxClick(row.id, event.target.checked)}
                          />
                        </TableCell>}
                        <TableCell>{index+1}</TableCell>
                        <TableCell>{this.renderStatus(row.status, row.paid)}</TableCell>
                        <TableCell>{row.firstname}</TableCell>
                        <TableCell>{row.lastname}</TableCell>
                        <TableCell>{row.classes}</TableCell>
                        <TableCell style={{ width: 50 }} title="Toggle Fee">
                          <Switch
                            defaultChecked={row.fee > 0}
                            onChange={(event) => this.handleChangeFee(row.id, event.target.checked)}
                          />
                        </TableCell>
                        <TableCell style={{ width: 50 }} title="Invoiced">
                          {row.amount + row.fee}
                        </TableCell>
                        <TableCell style={{ width: 50 }} title="Paid">
                          {row.paid}
                        </TableCell>
                        <TableCell style={{ width: 50 }} title="Balance Due">
                          {row.amount + row.fee - row.paid}
                        </TableCell>
                        <TableCell style={{ width: 30, padding: 0 }} title="Add Payment">
                          <TextField
                            id={row.id.toString()}
                            name={index.toString()}
                            placeholder="0"
                            value={row.payToAdd}
                            onChange={this.handleChangeDepositWithTimeout}
                            onKeyDown={this.depoPressed}
                            disabled={!this.props.Auth.edit_finance}
                            size="small"
                            variant="standard"
                          />
                        </TableCell>
                        <TableCell style={{ width: 30 }}>{row.credits}</TableCell>
                        <TableCell style={{ width: 50 }}>{row.gendate}</TableCell>
                        <TableCell style={{ width: 50 }} title="Suspend Email">
                          <Switch
                            defaultChecked={row.suspend_notification === 1}
                            onChange={(event) => this.handleChangeSuspendNotification(row.child_id, event.target.checked)}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            primary
                            onClick={() => this.editInvoice(row.id)}
                            disabled={!this.props.Auth.show_finance}
                          >EDIT</Button>
                        </TableCell>
                        <TableCell style={{ width: 70 }}>
                          <a href={api2PublicStorage + row.invoicefile} target="_blank">
                            <img
                              src={require("../../images/pdf.jpg")}
                              alt="Download PDF"
                            />
                          </a>
                        </TableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
              {this.state.datefilter && <Pagination sx={{ mt: 2}} variant="outlined" shape="rounded" onChange={(e,page) => this.setState({page})} page={this.state.page} count={Math.ceil(this.state.filteredItems.length/this.state.perPage)} />}
              <Button sx={{ mt: 2, mb: 2 }} primary variant="contained" onClick={() => this.saveData()}>Save</Button>
            </Loader>
          </div>
          {modal}
        </div>
      </LocalizationProvider>
    );
  }
}

export default inject("Auth")(observer(Invoices))
