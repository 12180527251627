import React, { Component } from "react";
import axios from "axios";
import Subheader from "material-ui/Subheader";
import { List, ListItem } from "material-ui/List";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";
import CircularProgress from "material-ui/CircularProgress";
import API from '../../utils/api';

import {getCurrentOrg, api2ClassesByOrganizationId} from "../../utils/operations.js";
import { observer, inject } from "mobx-react";

const style = {
  marginLeft: 'calc(50% - 20px)',
  marginTop: 30,
};

const divProgressHide = {
  display: "none"
};
const divProgressShow = {
  display: "inline"
};

class Classes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showProgress: true
    };
  }

  componentDidMount() {
    let orgId = this.props.Auth.orgId;
    this.loadData(orgId);
  }

  loadData = orgId => {
    let that = this;
    axios
      .all([
        API.get(getCurrentOrg + orgId),
        API.get(api2ClassesByOrganizationId(orgId))
      ])
      .then(
        axios.spread(function(organisations, classes) {
          const data = classes.data.data;
          const orgs = organisations.data;

          for (let i = 0; i < data.length; i++) {
            data[i].organisation = orgs.name;
          }

          that.setState({ showProgress: false });
          that.setState({ data });
        })
      );
  };

  editData = value => {
    const editUrl = "/editclasses/" + value;
    this.props.history.push(editUrl);
  };

  newClasses = () => {
    const newUrl = "/newclasses";
    this.props.history.push(newUrl);
  };

  render() {
    return (
      <div style={{ maxWidth: 400, margin: "auto" }}>
        <List>
          <Subheader>Classes</Subheader>
          {this.state.data.map((row) => (
            <ListItem
              key={row.id}
              primaryText={row.name}
              secondaryText={row.organisation}
              secondaryTextLines={1}
              onClick={() => {
                this.editData(row.id);
              }}
            />
          ))}
        </List>
        <div
          style={this.state.showProgress ? divProgressShow : divProgressHide}
        >
          <CircularProgress size={80} thickness={7} />
        </div>
        <FloatingActionButton
          style={style}
          secondary={true}
          onClick={this.newClasses}
        >
          <ContentAdd />
        </FloatingActionButton>
      </div>
    );
  }
}

export default inject("Auth")(observer(Classes))
