import React, { Component } from "react";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import API from '../../utils/api';

import states from "../../utils/states.js";
import { getCurrentOrg } from "../../utils/operations.js";
import { observer, inject } from "mobx-react";
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

class EditOrganisations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        id: "",
        name: "",
        address: "",
        city: "",
        st: "",
        zip: "",
        phone: "",
        fax: "",
        site: "",
        email: "",
        taxid: "",
        stripe_account: "",
        stripe_publishable_key: "",
        stripe_secret_key: "",
        stripe_webhook_secret: "",
      },
      opendialog: false,
      openerrordialog: false,
      texterrors: "",
      st: states
    };
  }

  handleOpen = () => {
    this.setState({ opendialog: true });
  };

  handleClose = () => {
    this.setState({ opendialog: false });
    this.setState({ openerrordialog: false });
  };

  // Записать State в state.data.st
  handleStateChange = (event, index, value) => {
    const data = this.state.data;
    data.st = value;
    this.setState({ data });
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const orgId = this.props.match.params.id;

    API.get(getCurrentOrg + orgId)
      .then(res => {
        this.setState({ data: res.data });
      });
  };

  deleteData = () => {
    this.setState({ opendialog: false });
    let that = this;
    const base_url = getCurrentOrg + this.state.data.id;
    API.delete(base_url).then(() => {
      const goUrl = "/organisations";
      toast.success('Organization deleted successfully!')
      this.props.history.push(goUrl);
    }).catch(function(error) {
      let issues = error.response.data.error.message;

      let res = issues.match(/\.`\w+`/);

      let table = res[0].replace(/`/g, "").replace(".", "");

      switch (table) {
        case "bills":
          table = "Invoices";
          break;
        case "parents":
          table = "Parents";
          break;
        case "classes":
          table = "Classes";
          break;
        case "childs":
          table = "Children";
          break;
        default:
          table = "Information table";
      }

      let texterrors = "You can't delete '" + that.props.Auth.orgName + "' ";
      texterrors += "while '" + table + "' exist";

      that.setState({ texterrors: texterrors });

      // Открыть диалог с ошибками
      that.setState({ openerrordialog: true });
    });
  };

  saveData = () => {
    const base_url = getCurrentOrg + this.state.data.id;
    API.patch(base_url, this.state.data).then(() => {
      toast.success('Organization changed successfully!')
      this.props.history.push("/organisations");
    }).catch(function(error) {
      toast.error(error)
    });
  };

  handleChange = event => {
    const data = this.state.data;
    data[event.target.id] = event.target.value;
    this.setState({ data });
  };

  // Перейти на предидущую страницу
  goPreviousPage = () => {
    this.props.history.goBack();
  };

  render() {
    const actions = [
      <FlatButton key='btn-cancel' label="Cancel" primary={true} onClick={this.handleClose} />,
      <FlatButton key='btn-ok' label="OK" primary={true} onClick={this.deleteData} />
    ];
    const errors = [
      <FlatButton key='btn-ok' label="Ok" primary={true} onClick={this.handleClose} />
    ];
    return (
      <div style={{ width: 500, margin: "auto" }}>
        <h3>School</h3>
        <TextField
          id="name"
          floatingLabelText="School Name"
          hintText="School Name"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.name}
          onChange={this.handleChange}
        />
        <TextField
          id="address"
          floatingLabelText="Address"
          hintText="Address"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.address}
          onChange={this.handleChange}
        />
        <TextField
          id="city"
          floatingLabelText="City"
          hintText="City"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.city}
          onChange={this.handleChange}
        />
        <SelectField
          id="st"
          floatingLabelText="State"
          fullWidth={true}
          value={this.state.data.st}
          onChange={this.handleStateChange}
        >
          {this.state.st.map((row, index) => (
            <MenuItem key={index} value={row} primaryText={row} />
          ))}
        </SelectField>
        <TextField
          id="zip"
          floatingLabelText="ZIP"
          hintText="ZIP"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.zip}
          onChange={this.handleChange}
        />
        <InputMask
          mask="999-999-9999 ext. 999"
          fullWidth={true}
          errorText="This field is required"
          value={this.state.data.phone}
          onChange={this.handleChange}
          id="phone"
          floatingLabelText="Phone"
          hintText="Phone"
          disabled={false}
        >
          {(inputProps) => <TextField {...inputProps} />}
        </InputMask>
        <TextField
          id="fax"
          floatingLabelText="Fax"
          hintText="Fax"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.fax}
          onChange={this.handleChange}
        />
        <TextField
          id="site"
          floatingLabelText="Website"
          hintText="Website"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.site}
          onChange={this.handleChange}
        />
        <TextField
          id="email"
          floatingLabelText="Email"
          hintText="Email"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.email}
          onChange={this.handleChange}
        />
        <TextField
          id="taxid"
          floatingLabelText="Tax ID#"
          hintText="Tax ID#"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.taxid}
          onChange={this.handleChange}
        />
        <br />
        <h3>Stripe Integration</h3>
        <TextField
          id="stripe_account"
          floatingLabelText="Stripe Account"
          hintText="Stripe Account"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.stripe_account}
          onChange={this.handleChange}
        />
        <TextField
          id="stripe_publishable_key"
          floatingLabelText="Stripe Publishable KEY"
          hintText="Stripe Publishable KEY"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.stripe_publishable_key}
          onChange={this.handleChange}
        />
        <TextField
          id="stripe_secret_key"
          floatingLabelText="Stripe Secret KEY"
          hintText="Stripe Secret KEY"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.stripe_secret_key}
          onChange={this.handleChange}
        />
        <TextField
          id="stripe_webhook_secret"
          floatingLabelText="Stripe WebHook Secret"
          hintText="Stripe WebHook KEY"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.stripe_webhook_secret}
          onChange={this.handleChange}
        />
        <br />
        <b>Webhook URL and events to add in stripe.com account:</b>
        <div className="alert alert--success">{process.env.REACT_APP_API_BASE_LARAVEL_URL}webhook/stripe/{this.state.data.id}</div>
        <ul>
          <li>charge.pending</li>
          <li>charge.succeeded</li>
          <li>charge.refunded</li>
          <li>charge.failed</li>
          <li>payment_method.attached</li>
          <li>payment_method.detached</li>
          <li>customer.updated</li>
          <li>invoice.payment_succeeded</li>
          <li>invoice.payment_failed</li>
        </ul>
        <br />
        <div
          style={{
            display: "flex",
            gap: 10,
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 20,
            paddingBottom: 20
          }}
        >
          <RaisedButton
            label="Save"
            primary={true}
            onClick={this.saveData}
          />
          <RaisedButton
            label="Cancel"
            primary={true}
            style={{ backgroundColor: "#ffd699" }}
            onClick={this.goPreviousPage}
          />
          <RaisedButton
            label="Delete"
            secondary={true}
            onClick={this.handleOpen}
          />
        </div>

        <Dialog
          actions={actions}
          modal={false}
          contentStyle={{ width: "20%", maxWidth: "none" }}
          open={this.state.opendialog}
          onRequestClose={this.handleClose}
        >
          Delete Organisation?
        </Dialog>
        <Dialog
          actions={errors}
          modal={false}
          contentStyle={{ width: "20%", maxWidth: "none" }}
          open={this.state.openerrordialog}
          onRequestClose={this.handleClose}
        >
          {this.state.texterrors}
        </Dialog>
      </div>
    );
  }
}

export default inject("Auth")(observer(EditOrganisations))
