import React, { Component } from "react";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import 'react-toastify/dist/ReactToastify.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


import { Route, BrowserRouter as Router } from "react-router-dom";

import Base from "./components/Layout";

import Teachers from "./pages/Teachers/Index";
import EditTeacher from "./pages/Teachers/Edit";
import NewTeacher from "./pages/Teachers/New";

import Organisations from "./pages/Organisations/Index";
import NewOrganisations from "./pages/Organisations/New";
import EditOrganisations from "./pages/Organisations/Edit";

import Classes from "./pages/Classes/Index";
import NewClasses from "./pages/Classes/New";
import EditClasses from "./pages/Classes/Edit";

import Children from "./pages/Children/Index";
import EditChild from "./pages/Children/Edit";

import Parents from "./pages/Parents/Index";
import NewParent from "./pages/Parents/New";
import EditParent from "./pages/Parents/Edit";

import Programs from "./pages/Finance/Programs.js";

import Invoices from "./pages/Invoices/Index";
import EditInvoice from "./pages/Invoices/Edit";

import Login from "./pages/Login.js";

import ImportExport from "./pages/ImportExport";

import NewChild from "./pages/Children/New.js";

import ChangePassword from "./pages/ChangePassword";

import EditEmergency from "./pages/Children/EditEmergency";
import EditSpecials from "./pages/Children/EditSpecials";

import Admins from "./pages/Administrators/Index";
import NewAdmin from "./pages/Administrators/New";
import EditAdmin from "./pages/Administrators/Edit";

import AllFeatureInvoices from "./pages/Finance/FutureInvoices";
import DOB from "./pages/DOB";

import Dashboard from "./pages/Finance/FinacialCharts";

import InvoiceSchedule from "./pages/Finance/InvoiceSchedule";
import SendArchive from "./pages/Finance/SendArchive";
import Transactions from "./pages/Finance/Transactions";

import MealMenu from "./pages/MealMenu";
import { ToastContainer } from 'react-toastify';
import {
  blue500,
  blue800,
  blue600,
  grey100,
  grey300,
  grey400,
  grey500,
  white,
  darkBlack,
  fullBlack,
  pink400, pink600
} from "material-ui/styles/colors";

import { Provider } from "mobx-react";

import Auth from "./stores/Auth";
import appRoutes from "./utils/appRoutes";
import Roasters from "./pages/Classes/Rosters";
import Attendance from "./pages/Classes/Attendance";
import AttendanceArchive from "./pages/Classes/AttendanceArchive";
import MedicalRecord from "./pages/Children/MedicalRecord";
import ContactsList from "./pages/Parents/ContactsList";
import AllChildren from "./pages/Children/AllChildren";
import ChildrenPageState from "./stores/ChildrenPageState";
import MedicalDoc from "./pages/Teachers/MedicalDoc";
import VaccineRecords from "./pages/VaccineRecords/Index";
import NewVaccineRecord from "./pages/VaccineRecords/New";
import VaccineRecord from "./pages/Children/VaccinelRecord";

const muiTheme = getMuiTheme({
  palette: {
    primary1Color: blue600,
    primary2Color: blue800,
    primary3Color: grey400,
    accent1Color: pink400,
    accent2Color: grey100,
    accent3Color: grey500,
    textColor: darkBlack,
    alternateTextColor: white,
    canvasColor: white,
    borderColor: grey300,
    pickerHeaderColor: blue500,
    shadowColor: fullBlack
  },
  appBar: {
    color: blue800
  },
  drawer: {
    color: blue600,
    textColor: white,
    alternateTextColor: white,
    secondaryTextColor: white
  }
});

const theme = createTheme({
  palette: {
    primary: {
      main: blue600,
    },
    secondary: {
      main: pink600,
    },
  },
});

const stores = { Auth, ChildrenPageState };

class App extends Component {
  render() {
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <ThemeProvider theme={theme}>
          <Provider {...stores}>
            <Router>
              <Base>
                <ToastContainer
                  position="top-center"
                  autoClose={3000}
                  hideProgressBar={true}
                  closeOnClick
                  rtl={false}
                />
                <Route path="/login" component={Login} />
                <Route exact path="/" component={Children} />
                <Route path="/teachers" component={Teachers} />
                <Route path="/newteacher" component={NewTeacher} />
                <Route exact path="/editteacher/:id" component={EditTeacher} />
                <Route exact path={appRoutes.teacherMedicalDoc()} component={MedicalDoc} />
                <Route exact path={appRoutes.teacherMedicalDocEdit()} component={MedicalDoc} />

                <Route path="/newchild" component={NewChild} />
                <Route path="/parents" component={Parents} />
                <Route path="/contact-list" component={ContactsList} />
                <Route path="/newparent" component={NewParent} />
                <Route exact path="/editparent/:id" component={EditParent} />

                <Route path="/children" component={Children} />
                <Route path={appRoutes.allChildren} component={AllChildren} />
                <Route path="/dob" component={DOB} />
                <Route path="/dashboard" component={Dashboard} />

                <Route exact path={appRoutes.childEdit()} component={EditChild} />
                <Route exact path={appRoutes.editSpecialNeeds()} component={EditSpecials} />
                <Route exact path={appRoutes.addMedicalRecord()} component={MedicalRecord} />
                <Route exact path={appRoutes.editMedicalRecord()} component={MedicalRecord} />
                <Route exact path={appRoutes.childAddVaccineRecord()} component={VaccineRecord} />
                <Route exact path={appRoutes.childEditVaccineRecord()} component={VaccineRecord} />
                <Route exact path="/editemergency/:id" component={EditEmergency} />
                <Route exact path="/editspecials/:id" component={EditSpecials} />

                <Route path="/organisations" component={Organisations} />
                <Route path="/neworganisations" component={NewOrganisations} />
                <Route exact path="/editorganisations/:id" component={EditOrganisations} />

                <Route path="/classes" component={Classes} />
                <Route path="/class-rosters" component={Roasters} />
                <Route exact path="/attendance" component={Attendance} />
                <Route path="/attendance/archive" component={AttendanceArchive} />
                <Route path="/newclasses" component={NewClasses} />
                <Route exact path="/editclasses/:id" component={EditClasses} />

                <Route path="/admins" component={Admins} />
                <Route path="/newadmin" component={NewAdmin} />
                <Route path="/editadmin/:id" component={EditAdmin} />

                <Route path={appRoutes.vaccineRecords} component={VaccineRecords} />
                <Route path={appRoutes.addVaccineRecord} component={NewVaccineRecord} />
                <Route path={appRoutes.editVaccineRecord()} component={NewVaccineRecord} />

                <Route path="/programs" component={Programs} />
                <Route path="/invoices" component={Invoices} />
                <Route path="/allfeatureinvoices" component={AllFeatureInvoices} />
                <Route exact path="/editinvoice/:id" component={EditInvoice} />

                <Route path="/imex" component={ImportExport} />
                <Route path="/transactions" component={Transactions} />
                <Route path="/schedule" component={InvoiceSchedule} />
                <Route path="/sendarchive" component={SendArchive} />
                <Route path="/changepassword" component={ChangePassword} />
                <Route path="/mealmenu" component={MealMenu} />
              </Base>
            </Router>
          </Provider>
        </ThemeProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
