import React, {useState} from "react";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export const ExpandedField = ({title, children, onAddClick, onAddTitle, ...anotherProps}) => {
	const addTitle = onAddTitle ?? 'Add'

	const [expanded, setExpanded] = useState(false)

	return (
		<div {...anotherProps}>
			<div className='titleWrapper'>
				<div>{title}</div>
				<Fab onClick={() => setExpanded(!expanded )}
					color="primary"
					aria-label="add"
					size='small'
					title={expanded ? "Hide" : "Show"}
					sx={{ minHeight: 'unset', height: '28px', width: '28px'}}
				>
					{expanded ? (
						<RemoveIcon sx={{ fontSize: '20px' }} />
					) : (
						<AddIcon sx={{ fontSize: '20px' }} />
					)}
				</Fab>
			</div>

			{expanded && <div>
				{children}
				{onAddClick && <Fab
					sx={{ marginTop: '10px' }}
					onClick={() => onAddClick()}
					color="secondary"
					aria-label="add"
					size='small'
					title={addTitle}
				>
					<AddIcon />
				</Fab>}
			</div>}
		</div>
	)
}
