import API from "./api";
import {getAllParents} from "./operations";

export const checkIfParentExistWithEmail = async email => {
  try {
    const res = await API.get(getAllParents,
      { params: { filter: `email = ${email}` }
      });
    return res.data.resource.length > 0;
  } catch (e) {
    return false;
  }
};