import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "material-ui/TextField";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import API from '../../utils/api';

import states from "../../utils/states.js";
import { getCurrentOrg } from "../../utils/operations.js";
import { observer, inject } from "mobx-react";
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

class NewOrganisations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: "",
        address: "",
        city: "",
        st: "",
        zip: "",
        phone: "",
        fax: "",
        site: "http://www.stepstosuccess.com/",
        email: "",
        taxid: "",
        stripe_account: "",
        stripe_publishable_key: "",
        stripe_secret_key: "",
        stripe_webhook_secret: "",
      },
      st: states,
      opendialog: false,
      texterrors: ""
    };
  }

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  saveData =  async () => {
    let data = {
      resource: [this.state.data]
    };

    try {
      await API.post(getCurrentOrg, data)
      toast.success('Organization added successfully!')
      this.props.Auth.getAllOrgs()
      setTimeout(this.props.history.push("/organisations"), 1000)
    } catch (e) {
      toast.error('Something went wrong!')
    }
  };

  handleOpen = () => {
    this.setState({ opendialog: true });
  };

  handleClose = () => {
    this.setState({ opendialog: false });
  };

  handleChange = event => {
    const data = this.state.data;
    data[event.target.id] = event.target.value;
    this.setState({ data });
  };

  handleStateChange = (event, index, value) => {
    const data = this.state.data;
    data.st = value;
    this.setState({ data });
  };

  goPreviousPage = () => {
    setTimeout(this.props.history.push("/organisations"), 100);
  };

  render() {
    const actions = [
      <FlatButton label="Cancel" primary={true} onClick={this.handleClose} />
    ];
    return (
      <div style={{ maxWidth: 500, margin: "auto" }}>
        <h3>School</h3>
        <TextField
          id="name"
          hintText="School Name"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.name}
          onChange={this.handleChange}
        />
        <TextField
          id="address"
          hintText="Address"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.address}
          onChange={this.handleChange}
        />
        <TextField
          id="city"
          hintText="City"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.city}
          onChange={this.handleChange}
        />
        <SelectField
          id="st"
          floatingLabelText="State"
          fullWidth={true}
          value={this.state.data.st}
          onChange={this.handleStateChange}
        >
          {this.state.st.map((row, index) => (
            <MenuItem key={index} value={row} primaryText={row} />
          ))}
        </SelectField>
        <TextField
          id="zip"
          hintText="ZIP"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.zip}
          onChange={this.handleChange}
        />
        <InputMask
          mask="999-999-9999 ext. 999"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.phone}
          onChange={this.handleChange}
          id="phone" hintText="Phone"
          disabled={false}
        >
          {(inputProps) => <TextField {...inputProps} />}
        </InputMask>
        <TextField
          id="fax"
          hintText="Fax"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.fax}
          onChange={this.handleChange}
        />
        <TextField
          id="site"
          hintText="Website"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.site}
          onChange={this.handleChange}
        />
        <TextField
          id="email"
          hintText="Email"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.email}
          onChange={this.handleChange}
        />
        <TextField
          id="taxid"
          hintText="Tax ID#"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.taxid}
          onChange={this.handleChange}
        />
        <br />
        <h3>Stripe Integration</h3>
        <TextField
          id="stripe_account"
          floatingLabelText="Stripe Account"
          hintText="Stripe Account"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.stripe_account}
          onChange={this.handleChange}
        />
        <TextField
          id="stripe_publishable_key"
          floatingLabelText="Stripe Publishable KEY"
          hintText="Stripe Publishable KEY"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.stripe_publishable_key}
          onChange={this.handleChange}
        />
        <TextField
          id="stripe_secret_key"
          floatingLabelText="Stripe Secret KEY"
          hintText="Stripe Secret KEY"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.stripe_secret_key}
          onChange={this.handleChange}
        />
        <TextField
          id="stripe_webhook_secret"
          floatingLabelText="Stripe WebHook Secret"
          hintText="Stripe WebHook KEY"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.stripe_webhook_secret}
          onChange={this.handleChange}
        />

        <div
          style={{
            display: "flex",
            gap: 10,
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 20,
            paddingBottom: 20
          }}
        >
          <RaisedButton
            label="Save"
            primary={true}
            onClick={this.saveData}
          />
          <RaisedButton
            label="Cancel"
            secondary={true}
            onClick={this.goPreviousPage}
          />
        </div>

        <Dialog
          title="Form errors:"
          actions={actions}
          modal={false}
          contentStyle={{ width: "40%", maxWidth: "none" }}
          open={this.state.opendialog}
          onRequestClose={this.handleClose}
        >
          <div>
            {this.state.texterrors.split("\n").map((i, index) => {
              return <div key={index}>{i}</div>;
            })}
          </div>
        </Dialog>
      </div>
    );
  }
}

export default inject("Auth")(observer(NewOrganisations))
