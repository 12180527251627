import React, {useEffect, useMemo, useState} from "react";

import { observer, inject } from "mobx-react";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import API from "../../utils/api";
import { api2AttendanceArchive, api2AttendanceItem } from "../../utils/operations";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from "@mui/material";
import {modalStyle, monthFormatter} from "../../utils/misc";
import RaisedButton from "material-ui/RaisedButton";
import {toast} from "react-toastify";
import Loader from "../../components/Loader";


const useStyles = makeStyles({
  table: {
    marginTop: "20px",
    "& .MuiTableCell-root": {
      border: '1px solid black',
      fontWeight: "bold !important",
      textAlign: "center",
    }
  }
});

const defaultFilter = {
  name: '',
  sick: false,
  vacation: false,
  date: new Date(),
  classes_id: '',
  day: '',
}


const AttendanceArchive = (props) => {
  const styles = useStyles();
  const orgId = props.Auth.orgId
  const [refreshId, setRefreshId] = useState(1)
  const [loading, setLoading] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)

  const [childNames, setChildNames] = useState({})
  const [classes, setClasses] = useState([])
  const [data, setData] = useState([])
  const [weekDays, setWeekDays] = useState([])
  const [childrenIdsWithSick, setChildrenIdsWithSick] = useState([])
  const [childrenIdsWithVacation, setChildrenIdsWithVacation] = useState([])

  const [editedAttendance, setEditedAttendance] = useState([])

  const [filter, setFilter] = useState(defaultFilter)

  let tableIterator = 0

  useEffect(() => {
    loadData().then()
  }, [filter.date])

  const loadData = async () => {
    setLoading(true)

    const [
      attendanceArchive,
    ] = await Promise.all([
      API.post(api2AttendanceArchive(orgId), {
        date: filter.date,
      })
    ]);

    setChildrenIdsWithSick(attendanceArchive.data.data.childrenIdsWithSick)
    setChildrenIdsWithVacation(attendanceArchive.data.data.childrenIdsWithVacation)
    setChildNames(attendanceArchive.data.data.childNames)
    setWeekDays(attendanceArchive.data.data.weekDays)
    setClasses(attendanceArchive.data.data.classes)
    setData(attendanceArchive.data.data.attendanceArchive)

    setLoading(false)
  };

  const childNameToIds = useMemo(() => {
    if (!filter.name) {
      return []
    }

    let ids = []
    Object.keys(childNames).forEach(key => {
      const childName = childNames[key].toLowerCase()
      if (childName.includes(filter.name.toLowerCase())) {
        ids.push(key)
      }
    })

    return ids
  }, [filter.name])

  const handleChange = (event) => {
    const name = event.target.name;
    setFilter({ ...filter, [name]: event.target.value });
  };

  const handleChangeDate = (value) => {
    setFilter({ ...filter, date: value });
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
  };

  const handleClearFilters = () => {
    setFilter(defaultFilter)
    setRefreshId(refreshId + 1)
  };

  const handleChangeCheckbox = (event) => {
    const name = event.target.name;
    setFilter({ ...filter, [name]: event.target.checked });
  };

  const findChildName = (childId) => childNames.hasOwnProperty(childId) ? childNames[childId] : 'UNKNOWN'

  const printTrackerReport = () => window.print()

  const handleEditAttendance = (attendance) => {
    setEditedAttendance(attendance)
    setShowEditModal(true)
  };

  const handleEditAttendanceValue = (event) => {
    setEditedAttendance({
      ...editedAttendance,
      attendance: event.target.value,
    })
  }

  const handleSaveAttendanceClick = async () => {
    try {
      await API.patch(api2AttendanceItem(editedAttendance.id), {
        attendance: editedAttendance.attendance,
      })
      loadData().then()
      setShowEditModal(false)
      toast.success('Data saved successfully!');
    } catch (e) {
      toast.error('Error on data save!');
    }
  }

  const AttendanceCell = (props) => {
    if (filter.day && filter.day !== props.day) {
      return <TableCell sx={{ backgroundColor: 'lightgrey' }}></TableCell>
    }


    if (!props.hasOwnProperty('attendance')) {
      return <TableCell>-</TableCell>
    }

    let attendanceSymbol = '-'
    let bgColor = 'white'

    switch (props.attendance) {
      case 'present':
        attendanceSymbol = '+'
        bgColor = '#2e7d32'
        break

      case 'sick':
        attendanceSymbol = 'S'
        bgColor = '#f44336'
        break

      case 'vacation':
        attendanceSymbol = 'V'
        bgColor = '#ffcc00'
        break

      case 'absentee':
        attendanceSymbol = 'A'
        bgColor = '#ff9800'
        break
    }

    return <TableCell
      sx={{ backgroundColor: bgColor, cursor: "pointer"}}
      onClick={() => handleEditAttendance(props)}
    >
      {attendanceSymbol}
    </TableCell>
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="attendance">

        <div className="tabsMenu no-print">
          <Tabs value="attendance-archive"
                indicatorColor="primary"
                textColor="primary"
          >
            <Tab value="attendance" label="Current Day Attendance" onClick={() => props.history.push('/attendance')} />
            <Tab value="attendance-archive" label="Archive" />
          </Tabs>
        </div>

        <form onSubmit={handleFormSubmit}>
          <div key={refreshId} className="filters filters--archive no-print">
            <FormControl className='filtersSelector'>
              <InputLabel id="class-selector">Class</InputLabel>
              <Select
                labelId="class-selector"
                name="classes_id"
                value={filter.classes_id}
                onChange={handleChange}
                size="small"
                variant="standard"
              >
                {classes.map(row => (<MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>))}
              </Select>
            </FormControl>

            <DatePicker
              slotProps={{ textField: { variant: 'standard' }}}
              name='date'
              views={['year', 'month']}
              label="Year and Month"
              value={filter.date}
              onChange={handleChangeDate}
            />

            <FormControl className='filtersSelector'>
              <InputLabel id="day-selector">Day</InputLabel>
              <Select
                labelId="day-selector"
                name="day"
                value={filter.day}
                onChange={handleChange}
                size="small"
                variant="standard"
              >
                {weekDays.map(d => (<MenuItem key={d} value={d}>{d}</MenuItem>))}
              </Select>
            </FormControl>

            <TextField
              label='Child Name'
              name='name'
              value={filter.name}
              onChange={handleChange}
              size="small"
              variant="standard"
            />

            <div className="filtersItem--grouped">
              <FormControlLabel control={<Checkbox name='sick' size="small" value={filter.sick} onChange={handleChangeCheckbox} />} label="Sick" />
              <FormControlLabel control={<Checkbox name='vacation' size="small" value={filter.vacation} onChange={handleChangeCheckbox} />} label="Vacation" />
            </div>

            <div className="filtersItem--grouped">
              <Button variant="outlined" onClick={handleClearFilters}>Clear</Button>
              <Button variant="outlined" onClick={printTrackerReport}>Print Tracker Report</Button>
            </div>

          </div>
        </form>

        <Loader loaded={!loading}>
          <div className="attendanceArchiveContainer" id="attendance-archive-container">
            {classes.map(oClass => {
              const attendanceData = data.hasOwnProperty(oClass.id) ? data[oClass.id] : null
              let childIds = attendanceData ? Object.keys(attendanceData) : []

              if (filter.classes_id && oClass.id !== filter.classes_id) {
                return '';
              }

              childIds = childIds.filter(childId => {
                const childIdClean = childId.replace('cid-', '')
                let willReturn = true

                if (filter.name) {
                  willReturn = (willReturn && childNameToIds.includes(childIdClean))
                }

                if (filter.sick && filter.vacation) {
                  willReturn = (willReturn && (childrenIdsWithSick.includes(parseInt(childIdClean)) && childrenIdsWithVacation.includes(parseInt(childIdClean))))
                } else {
                  if (filter.sick) {
                    willReturn = (willReturn && childrenIdsWithSick.includes(parseInt(childIdClean)))
                  }

                  if (filter.vacation) {
                    willReturn = (willReturn && childrenIdsWithVacation.includes(parseInt(childIdClean)))
                  }
                }

                return willReturn
              })

              if (childIds.length === 0) {
                return ''
              }

              tableIterator++

              if (attendanceData) {
                return (
                  <>
                    {tableIterator > 1 && <div className="attendance-page-break"></div>}

                    <Table key={'class-' + oClass.id} className={styles.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2 + weekDays.length} sx={{ textAlign: "center" }}>
                            Group: {oClass.name}<br/>{monthFormatter(filter.date)}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell width="20px" style={{ textAlign: 'left'}}>#</TableCell>
                          <TableCell width="200px" style={{ textAlign: 'left'}}>Child Name</TableCell>
                          {weekDays.map(d => (<TableCell key={'week-day-' + d}>{d}</TableCell>))}
                        </TableRow>

                        {childIds.map((childId, index) => {
                          const childIdClean = childId.replace('cid-', '')
                          const attendanceItems = attendanceData[childId]

                          return (
                            <TableRow key={'child-' + childIdClean}>
                              <TableCell style={{ textAlign: 'left'}}>{index + 1}</TableCell>
                              <TableCell style={{ textAlign: 'left'}}>{findChildName(childIdClean)}</TableCell>
                              {weekDays.map(day => {
                                const attendanceItem = attendanceItems.hasOwnProperty(day) ? attendanceItems[day] : null
                                return <AttendanceCell
                                  key={'week-day-' + day}
                                  {...attendanceItem}
                                  childName={findChildName(childIdClean)}
                                  day={day}
                                />
                              })}
                            </TableRow>
                          )
                        })}

                      </TableBody>
                    </Table>
                  </>
                )
              }

            })}
          </div>
        </Loader>

        <Modal
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <div style={{textAlign: 'center', fontSize: '18px'}}>
              Attendance
              <br/>{editedAttendance.childName}
              <br/>{editedAttendance.day} of {monthFormatter(filter.date)}
            </div>
            <div style={{ fontSize: '18px', marginTop: '30px', marginBottom: '30px', textAlign: 'center' }}>
              <Select
                value={editedAttendance.attendance}
                onChange={(event) => handleEditAttendanceValue(event)}
                size='small'
                variant='standard'
              >
                <MenuItem value='present'>Present</MenuItem>
                <MenuItem value='sick'>Sick</MenuItem>
                <MenuItem value='vacation'>Vacation</MenuItem>
                <MenuItem value='absentee'>Absentee Month</MenuItem>
              </Select>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
              <RaisedButton
                label="Save"
                primary
                onClick={handleSaveAttendanceClick}
              />
              <RaisedButton
                label="Cancel"
                onClick={() => setShowEditModal(false)}
              />
            </div>

          </Box>
        </Modal>
      </div>
    </LocalizationProvider>
  );
}

export default inject("Auth")(observer(AttendanceArchive))
