import React, { Component } from "react";
import axios from "axios";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";

import LinearProgress from "material-ui/LinearProgress";
import { divProgressHide, divProgressShow } from "../../css/additional.js";

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from "@mui/material";

import { observer, inject } from "mobx-react";

import { getCurrentOrg } from "../../utils/operations.js";
import withStyles from '@mui/styles/withStyles';

import API from '../../utils/api';
import { api2Admins } from '../../utils/operations';

const style = {
  marginLeft: 'calc(50% - 20px)',
  marginTop: 30,
};

const ClickableRow = props => {
  const { userID, history, ...restProps } = props;

  const editData = value => {
    const editUrl = "/editadmin/" + value;
    history.push(editUrl);
  };

  return (
    <TableRow {...restProps} onMouseDown={() => editData(userID)} style={{cursor: 'pointer'}}>
      {props.children}
    </TableRow>
  );
};

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(142, 195, 242, 0.4)',
    },
    '&:hover': {
      backgroundColor: '#f5f5f5',
    }
  },
}))(ClickableRow);

class Admins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProgress: true,
      organisations: [],
      admins: []
    };
  }

  componentDidMount() {
    if (!this.props.Auth.can('superadmin')) return this.props.history.push("/children");
    this.loadData();
  }

  loadData = () => {
    let that = this;

    axios
      .all([
        API.get(getCurrentOrg),
        API.get(api2Admins)
      ])
      .then(
        axios.spread(function(organisations, admins) {
          let orgs = organisations.data.resource;
          let adms = admins.data.admins;

          adms = adms.map((a) => {
            const a_org = orgs.find(o => o.id === a.organisation_id)
            return {...a, org_name: a_org ? a_org.name: ''}
          })

          that.setState({ organisations: orgs, admins: adms, showProgress: false });
        })
      );
  };

  editData = value => {
    const editUrl = "/editadmin/" + value;
    this.props.history.push(editUrl);
  };

  newUser = () => {
    const newUrl = "/newadmin/";
    this.props.history.push(newUrl);
  };

  render() {
    const edit = this.props.history;
    return (
      <div style={{ margin: "auto", overflowX: 'scroll' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={40}></TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>School</TableCell>
              <TableCell>Additional Access</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.admins.map((row, index) => (
              <StyledTableRow key={index} userID={row.id} history={edit}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.org_name}</TableCell>
                <TableCell>{row.organisations.map((o) => <div key={o.id}>{o.name}</div>)}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <div
          style={this.state.showProgress ? divProgressShow : divProgressHide}
        >
          <LinearProgress mode="indeterminate" />
        </div>
        <FloatingActionButton
          style={style}
          secondary={true}
          onClick={this.newUser}
        >
          <ContentAdd />
        </FloatingActionButton>
      </div>
    );
  }
}

export default inject("Auth")(observer(Admins))
