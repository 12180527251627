import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";
import Checkbox from "material-ui/Checkbox";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";

import {getAllAdmins, getCurrentOrg, dreamUsers, dreamUserWithRoles} from "../../utils/operations.js";

import { observer, inject } from "mobx-react";

import API from '../../utils/api';
import { toast } from 'react-toastify';
import { api2AdminAdditionalOrganizations } from '../../utils/operations';
import Grid from '@mui/material/Grid';
import {
  DREAM_ROLE_ADMIN,
  DREAM_ROLE_SUPERADMIN
} from "../../utils/constants";
import {getUserRolesObject, roleIdToPhoneRole} from "../../utils/misc";
class NewAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: "",
        organisation_id: "",
        role_id: DREAM_ROLE_ADMIN,
        role: roleIdToPhoneRole(DREAM_ROLE_ADMIN),
        email: "",
        password: "",
        show_finance: true,
        edit_finance: false,
        show_charts: false,
        show_future_invoices: false,
      },
      admin_organisations: [],
      organisations: [],
      opendialog: false,
      texterrors: "",
      showProgress: false
    };
  }

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    if (!this.props.Auth.can('superadmin')) return this.props.history.push("/children");
    this.loadData();
  }

  loadData = () => {
    let that = this;
    API.get(getCurrentOrg).then(res => {
      let organisations = res.data.resource;

      that.setState({ organisations });
      that.setState({ showProgress: false });
    });
  };

  // Запись данных в базу
  saveData = async () => {
    try {
      const newUser = {
        resource: [
          {
            name: this.state.data.name,
            first_name: this.state.data.name,
            last_name: this.state.data.name,
            is_active: true,
            email: this.state.data.email,
            password: this.state.data.password,
            phone: this.state.data.role
          }
        ]
      };
      const createdUser = await API.post(dreamUsers, newUser)

      const admin_id = createdUser.data.resource[0].id;
      const role_id = this.state.data.role_id;

      await API.put(dreamUserWithRoles(admin_id), getUserRolesObject(admin_id, role_id))

      const data = {
        resource: [
          {
            id: admin_id,
            name: this.state.data.name,
            organisation_id: this.state.data.organisation_id,
            email: this.state.data.email,
            password: this.state.data.password,
            show_finance: this.state.data.show_finance,
            edit_finance: this.state.data.edit_finance,
            show_charts: this.state.data.show_charts,
            show_future_invoices: this.state.data.show_future_invoices,
          }
        ]
      };
      await API.post(getAllAdmins, data);

      const admin_organizations = this.state.admin_organisations.filter(id => id !== this.state.data.organisation_id)
      await API.post(api2AdminAdditionalOrganizations(admin_id), admin_organizations)

      toast.success('Administrator successfully added!')
      setTimeout(this.props.history.push("/admins"), 1000);
    } catch (e) {
      toast.error('Something went wrong!')
    }
  };

  // Открыть диалог
  handleOpen = () => {
    this.setState({ opendialog: true });
  };

  // Закрыть диалог
  handleClose = () => {
    this.setState({ opendialog: false });
  };

  // Записать Select в state
  handleOrgChange = (event, index, value) => {
    let data = this.state.data;
    data.organisation_id = value;
    this.setState({ data });
  };

  handleRoleChange = (event, index, value) => {
    const phoneRole = roleIdToPhoneRole(value)
    let data = this.state.data
    data.role_id = value
    data.role = phoneRole
    this.setState({ data })
  };

  // Записать данные в state
  handleChange = event => {
    const data = this.state.data;
    data[event.target.id] = event.target.value;
    this.setState({ data });
  };

  // Перейти на предыдущую страницу
  goPreviousPage = () => {
    const goUrl = "/admins";
    setTimeout(this.props.history.push(goUrl), 1000);
  };

  checkEmail = email => {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  checkInfo = () => {
    let errors = [];

    let data = this.state.data;
    if (data.name === "") {
      errors.push(<span>* Name cannot be empty</span>);
      errors.push(<br />);
    }
    if (data.organisation_id === "") {
      errors.push(<span>* Organisation cannot be empty</span>);
      errors.push(<br />);
    }

    if (!this.checkEmail(data.email)) {
      errors.push(<span>* Email must be valid</span>);
      errors.push(<br />);
    }

    if (data.password.length < 6) {
      errors.push(<span>* Password length must be at least 6 symbols</span>);
      errors.push(<br />);
    }

    if (errors.length === 0) {
      return true;
    } else {
      this.setState({ texterrors: errors });
      return false;
    }
  };

  saveInfo = () => {
    if (this.checkInfo()) {
      this.saveData();
    } else {
      this.handleOpen();
    }
  };

  handlePermissionsToggle = (permissionName) => {
    if (this.props.Auth.role !== 'superadmin') {
      return
    }

    let data = this.state.data;
    data[permissionName] = !data[permissionName];
    this.setState({ data });
  }

  render() {
    const actions = [
      <FlatButton key='btn-cancel' label="Cancel" primary={true} onClick={this.handleClose} />
    ];
    return (
      <div style={{ maxWidth: 500, margin: "auto" }}>
        <br />
        <TextField
          id="name"
          hintText="Name"
          errorText="This field is required"
          floatingLabelText="Name"
          fullWidth={true}
          value={this.state.data.name}
          onChange={this.handleChange}
        />
        {this.props.Auth.role === 'superadmin' && <div>
          <SelectField
            id="role"
            floatingLabelText="Role"
            errorText="This field is required"
            fullWidth={true}
            value={this.state.data.role_id}
            onChange={this.handleRoleChange}
          >
              <MenuItem key={'admin'} value={DREAM_ROLE_ADMIN} primaryText={'Admin'} />
              <MenuItem key={'superadmin'} value={DREAM_ROLE_SUPERADMIN} primaryText={'Superadmin'} />
          </SelectField>
          <br />
        </div>}
        <br />
        <SelectField
          id="organisation"
          floatingLabelText="School"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.organisation_id}
          onChange={this.handleOrgChange}
        >
          {this.state.organisations.map(row => (
            <MenuItem key={row.id} value={row.id} primaryText={row.name} />
          ))}
        </SelectField>
        <br />
        <TextField
          id="email"
          hintText="Email"
          floatingLabelText="Email"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.email}
          onChange={this.handleChange}
        />
        <br />
        <TextField
          id="password"
          hintText="Password"
          errorText="This field is required"
          floatingLabelText="Password"
          fullWidth={true}
          value={this.state.data.password}
          onChange={this.handleChange}
        />
        {this.state.data.role_id !== DREAM_ROLE_SUPERADMIN && <Grid container spacing={3} style={{ marginTop: '20px'}} >
          <Grid item xs={6}>
            <Checkbox
              label="Show Finance Info"
              checked={this.state.data.show_finance}
              onCheck={() => this.handlePermissionsToggle('show_finance')}
            />
            <br />
            <Checkbox
              label="Edit Finance"
              checked={this.state.data.edit_finance}
              onCheck={() => this.handlePermissionsToggle('edit_finance')}
            />
            <br />
          </Grid>
          <Grid item xs={6}>
            <Checkbox
              label="View Future Invoices"
              checked={this.state.data.show_future_invoices}
              onCheck={() => this.handlePermissionsToggle('show_future_invoices')}
            />
            <br />
            <Checkbox
              label="View Financial Charts"
              checked={this.state.data.show_charts}
              onCheck={() => this.handlePermissionsToggle('show_charts')}
            />
            <br />
          </Grid>
        </Grid>}
        <br />
        <SelectField
          multiple={true}
          id="organisation"
          floatingLabelText="Additional schools"
          fullWidth={true}
          value={this.state.admin_organisations}
          onChange={(event, index, value) => { this.setState({admin_organisations: value})}}
        >
          {this.state.organisations.filter(o => o.id !== this.state.data.organisation_id).map(row => (
            <MenuItem key={row.id} value={row.id} primaryText={row.name} />
          ))}
        </SelectField>
        <br />
        <div
          style={{
            margin: "auto",
            width: 200,
            paddingTop: 20,
            paddingBottom: 20
          }}
        >
          <RaisedButton
            label="Save"
            primary={true}
            style={{ marginRight: 10 }}
            onClick={this.saveInfo}
          />
          <RaisedButton
            label="Cancel"
            secondary={true}
            style={{ marginLeft: 10 }}
            onClick={this.goPreviousPage}
          />
        </div>
        <Dialog
          title="Form errors:"
          actions={actions}
          modal={false}
          contentStyle={{ width: "40%", maxWidth: "none" }}
          open={this.state.opendialog}
          onRequestClose={this.handleClose}
        >
          <div>{this.state.texterrors}</div>
        </Dialog>
      </div>
    );
  }
}

export default inject("Auth")(observer(NewAdmin))
