import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";
import Toggle from "material-ui/Toggle";
import moment from "moment";

import {
  getCurrentInvoice,
  getChildPlans,
  getCurrentOrg,
  getAllDepos,
  getCurrentChildPlans,
  getCurrentChild,
  getNMP, api2BillById
} from "../../utils/operations.js";

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from "@mui/material";

import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";

import { observer, inject } from "mobx-react";
import { Document, Page } from "react-pdf";
import CircularProgress from "material-ui/CircularProgress";
import {
  api2AllPlans, api2ChildDeleteInvoice,
  api2ChildEmailInvoice,
  api2ChildGetFutureInvoice, api2ChildLogFinance,
  api2ChildUpdateInvoice, api2PlanGroups,
  api2PublicStorage,
} from "../../utils/operations";

import API from '../../utils/api'
import { toast } from 'react-toastify';

import Paper from '@mui/material/Paper';
import appRoutes from "../../utils/appRoutes";

const AriaModal = require("react-aria-modal");

class EditInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      plans: [],
      selectedPlanGroup: 0,
      planGroups: [],
      allplans: [],
      plansWasChanged: false,
      depos: [],
      child: {},
      invoice: {},
      invoiceDate: '',
      paymentAmount: 0,
      nextMonthPayment: 0,
      paidOriginal: 0,
      nextMonthPaymentOriginal: 0,
      memoOriginal: '',
      nmpId: null,
      organisation: {},
      onPartialChange: false,
      opendialog: false,
      texterrors: "",
      showProgress: true,
      programFilterValue: '',
    };
  }

  handleOpen = () => {
    this.setState({ opendialog: true });
  };

  handleClose = () => {
    this.setState({ showProgress: false });
    this.setState({ opendialog: false });
  };

  static propTypes = {
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.Auth.snackBar = false;
    this.loadData();
  }

  loadData = async () => {
    const itemId = this.props.match.params.id;

    let res = await API.get(getCurrentInvoice + itemId);

    let invoice = res.data;
    const invoiceDate = invoice.gendate.substring(0, 10);
    let child_id = invoice.child_id;

    this.setState({ invoice, paidOriginal: Number(invoice.paid)});

    let [planGroups, child, depos, cplans, currentOrg, all, nmp] = await axios.all([
      API.get(api2PlanGroups),
      API.get(getCurrentChild + child_id),
      API.get(getAllDepos + "?related=admins_by_user_id&filter=invoice_id%3D" + itemId),
      API.get(getChildPlans + res.data.child_id),
      API.get(getCurrentOrg + res.data.organisation_id),
      API.get(api2AllPlans),
      API.get(getNMP + "?filter=child_id%3D" + child_id)
    ]);

    let allplans = all.data;

    let organisation = currentOrg.data;
    let gotNMP = nmp.data.resource;

    if (gotNMP && gotNMP.length > 0) {
      this.setState({
        nextMonthPaymentOriginal: gotNMP[0].amount,
        nextMonthPayment: gotNMP[0].amount,
        nmpId: gotNMP[0].id
      });
    }

    this.setState({
      invoiceDate: invoiceDate,
      planGroups: planGroups.data,
      organisation,
      child: child.data,
      memoOriginal: child.data.memo,
      depos: depos.data.resource,
    });

    let childPlans = cplans.data.resource;
    let plans = childPlans.map(p => p.plan_id);

    let tempPlans = [];
    for (let i = 0; i < allplans.length; i++) {
      tempPlans.push({
        id: allplans[i].id,
        plan_group_id: allplans[i].plan_group_id,
        name: allplans[i].name,
        price: allplans[i].price,
        sort_score: allplans[i].sort_score,
        toggled: plans.includes(allplans[i].id)
      });
    }

    tempPlans.sort(function(a, b) {
      return a.sort_score - b.sort_score;
    });

    this.setState({
      plans,
      allplans: tempPlans,
    });
  };

  deleteData = async () => {
    const childId = this.state.child.id

    try {
      await API.delete(api2ChildDeleteInvoice(childId))
      toast.success('Invoice deleted successfully!')
      setTimeout(this.props.history.push("/invoices"), 1000);
    } catch (e) {
      toast.error('Something went wrong!')
    }
    this.setState({ opendialog: false })
  };

  saveNextMonthPayment = async () => {
    const childId = this.state.child.id;
    const nextMonthPayment = isNaN(this.state.nextMonthPayment) ? 0 : this.state.nextMonthPayment;
    const nextMonthPaymentDiff = nextMonthPayment - this.state.nextMonthPaymentOriginal;

    if (nextMonthPaymentDiff === 0) {
      return;
    }

    let nmpData = {
      child_id: childId,
      amount: Number(nextMonthPayment)
    };

    try {
      if (this.state.nmpId) {
        await API.patch(getNMP + "/" + this.state.nmpId, nmpData)
      } else {
        await API.post(getNMP, { resource: [nmpData] })
      }

      await API.post(api2ChildLogFinance(childId), {
        amount: Number(nextMonthPaymentDiff),
        type: 'nextmonthpayment'
      })
    } catch (error) {
      let issues = error.response.data._issues;
      let texterrors = "";

      for (const key in issues) {
        texterrors += key + ": " + issues[key] + "\n";
      }

      toast.error(texterrors)
    }
  };

  saveData = async () => {
    this.setState({ showProgress: true });
    let child = this.state.child;
    const paidDiff =  Number(this.state.invoice.paid) - Number(this.state.paidOriginal)

    try {
      if (this.state.memoOriginal !== child.memo) {
        await API.patch(getCurrentChild + child.id, { memo: child.memo })
      }

      // If partial paid changed - log user id
      if (this.state.onPartialChange) {
        await API.post(getAllDepos, {
          resource: [{
            invoice_id: this.state.invoice.id,
            amount: Number(this.state.invoice.paid),
            user_id: this.props.Auth.profile.id,
            description: 'Payment'
          }]
        })
      }
    } catch (e) {
      let issues = e.response.data._issues;
      let texterrors = "";

      for (const key in issues) {
        texterrors += key + ": " + issues[key] + "\n";
      }

      toast.error(texterrors)
    }

    let allplans = this.state.allplans;
    let amount = 0;
    let total = 0;
    let planData = [];

    allplans.forEach((plan) => {
      if (plan.toggled) {
        planData.push({ child_id: this.state.invoice.child_id, plan_id: plan.id });
        amount += plan.price;
        total += plan.price;
      }
    })

    let invoiceData = {...this.state.invoice};
    invoiceData.action = "to_generate_new";
    invoiceData.amount = amount;
    invoiceData.total = total - invoiceData.paid;
    if (invoiceData.paid >= amount) {
      invoiceData.status = "paid";
    }

    try {
      if (this.state.plansWasChanged) {
        await API.delete(getCurrentChildPlans + "?id_field=child_id&filter=child_id%3D" + this.state.invoice.child_id)
        if (planData.length > 0) {
          await API.post(getCurrentChildPlans, { resource: planData })
        }
      }

      await this.saveNextMonthPayment()

      await API.patch(api2BillById(invoiceData.id), invoiceData)

      if (paidDiff > 0) {
        await API.post(api2ChildLogFinance(this.state.invoice.child_id), {
          amount: Number(paidDiff),
          type: 'depo'
        })
      }

      toast.success('Invoice edited successfully! Invoice PDF file will be regenerated in few seconds.')
      setTimeout(this.props.history.push("/invoices"), 1000);
    } catch (e) {
      toast.error('Something went wrong!')
    }
  };

  handleChange = event => {
    const data = this.state.data;
    data[event.target.id] = event.target.value;
    this.setState({ data });
  };

  handleMemo = event => {
    let child = this.state.child;
    child.memo = event.target.value;
    this.setState({ child });
  };

  goPreviousPage = () => {
    this.props.history.goBack();
  };

  handleToggle = e => {
    let allplans = Array.from(this.state.allplans);
    let id = Number(e.target.name);

    allplans = allplans.map( p => {
      if (Number(p.id) === id) {
        return {
          ...p,
          toggled: !p.toggled
        }
      }
      return p
    })

    this.setState({ allplans, plansWasChanged: true });
  };

  handleChangePayment = event => {
    if (!this.state.onPartialChange) this.setState({ onPartialChange: true });

    let invoice = this.state.invoice;
    let nextMonthPayment = this.state.nextMonthPaymentOriginal;
    const paymentAmount = event.target.value;

    const totalToPay = invoice.amount + invoice.fee + invoice.pdb
    if (paymentAmount > totalToPay) {
      invoice.paid = totalToPay
      nextMonthPayment = paymentAmount - totalToPay
    } else {
      invoice.paid = paymentAmount
    }

    this.setState({ invoice, paymentAmount, nextMonthPayment});
  };

  handleChangePartialPaid = event => {
    let invoice = this.state.invoice;
    invoice.paid = Number(event.target.value);
    this.setState({ invoice, onPartialChange: true });
  };

  handleNextMonthPayment = event => {
    this.setState({ nextMonthPayment: event.target.value });
  };

  handleFee = () => {
    let invoice = this.state.invoice;
    if (invoice.fee === 0) {
      invoice.fee = 30;
    } else {
      invoice.fee = 0;
    }
    this.setState({ invoice });
  };

  handelPDB = (event) => {
    this.setState({
      invoice: {
        ...this.state.invoice,
        pdb: event.target.value
      }
    })
  }

  sendInvoice = async () => {
    let child = this.state.child;

    try {
      await API.get(api2ChildEmailInvoice(child.id))
      toast.success("Invoice was emailed successfully")
    } catch (e) {
      toast.error("Something wrong. Please try again.")
    }
  };

  showFutureInvoice = childId => {
    this.viewFutureInvoice(childId);
  };

  closeFutureInvoice = () => {
    this.setState({ showFutureInvoice: false });
    this.props.Auth.pdfFile = "";
  };

  viewFutureInvoice = async (childId) => {
    try {
      const result = await API.get(api2ChildGetFutureInvoice(childId))
      setTimeout(() => {
        window.open(result.data.file)
      }, 100);
    } catch (e) {
      toast.error("Something wrong. Please try again.")
    }
  };

  refreshInvoice = async () => {
    let child = this.state.child;

    try {
      const result = await API.get(api2ChildUpdateInvoice(child.id))
      toast.success("Invoice was updated successfully")
      setTimeout(() => {
        window.open(result.data.file)
      }, 1000);
      this.setState({
        invoice: {
          ...this.state.invoice,
          invoicefile: result.data.invoicefile
        }
      });
    } catch (e) {
      toast.error("Something wrong. Please try again.")
    }
  };

  handleChangePlanGroup = groupId => {
    this.setState({ selectedPlanGroup: groupId})
  }

  render() {
    const actions = [
      <FlatButton key='b1' label="Cancel" primary={true} onClick={this.handleClose} />,
      <FlatButton key='b2' label="OK" primary={true} onClick={this.deleteData} />
    ];

    const modal = this.state.showFutureInvoice ? (
      <AriaModal
        titleText="Future Invoice"
        onExit={this.closeFutureInvoice}
        focusDialog={true}
        escapeExits={false}
        underlayStyle={{ paddingTop: 20 }}
        aria-describedby="describer"
        data-test-id="test-id"
      >
        <div
          style={{
            background: "#fff",
            outline: 0,
            minWidth: 250,
            maxWidth: 700,
            padding: 50,
            borderRadius: 4
          }}
        >
          <FlatButton
            label="Close"
            primary={true}
            onClick={this.closeFutureInvoice}
          />
          <div style={{ padding: 30 }}>
            <Document
              title="Future Invoice"
              author="YevhenL"
              subject="Rendering invoice with react-pdf"
              keywords={["steps", "pdf"]}
              noData=" "
              loading=" "
              file={this.props.Auth.pdfFile}
            >
              <Page pageNumber={1} />
            </Document>
            {this.props.Auth.pdfFile === "" ? (
              <CircularProgress size={60} thickness={7} />
            ) : null}
          </div>
          <FlatButton
            label="Close"
            primary={true}
            onClick={this.closeFutureInvoice}
          />
        </div>
      </AriaModal>
    ) : false;

    return (
      <div className='invoice'>
        <div className='container500'>
          <Paper zDepth={1} style={{ padding: '10px' }}>
            <div className='buttons_top'>
              <RaisedButton
                target='_blank'
                href={api2PublicStorage + this.state.invoice.invoicefile}
                label="Download Invoice"
              />

              <RaisedButton
                label="View Future Invoice"
                style={{ alignSelf: "center" }}
                onClick={() => this.showFutureInvoice(this.state.invoice.child_id)}
              />

              <RaisedButton
                label="Send Invoice"
                onClick={this.sendInvoice}
              />

              <RaisedButton
                label="Create New Invoice"
                onClick={this.refreshInvoice}
              />
            </div>

            <div className='text--center'>
              <span style={{ color: "blue" }}>School: </span>
              <span> {this.state.organisation.name}</span>
            </div>
            <div className='text--center'>
              <span style={{ color: "blue" }}>Name: </span>
              <a href={appRoutes.childEdit(this.state.child.id)}>{this.state.child.firstname + " " + this.state.child.lastname}</a>
            </div>
            <div className='text--center'>
              <span style={{ color: "blue" }}>Date: </span>
              <span> {this.state.invoiceDate}</span>
            </div>
            <div className='text--center'>
              <span style={{ color: "blue" }}>Amount: </span>
              <b> ${this.state.invoice.amount}</b>
            </div>
            <div className='text--center'>
              <span style={{ color: "blue" }}>Late Fee: </span>
              <span> ${this.state.invoice.fee}</span>
            </div>

            {this.props.Auth.edit_finance && <div className='invoice_form'>
              <TextField
                type='number'
                hintText="Past Due Balance"
                floatingLabelText="Past Due Balance"
                fullWidth={true}
                value={this.state.invoice.pdb}
                onChange={this.handelPDB}
              />
              <TextField
                type='number'
                id="paymentAmount"
                hintText="Total paid"
                floatingLabelText="Total paid(will calc)"
                fullWidth={true}
                value={this.state.paymentAmount}
                onChange={this.handleChangePayment}
              />

              <TextField
                type='number'
                id="partialpaid"
                hintText="Paid Amount"
                floatingLabelText="Paid Amount"
                fullWidth={true}
                value={this.state.invoice.paid}
                onChange={this.handleChangePartialPaid}
              />

              <TextField
                type='number'
                id="nextMonthPayment"
                hintText="0"
                floatingLabelText="Next Month Payment"
                fullWidth={true}
                value={this.state.nextMonthPayment}
                onChange={this.handleNextMonthPayment}
              />

              <Toggle
                name="Late Fee"
                label="Late Fee"
                defaultToggled={this.state.invoice.fee > 0}
                onToggle={() => this.handleFee()}
              />
            </div>}
            <br />
            <div>
              <span style={{ fontSize: 17, color: "gray" }}>Entered deposits:</span>
              <br />
              {this.state.depos.map((row, index) => (
                <span key={index}>
                  &nbsp;&nbsp;${row.amount} ({row.admins_by_user_id.name} - {row.description},{" "}
                  {moment(row.updated).format("MM/DD/YYYY")})
                  <br />
                </span>
              ))}
            </div>
            <br />
            <div>
              <span style={{ fontSize: 17, color: "gray" }}>Notes:</span>
              <br />
              <TextField
                hintText="Notes"
                fullWidth
                multiLine
                rows={3}
                value={this.state.child.memo}
                onChange={this.handleMemo}
              />
              <br />
            </div>
          </Paper>
          <br />
          <div style={{
            display: "flex",
            gap: 10,
            alignItems: "center",
            justifyContent: "center",
          }}>
            <RaisedButton
              label="Save"
              primary={true}
              onClick={this.saveData}
            />
            <RaisedButton
              label="Cancel"
              primary={true}
              onClick={this.goPreviousPage}
            />
          </div>
        </div>
        <div>
          <hr />
          <div className="editInvoicesProgramsFilter">
            <div className="container500">
              <div className="programsFilter">
                <h3>Programs</h3>
                <div>
                  <TextField
                    floatingLabelText="Filter by name"
                    fullWidth={true}
                    value={this.state.programFilterValue}
                    onChange={(event) => {this.setState({programFilterValue: event.target.value})}}
                  />
                </div>
              </div>
            </div>
            <RaisedButton
              label="All"
              primary={this.state.selectedPlanGroup === 0}
              onClick={() => {this.handleChangePlanGroup(0)}}
            />
            {this.state.planGroups.map((planGroup) => (
              <RaisedButton
                key={planGroup.id}
                label={planGroup.title}
                primary={this.state.selectedPlanGroup === planGroup.id}
                onClick={() => {this.handleChangePlanGroup(planGroup.id)}}
              />
            ))}
          </div>

          <div className="container500">
            <Table>
              <TableHead style={{ backgroundColor: '#f5f5f5'}}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell style={{ width: 50 }}>Price</TableCell>
                  <TableCell style={{ width: 50 }}>Active</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.allplans
                  .filter(row => row.name.toLowerCase().includes(this.state.programFilterValue))
                  .map((row) => {
                  return this.state.selectedPlanGroup === 0 || row.plan_group_id === this.state.selectedPlanGroup ? (
                    <TableRow hover key={row.id}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell style={{ width: 50 }}>{row.price}</TableCell>
                      <TableCell style={{ width: 50 }}>
                        <Toggle
                          name={row.id.toString()}
                          defaultToggled={row.toggled}
                          onToggle={this.handleToggle.bind(this)}
                        />
                      </TableCell>
                    </TableRow>
                  ) : '';
                })}
              </TableBody>
            </Table>
          </div>
          <div
            style={{
              display: "flex",
              gap: 10,
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 20,
              paddingBottom: 20
            }}
          >
            <RaisedButton
              label="Save"
              primary={true}
              onClick={this.saveData}
            />
            <RaisedButton
              label="Cancel"
              primary={true}
              onClick={this.goPreviousPage}
            />
            <RaisedButton
              label="Delete"
              secondary={true}
              onClick={this.handleOpen}
            />
          </div>
          <Dialog
            actions={actions}
            modal={false}
            contentStyle={{ width: "20%", maxWidth: "none" }}
            open={this.state.opendialog}
            onRequestClose={this.handleClose}
          >
            Delete invoice?
          </Dialog>
          {modal}
        </div>
      </div>
    );
  }
}

export default inject("Auth")(observer(EditInvoice))
