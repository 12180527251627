export const LATE_FEE_AMOUNT = 30

export const DREAM_APP_STS = 3
export const DREAM_APP_MEDIA = 4
export const DREAM_APP_FILES = 5
export const DREAM_APP_MENU = 8

export const DREAM_ROLE_TEACHER = 1
export const DREAM_ROLE_PARENT = 2
export const DREAM_ROLE_ADMIN = 3
export const DREAM_ROLE_SUPERADMIN = 4

export const TEACHER_DOC_MEDICAL_TYPE = 'medical'
export const TEACHER_DOC_DOH_TYPE = 'doh'
