import React, {useEffect, useMemo, useState} from "react";
import Divider from "material-ui/Divider";
import LinearProgress from "material-ui/LinearProgress";
import {divProgressHide, divProgressShow} from "../../css/additional.js";

import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

import {api2Children} from "../../utils/operations.js";
import {inject, observer} from "mobx-react";

import API from '../../utils/api';
import Button from "@mui/material/Button";
import StyledTableRow from "../../components/StyledTableRow";
import TextField from "@mui/material/TextField";
import appRoutes from "../../utils/appRoutes";

const AllChildren = props => {
  const history = props.history;
  const allOrgs = props.Auth.allOrgs;

  const [data, setData] = useState([]);
  const [showProgress, setShowProgress] = useState(false);

  const [childName, setChildName] = useState('');
  const [email, setEmail] = useState('');
  const [parentName, setParentName] = useState('');


  useEffect(() => {
    let orgId = props.Auth.orgId;
    loadData(orgId);
  }, [])

  const loadData = async (orgId) => {
    setShowProgress(true)

    const [
      children,
    ] = await Promise.all([
      API.get(api2Children, {
        params: {
          children_page: true,
          orderBy: 'firstname',
          with: 'parents',
        }
      })
    ]);

    setData(children.data)
    setShowProgress(false)
  };

  const clearFilter = () => {
    setChildName('')
    setEmail('')
    setParentName('')
  };

  const filteredData = useMemo(() => {
    return data.filter(child => {
      let isCorrect = true

      if (childName) {
        let fullName = child.firstname?.toLowerCase() + ' ' + child.lastname?.toLowerCase()
        isCorrect = isCorrect && fullName.includes(childName.toLowerCase())
      }

      if (parentName) {
        let parentNames = child.parents.map(p => p.firstname?.toLowerCase() + ' ' + p.lastname?.toLowerCase()).join(' ')
        isCorrect = isCorrect && parentNames.includes(parentName.toLowerCase())
      }

      if (email) {
        let parentEmails = child.parents.map(p => p.email?.toLowerCase()).join(' ')
        isCorrect = isCorrect && parentEmails.includes(email.toLowerCase())
      }

      return isCorrect
    })
  }, [data, childName, email, parentName])

  return (
    <div className="all_children" style={{ margin: "auto" }}>

      <div className="all_children_filter no-print">

        <TextField
          sx={{ minWidth: '200px'}}
          label='Child Name'
          name='name'
          value={childName}
          onChange={e => setChildName(e.target.value)}
          size="small"
          variant="standard"
        />

        <TextField
          sx={{ minWidth: '200px'}}
          label='Email'
          name='email'
          value={email}
          onChange={e => setEmail(e.target.value)}
          size="small"
          variant="standard"
        />

        <TextField
          sx={{ minWidth: '200px'}}
          label='Parent Name'
          name='parentName'
          value={parentName}
          onChange={e => setParentName(e.target.value)}
          size="small"
          variant="standard"
        />


        <Button variant="outlined" onClick={clearFilter}>Clear Filters</Button>
      </div>


      <Divider style={{marginTop: 10}} />

      <div style={{ overflowX: 'scroll'}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Child</TableCell>
              <TableCell>School</TableCell>
              <TableCell>Parent</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((childItem, index) => {
              const parentFirst = childItem.parents[0]
              const parentSecond = childItem.parents[1]
              return (
                <StyledTableRow key={childItem.id} sx={{ cursor: "pointer" }} onClick={() => history.push(appRoutes.childEdit(childItem.id))}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{childItem.firstname} {childItem.lastname}</TableCell>
                  <TableCell>{allOrgs[childItem.organisation_id]?.name}</TableCell>
                  <TableCell>
                    {parentFirst?.firstname} {parentFirst?.lastname}
                    {parentSecond && <><br /><br />{parentSecond.firstname} {parentSecond.lastname}</>}
                  </TableCell>
                  <TableCell>
                    {parentFirst?.email}
                    {parentSecond && <><br /><br />{parentSecond.email}</>}
                  </TableCell>
                  <TableCell>
                    {parentFirst?.phone}
                    {parentSecond && <><br /><br />{parentSecond.phone}</>}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div
        style={showProgress ? divProgressShow : divProgressHide}
      >
        <LinearProgress mode="indeterminate" />
      </div>
    </div>
  );

}

export default inject("Auth")(observer(AllChildren))
