import axios from "axios";
import { API_KEY, baseUrl } from "./operations";

const axiosInstance = axios.create({
  baseURL: baseUrl,
  responseType: "json",
  headers: {
    "X-DreamFactory-API-Key": API_KEY
  }
});

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["X-DreamFactory-Session-Token"] = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
