import React, { Component } from "react";
import { List, ListItem } from "material-ui/List";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";
import API from '../../utils/api';

import { getCurrentOrg } from "../../utils/operations.js";
import { observer, inject } from "mobx-react";
import {green500, grey900, red500} from "material-ui/styles/colors";

const style = {
  marginLeft: 'calc(50% - 20px)',
  marginTop: 30,
};

class Organisations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    API.get(getCurrentOrg).then(res => {
      this.setState({ data: res.data.resource });
    });
  };

  editData = value => {
    const editUrl = "/editorganisations/" + value;
    this.props.history.push(editUrl);
  };

  newOrganisations = () => {
    const newUrl = "/neworganisations";
    this.props.history.push(newUrl);
  };

  isStripeIntegrated = (organization) => {
    return organization.stripe_publishable_key
      && organization.stripe_secret_key
      && organization.stripe_webhook_secret;
  }

  render() {
    return (
      <div style={{ maxWidth: 400, margin: "auto" }}>
        <List>
          <h3>Schools</h3>
          {this.state.data.map((row, index) => (
            <ListItem
              style={{ border: '1px solid lightgrey', borderRadius: '10px', marginBottom: '10px'}}
              key={row.id}
              onClick={() => {
                this.editData(row.id);
              }}
            >
              <div>
                <p style={{ color: grey900, fontWeight: 'bold'}}>{row.name}</p>
                <p>{row.address}<br />{row.city + ", " + row.st}</p>
                {this.isStripeIntegrated(row) ? <p style={{ color: green500}}>Stripe Configured</p>
                  : <p style={{ color: red500}}>Stripe Not Configured</p>}
              </div>
            </ListItem>
          ))}
        </List>
        <FloatingActionButton
          style={style}
          secondary={true}
          onClick={this.newOrganisations}
        >
          <ContentAdd />
        </FloatingActionButton>
      </div>
    );
  }
}

export default inject("Auth")(observer(Organisations))
