import React, { Component } from "react";
import axios from "axios";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";
import AutoComplete from "material-ui/AutoComplete";
import Divider from "material-ui/Divider";
import FlatButton from "material-ui/FlatButton";
import LinearProgress from "material-ui/LinearProgress";
import { divProgressHide, divProgressShow } from "../../css/additional.js";

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from "@mui/material";

import {
  getCurrentOrg,
  getCurrentInvoice,
  api2OrganizationSendInvitationsToCabinet,
  api2Parents, api2SendUnpaidReminderAll, api2ClassesByOrganizationId
} from "../../utils/operations.js";
import { observer, inject } from "mobx-react";
import withStyles from '@mui/styles/withStyles';

import API from '../../utils/api';
import {toast} from "react-toastify";
import {checkParentHasPaymentRegistration} from "../../utils/misc";
import Button from "@mui/material/Button";

const style = {
  marginLeft: 'calc(50% - 20px)',
  marginTop: 30,
};

const ClickableRow = props => {
  const { userID, history, ...restProps } = props;

  const editData = value => {
    const editUrl = "/editparent/" + value;
    history.push(editUrl);
  };

  return (
    <TableRow {...restProps} onMouseDown={() => editData(userID)} style={{cursor: 'pointer'}}>
      {props.children}
    </TableRow>
  );
};

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(142, 195, 242, 0.4)',
    },
    '&:hover': {
      backgroundColor: '#f5f5f5',
    }
  },
}))(ClickableRow);

const dataSourceConfig = {
  text: "textKey",
  value: "valueKey"
};

class Parents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      name: [],
      invoices: [],
      showInvoice: 0,
      showProgress: true
    };
  }

  componentDidMount() {
    let orgId = this.props.Auth.orgId;
    this.loadData(orgId);
  }

  loadData = orgId => {
    let that = this;

    axios
      .all([
        API.get(getCurrentOrg + orgId),
        API.get(api2ClassesByOrganizationId(orgId)),
        API.get(api2Parents, {
          params: {
            with: 'children',
            append: 'stripe_customer,stripe_payment_methods',
            filter: 'organisation_id=' + orgId
          }
        })
      ])
      .then(
        axios.spread(function(organisations, classes, parents) {
          let data = parents.data.data;
          let org = organisations.data;
          let claSet = classes.data.data;

          let name = [];

          for (let j = 0; j < data.length; j++) {
            data[j].organisation_name = org.name;

            let children = "";
            let gotChildren = data[j].children;
            for (let i = 0; i < gotChildren.length; i++) {
              children += `${gotChildren[i].firstname} ${
                gotChildren[i].lastname
              }, `;
            }
            if (children !== "") children = ` (${children.slice(0, -2)})`;

            name.push({
              textKey: data[j].firstname + " " + data[j].lastname + children,
              valueKey: data[j].id
            });

            for (let i = 0; i < claSet.length; i++) {
              if (data[j].classes_id === claSet[i].id) {
                data[j].classes_name = claSet[i].name;
              }
            }
          }

          that.setState({ name });

          that.setState({ data });
          that.setState({ showProgress: false });
        })
      );
  };

  editData = value => {
    const editUrl = "/editparent/" + value;
    this.props.history.push(editUrl);
  };

  newUser = () => {
    const newUrl = "/newparent";
    this.props.history.push(newUrl);
  };

  autoCompleteParent = value => {
    this.setState({ showInvoice: value.valueKey });
    this.showInvoices();
  };

  editInvoice = id => {
    const editUrl = "/editinvoice/" + id;
    this.props.history.push(editUrl);
  };

  showInvoices = () => {
    let id = this.state.showInvoice;
    if (id > 0) {
      let parent_id = "(parent_id%3D" + id + ")";
      let organisation_id = "(organisation_id%3D" + this.props.Auth.orgId + ")";
      let filter = "?filter=" + parent_id + "and" + organisation_id;

      let invoiceUrl = getCurrentInvoice.slice(0, -1) + filter;

      API
        .get(invoiceUrl)
        .then(res => {
          let data = res.data.resource;

          this.setState({ invoices: data });
        });

      let olddata = this.state.data;
      let newdata = [];
      for (let i = 0; i < olddata.length; i++) {
        if (olddata[i].id === id) {
          newdata.push(olddata[i]);
          break;
        }
      }
      this.setState({ data: newdata });
    }
  };

  clearFilter = () => {
    this.loadData(this.props.Auth.orgId);
    this.setState({ invoices: [] });
  };

  sendInvitations = async () => {
    let orgId = this.props.Auth.orgId;

    if (!window.confirm('Are you sure ? \nAll parents(only unregistered) of active(under tuition) children in this center will receive invitations!')) return;

    try {
      await API.get(api2OrganizationSendInvitationsToCabinet(orgId))
      await this.props.Auth.getAllOrgs()
      toast.success("Invitations was sent successfully")
    } catch (e) {
      toast.error("Something wrong. Please try again.")
    }
  };

  handleSendUnpaidReminder = () => {

    const conf = window.confirm(`Are you sure? Do you want to send unpaid reminder email for all unpaid invoices ?`);
    if (!conf) return;

    API.post(api2SendUnpaidReminderAll).then(() => {
      toast.success("The task was scheduled successfully.")
    }).catch(() => toast.error("Something wrong."));

  }

  render() {
    const edit = this.props.history;
    return (
      <div className="parents" style={{ margin: "auto" }}>


        <div className="parents_actions">
          <div className="parents_filter">
            <AutoComplete
              floatingLabelText="Parent (Firstname Lastname)"
              filter={AutoComplete.caseInsensitiveFilter}
              dataSource={this.state.name}
              dataSourceConfig={dataSourceConfig}
              onNewRequest={value => this.autoCompleteParent(value)}
            />

            <Button variant="contained" onClick={this.showInvoices}>Filter</Button>
            <Button variant="outlined" onClick={this.clearFilter}>Clear Filter</Button>
          </div>

          <div  className="parents_action_buttons">
            {this.props.Auth.role === "superadmin" && !this.props.Auth.isInvitationsSentForCurrentOrg ? (
              <Button
                variant="contained"
                onClick={this.sendInvitations}
              >Send Payment Invitations</Button>
            ) : null}

            {this.props.Auth.role === "superadmin" ? ( <Button
              variant="contained"
              onClick={() => this.handleSendUnpaidReminder()}
            >Unpaid reminder</Button>
            ) : null}
          </div>
        </div>



        <Divider style={{marginTop: 10}} />
        <div>
          {this.state.invoices.map(row => (
            <FlatButton
              key={row.id}
              label={row.gendate.toString().slice(0, 10)}
              style={{ marginLeft: 10 }}
              primary={true}
              onClick={() => this.editInvoice(row.id)}
            />
          ))}
        </div>
        <Divider />
        <div style={{ overflowX: 'scroll'}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>School</TableCell>
                <TableCell>Payments Status / Payment Methods</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.data.map((row, index) => {
                return (
                  <StyledTableRow key={row.id} userID={row.id} history={edit}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.firstname}</TableCell>
                    <TableCell>{row.lastname}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.organisation_name}</TableCell>
                    <TableCell>
                      <div>{checkParentHasPaymentRegistration(row) ? 'Registered' : 'Unregistered'}</div>
                      {row.stripe_payment_methods && row.stripe_payment_methods.map(method => {
                      return <div key={method.id}>{method.name}{method.is_auto_pay === 1 && ' - Autopay'}</div>
                    })}</TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <div
          style={this.state.showProgress ? divProgressShow : divProgressHide}
        >
          <LinearProgress mode="indeterminate" />
        </div>
        <FloatingActionButton
          style={style}
          secondary={true}
          onClick={this.newUser}
        >
          <ContentAdd />
        </FloatingActionButton>
      </div>
    );
  }
}

export default inject("Auth")(observer(Parents))
