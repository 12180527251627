import React, { Component } from "react";
import axios from "axios";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import moment from "moment";
import DropDownMenu from "material-ui/DropDownMenu";
import MenuItem from "material-ui/MenuItem";
import { green500, blue500, red500 } from "material-ui/styles/colors";
import DatePicker from "material-ui/DatePicker";

import { observer, inject } from "mobx-react";

import SelectField from "material-ui/SelectField";

import API from '../../utils/api';
import {
  api2AllPlans,
  api2StatBilled, api2statCreditPlans,
  api2StatEnrolled, api2statIncomePlans,
  api2StatPlans, api2StatPlansCredit
} from '../../utils/operations';
import { moneyFormatter } from '../../utils/misc';

class FinacialCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgId: props.Auth.orgId,
      fromDate: moment().subtract(1,'year').month(7).date(1).toDate(),
      toDate: moment().month(7).toDate(),
      statBilled: [],
      statEnrolled: [],
      statPlans: [],
      statPlansCredit: [],
      statPlansIncome: [],

      showProgress: true,
      chartNumber: 10,
      reBar: [],
      amountPaid: [],
      organisations: [],
      invoices: [],
      children: [],
      chart: "",
      pieColors: [
        "#223d78",
        "#04509d",
        "#2068b2",
        "#317fc2",
        "#4b9dd5",
        "#64b5e4",
        "#83c8ee",
        "#a3dcf6",
        "#c6eaf9",
      ],
      pieColorsOld: [
        "#0088FE",
        "#00C49F",
        "#FFBB28",
        "#5E35B1",
        "#6D4C41",
        "#546E7A",
        "#FF8042"
      ],
      archiveData: [],
      creditsPlans: [],
      incomePlans: [],
      programIncomePlansFilter: [],
      api2AllPlans: [],
      creditsSelectedPrograms: [],
      incomeSelectedPrograms: [],
      selectedPrograms: [],
      firstMonth: "September",
      firstYear: 2017,
      secondMonth: "September",
      secondYear: 2019,
      filterArchive: false
    };
  }

  planColors = [
    "#223d78", "#04509d", "#2068b2", "#317fc2",
    "#4b9dd5", "#64b5e4", "#83c8ee", "#a3dcf6", "#c6eaf9",
    '#FF6633', '#FFB399', '#FF33FF', '#00B3E6',
    '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

  componentDidMount() {
    if (!this.props.Auth.can('dashboard')) return this.props.history.push("/children");
    this.loadData()
  }

  loadData = () => {
    let that = this;

    Promise.all([
      this.loadBilledData(),
      this.loadEnrolledData(),
      this.loadPlanData(),
      this.loadPlanCreditData(),
      this.loadPlanIncomeData(),
    ]).then()

    axios
      .all([
        API.get(api2statCreditPlans),
        API.get(api2statIncomePlans),
        API.get(api2AllPlans, { params: {in_graphs: true}}),
      ]).then(
        axios.spread(function(
          creditsPlans,
          incomePlans,
          api2AllPlans,
        ) {
          that.setState({
            creditsPlans: creditsPlans.data,
            incomePlans: incomePlans.data,
            api2AllPlans: api2AllPlans.data,
            showProgress: false,
          });
        })
      );
  };

  loadPlanCreditData = async () => {
    const response = await API.post(api2StatPlansCredit, {
      planIds: this.state.creditsSelectedPrograms,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      orgId: this.state.orgId,
    })
    this.setState({statPlansCredit: response.data})
  }

  loadPlanIncomeData = async () => {
    const response = await API.post(api2StatPlansCredit, {
      planIds: this.state.incomeSelectedPrograms,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      orgId: this.state.orgId,
    })
    this.setState({statPlansIncome: response.data})
  }

  loadPlanData = async () => {
    const response = await API.post(api2StatPlans, {
      planIds: this.state.selectedPrograms,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      orgId: this.state.orgId,
    })
    this.setState({statPlans: response.data})
  }

  loadBilledData = async () => {
    const response = await API.post(api2StatBilled, {
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        orgId: this.state.orgId,
    })
    this.setState({statBilled: response.data})
  }

  loadEnrolledData = async () => {
    const response = await API.post(api2StatEnrolled, {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      orgId: this.state.orgId,
    })
    this.setState({statEnrolled: response.data})
  }

  showBilledAmount = () => {
    return (
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={this.state.statBilled}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <XAxis dataKey="s" />
          <YAxis dataKey={(v) => parseInt(v.billed)}  />
          <CartesianGrid strokeDasharray="4 4" />
          <Tooltip formatter={(value) => { return moneyFormatter(value) }} />
          <Legend />
          <Bar name="Billed" dataKey="billed" fill={blue500} />
          <Bar name="Collected" dataKey="paid" fill={green500} />
        </BarChart>
      </ResponsiveContainer>
    );
  };


  showBilledPercent = () => {
    return (
      <BarChart
        width={800}
        height={400}
        data={this.state.statBilled}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis dataKey="s" />
        <YAxis  />
        <CartesianGrid strokeDasharray="4 4" />
        <Tooltip />
        <Legend />
        <Bar unit="%" name="Collected" dataKey={(v) => Math.round(v.paid / v.billed * 100)} fill={blue500} />
        <Bar unit="%" name="Outstanding" dataKey={(v) => Math.round(v.paid / v.billed * 100 - 100)} fill={red500} />
      </BarChart>
    );
  };

  showChildrenEnrollment = () => {
    return (
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={this.state.statEnrolled}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <XAxis dataKey="s" />
          <YAxis />
          <CartesianGrid strokeDasharray="4 4" />
          <Tooltip />
          <Legend />
          <Bar name="Children quantity" maxBarSize={40} dataKey="qty" fill={blue500} />
        </BarChart>
      </ResponsiveContainer>
    );
  }

  showChildrenPrograms = () => {
    const barTooltip = (value, name, props) => {
      const item = Object.values(props.payload.data).find(row => row.name === name)
      return item ? `${item.qty} kids, ` + moneyFormatter(value*item.price) : `${value} kids`;
    }


    return (
      <div>
        {this.state.statPlans.length > 0 && <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={this.state.statPlans}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="period" />
            <YAxis />
            <CartesianGrid strokeDasharray="4 4" />
            <Tooltip formatter={barTooltip} />
            <Legend />
            {Object.keys(this.state.statPlans[0].data).map((programId, index) => {
              return <Bar key={index} name={this.state.statPlans[0].data[programId].name} maxBarSize={40} dataKey={(v) => v.data[programId].qty} fill={this.planColors[index]} />
            })}
          </BarChart>
        </ResponsiveContainer>}
      </div>
    );
  }

  showChildrenProgramsCredits = () => {
    const barTooltip = (value, name, props) => {
      const item = Object.values(props.payload.data).find(row => row.name === name)
      return item ? `${item.children_qty} kids, ` + moneyFormatter(value) : moneyFormatter(value);
    }

    return (
      <div>
        {this.state.statPlansCredit.length > 0 && <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={this.state.statPlansCredit}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="period" />
            <YAxis />
            <CartesianGrid strokeDasharray="4 4" />
            <Tooltip formatter={barTooltip} />
            <Legend />
            {Object.keys(this.state.statPlansCredit[0].data).map((programId, index) => {
              return <Bar key={index} name={this.state.statPlansCredit[0].data[programId].name} maxBarSize={40} dataKey={(v) => v.data[programId].credits} fill={this.planColors[index]} />
            })}
          </BarChart>
        </ResponsiveContainer>}
      </div>
    );
  }

  showChildrenProgramsIncome = () => {
    const barTooltip = (value, name, props) => {
      const item = Object.values(props.payload.data).find(row => row.name === name)
      return item ? `${item.children_qty} kids, ` + moneyFormatter(value) : moneyFormatter(value);
    }

    return (
      <div>
        {this.state.statPlansIncome.length > 0 && <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={this.state.statPlansIncome}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="period" />
            <YAxis />
            <CartesianGrid strokeDasharray="4 4" />
            <Tooltip formatter={barTooltip} />
            <Legend />
            {Object.keys(this.state.statPlansIncome[0].data).map((programId, index) => {
              return <Bar key={index} name={this.state.statPlansIncome[0].data[programId].name} maxBarSize={40} dataKey={(v) => v.data[programId].credits} fill={this.planColors[index]} />
            })}
          </BarChart>
        </ResponsiveContainer>}
      </div>
    );
  }

  handleChangeFromDate = (event, date) => {
    this.setState({fromDate: date});
    setTimeout(() => this.loadData(), 500)
  }

  handleChangeToDate = (event, date) => {
    this.setState({toDate: date});
    setTimeout(() => this.loadData(), 500)
  }

  handleChange = (event, index, value) => {
    this.setState({ chartNumber: value, filterArchive: false });
  };

  handleChangeCreditsPlans = (event, index, value) => {
    this.setState({creditsSelectedPrograms: value})
    setTimeout(() => this.loadPlanCreditData(), 500)
  };

  handleChangeIncomePlans = (event, index, value) => {
    this.setState({incomeSelectedPrograms: value})
    setTimeout(() => this.loadPlanIncomeData(), 500)
  };

  handleChangePlans = (event, index, value) => {
    this.setState({selectedPrograms: value})
    setTimeout(() => this.loadPlanData(), 500)
  };

  render() {
    return (
      <div style={{ margin: "auto" }}>
        <br />
        <DropDownMenu
          value={this.state.chartNumber}
          onChange={this.handleChange}
        >
          <MenuItem value={10} primaryText="Billed/Paid Amount" />
          <MenuItem value={12} primaryText="Children Enrollment" />
          <MenuItem value={13} primaryText="Programs by Number Of Children" />
          <MenuItem value={14} primaryText="Programs Credits" />
          <MenuItem value={15} primaryText="Programs Income" />
        </DropDownMenu>
        <div className="dashboardFilters">
          <DatePicker
            onChange={this.handleChangeFromDate}
            floatingLabelText="From Date"
            defaultDate={this.state.fromDate}
            style={{ marginLeft: '20px' }}
            locale="en-US"
            firstDayOfWeek={0}
          />
          <DatePicker
            onChange={this.handleChangeToDate}
            floatingLabelText="To Date"
            defaultDate={this.state.toDate}
            style={{ marginLeft: '20px' }}
            locale="en-US"
            firstDayOfWeek={0}
          />
          {(this.state.chartNumber === 13) &&
            <SelectField
              floatingLabelText="Programs"
              style={{ marginLeft: '40px' }}
              multiple={true}
              value={this.state.selectedPrograms}
              onChange={this.handleChangePlans}
              fullWidth={true}
            >
              {this.state.api2AllPlans.map((plan) => {
                return <MenuItem key={plan.id} value={plan.id} primaryText={plan.name} />
              })}
            </SelectField>
          }
          {(this.state.chartNumber === 14) &&
          <SelectField
            floatingLabelText="Credit Programs"
            style={{ marginLeft: '40px' }}
            multiple={true}
            value={this.state.creditsSelectedPrograms}
            onChange={this.handleChangeCreditsPlans}
            fullWidth={true}
          >
            {this.state.creditsPlans.map((plan) => {
              return <MenuItem key={plan.id} value={plan.id} primaryText={plan.name} />
            })}
          </SelectField>
          }

          {(this.state.chartNumber === 15) &&
          <SelectField
            floatingLabelText="Income Programs"
            style={{ marginLeft: '40px' }}
            multiple={true}
            value={this.state.incomeSelectedPrograms}
            onChange={this.handleChangeIncomePlans}
            fullWidth={true}
          >
            {this.state.incomePlans.map((plan) => {
              return <MenuItem key={plan.id} value={plan.id} primaryText={plan.name} />
            })}
          </SelectField>
          }
        </div>
        <br />
        <br />
        <br />
        <div style={{ overflowX: "scroll"}}>
          {this.state.chartNumber === 10 && this.showBilledAmount()}
          {/*{this.state.chartNumber === 11 && this.showBilledPercent()}*/}
          {this.state.chartNumber === 12 && this.showChildrenEnrollment()}
          {this.state.chartNumber === 13 && this.showChildrenPrograms()}
          {this.state.chartNumber === 14 && this.showChildrenProgramsCredits()}
          {this.state.chartNumber === 15 && this.showChildrenProgramsIncome()}
        </div>
        <br />
      </div>
    );
  }
}

export default inject("Auth")(observer(FinacialCharts))
