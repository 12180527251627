import React, {useEffect, useMemo, useState} from "react";

import { observer, inject } from "mobx-react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import API from "../../utils/api";
import {
  api2Attendance,
} from "../../utils/operations";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from "@mui/material";
import { dateFormatter} from "../../utils/misc";
import StyledTableRow from "../../components/StyledTableRow";

import Loader from "../../components/Loader";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {AttendanceClassesTabs} from "../../components/AttendanceClassesTabs";
import {toast} from "react-toastify";
import {AttendanceSwitch} from "../../components/AttendanceSwitch";
import html2pdf from "html2pdf.js";


const defaultFilter = {
  name: '',
  sick: false,
  vacation: false,
}

const getAttendanceStatusTitle = (attendance) => {
  switch (attendance) {
    case 'present':
      return 'Present'

    case 'sick':
      return 'Sick'

    case 'vacation':
      return 'Vacation'

    case 'absentee':
      return 'Absentee Month'
  }
}

const Attendance = (props) => {
  const orgId = props.Auth.orgId
  const [refreshId, setRefreshId] = useState(1)
  const [loading, setLoading] = useState(false)

  const [classesIdsWithSavedAttendance, setClassesIdsWithSavedAttendance] = useState([])
  const [selectedClass, setSelectedClass] = useState('')
  const [classes, setClasses] = useState([])
  const [data, setData] = useState([])
  const [editedDataOriginal, setEditedDataOriginal] = useState([])
  const [editedData, setEditedData] = useState([])

  const [filter, setFilter] = useState(defaultFilter);

  const handleChange = (event) => {
    const name = event.target.name;
    setFilter({ ...filter, [name]: event.target.value });
  };

  const handleChangeCheckbox = (event) => {
    const name = event.target.name;
    setFilter({ ...filter, [name]: event.target.checked });
  };

  useEffect(() => {
    loadData().then()
  }, [])

  useEffect(() => {
    const newData = data.filter(item => item.classes_id === selectedClass)
    setEditedData(JSON.parse(JSON.stringify(newData)))
    setEditedDataOriginal(JSON.parse(JSON.stringify(newData)))
  }, [selectedClass, data])

  const loadData = async () => {
    setLoading(true)

    try {
      const response = await API.get(api2Attendance(orgId))
      setClasses(response.data.data.classes)
      setSelectedClass(response.data.data.selectedClass)
      setData(response.data.data.attendance)
      setClassesIdsWithSavedAttendance(response.data.data.classesIdsWithSavedAttendance)
    } catch (e) {
      toast.error('Error on data loading!');
    } finally {
      setLoading(false)
    }

  };

  const selectedClassTitle = useMemo(() => {
    const foundedClass = classes.find(c => c.id === selectedClass)
    return foundedClass ? foundedClass.name : ""
  }, [selectedClass, classes])

  const filteredData = useMemo(() => {
    let fData = [...editedData]

    if (filter.name.length > 0) {
      const searchString = filter.name.toLowerCase()
      fData = fData.filter(item => { return (item.child.firstname + ' ' + item.child.lastname).toLowerCase().includes(searchString)})
    }

    if (filter.sick || filter.vacation) {
      let searchAttendance = []
      if (filter.sick) { searchAttendance.push('sick') }
      if (filter.vacation) { searchAttendance.push('vacation') }

      fData = fData.filter(item => {
        return searchAttendance.includes(item.attendance)
      })
    }

    fData = fData.sort((a, b) => {
      return a.child.lastname.localeCompare(b.child.lastname)
    })

    return fData

  }, [editedData, filter])

  const handleFormSubmit = (event) => {
    event.preventDefault()
  };

  const handleClearFilters = () => {
    setFilter(defaultFilter)
    setRefreshId(refreshId + 1)
  };

  const handleChangeAttendance = (childId, event) => {
    const isChecked = event.target.checked
    setEditedData(editedData.map(item => {
      if (item.child_id === childId) {
        if (isChecked) {
          item.attendance = 'present'
        } else {
          item.attendance = 'sick'
        }
      }
      return item
    }))
  }

  const handleChangeAbsenteeMonth = (childId, event) => {
    const isChecked = event.target.checked
    setEditedData(editedData.map(item => {
      if (item.child_id === childId) {
        item.attendance = isChecked ? 'absentee' : 'present'
        item.child.absentee_month = isChecked
      }
      return item
    }))
  }

  const handleChangeAbsentType = (childId, event) => {
    setEditedData(editedData.map(item => {
      if (item.child_id === childId) {
        item.attendance = event.target.value
      }
      return item
    }))
  }

  const notSaved = useMemo(() => {
    return JSON.stringify(editedDataOriginal) !== JSON.stringify(editedData)
  }, [editedDataOriginal, editedData])

  const handleChangeClass = (classId) => {
    if (notSaved) {
      toast.error('Please, save data!');
    } else {
      setSelectedClass(classId)
    }
  }

  const handleSaveClick = async () => {
    try {
      const dataToSend = filteredData.map(item => ({
        id: item.id,
        attendance: item.attendance,
        child_id: item.child_id,
        absentee_month: item.child.absentee_month
      }))
      const response = await API.patch(api2Attendance(orgId), dataToSend)
      setData(response.data.data.attendance)
      setClassesIdsWithSavedAttendance(response.data.data.classesIdsWithSavedAttendance)
      toast.success('Data saved successfully!');
    } catch (e) {
      toast.error('Error on data save!');
    }

  }

  const printTrackerReport = () => {
    let dateString = '';

    let dataTable = '';
    dataTable += '<table style="width: 100%; border: 1px solid grey; border-collapse: collapse;">';
    dataTable +=
      "<tr>" +
      "<th style='text-align: left; border: 1px solid grey;'>#</th>" +
      "<th style='text-align: left; border: 1px solid grey;'>Child Name</th>" +
      "<th style='text-align: center; border: 1px solid grey;'>Class</th>" +
      "<th style='text-align: center; border: 1px solid grey;'>Attendance date</th>" +
      "<th style='text-align: center; border: 1px solid grey;'>Attendance</th>" +
      "<th style='text-align: center; border: 1px solid grey;'>Sick/Vacation</th>" +
      "</tr>";

    filteredData.map((row, index) => {
      const isPresent = row.attendance === 'present'
      dateString = dateFormatter(row.date)

      dataTable += '<tr>';
      dataTable += `<td style='text-align: left; border: 1px solid grey;'>${index + 1}</td>`;
      dataTable += `<td style='text-align: left; border: 1px solid grey;'>${row.child.lastname} ${row.child.firstname}</td>`;
      dataTable += `<td style='text-align: center; border: 1px solid grey;'>${selectedClassTitle}</td>`;
      dataTable += `<td style='text-align: center; border: 1px solid grey;'>${dateFormatter(row.date)}</td>`;
      dataTable += `<td style='text-align: center; border: 1px solid grey;'>${isPresent ? 'PRESENT' : ''}</td>`;
      dataTable += `<td style='text-align: center; border: 1px solid grey;'>${!isPresent ? getAttendanceStatusTitle(row.attendance) : ''}</td>`;
      dataTable += "</tr>";
    });

    dataTable += "</table>";

    const title = `<h2 style='text-align: center;'>Attendance Report <br /> ${selectedClassTitle} for ${dateString}</h2>`;

    const htmlContent = title + dataTable

    html2pdf(htmlContent, {
      margin: 0.5,
      filename: "attendance-" + selectedClassTitle + "-" + dateString + ".pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" }
    });
  };

  return (
      <div className="attendance">

        <div className="tabsMenu">
          <Tabs value="attendance"
                indicatorColor="primary"
                textColor="primary"
          >
            <Tab value="attendance" label="Current Day Attendance" />
            <Tab value="attendance-archive" label="Archive" onClick={() => props.history.push('/attendance/archive')} />
          </Tabs>
        </div>

        <AttendanceClassesTabs classes={classes} selectedClass={selectedClass} savedClasses={classesIdsWithSavedAttendance} onChange={handleChangeClass} />

        <form onSubmit={handleFormSubmit}>
          <div key={refreshId} className="filters">
            <TextField
              label='Child Name'
              name='name'
              value={filter.name}
              onChange={handleChange}
              size="small"
              variant="standard"
            />
            <FormControlLabel control={<Checkbox name='sick' value={filter.sick} onChange={handleChangeCheckbox} />} label="Sick" />
            <FormControlLabel control={<Checkbox name='vacation' value={filter.vacation} onChange={handleChangeCheckbox} />} label="Vacation" />

            <Button variant="outlined" onClick={handleClearFilters}>Clear</Button>
            <Button variant="outlined" onClick={printTrackerReport}>Print Tracker Report</Button>
          </div>
        </form>

        <div>
          <Loader loaded={!loading}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={20}>#</TableCell>
                  <TableCell width={300}>Child Name</TableCell>
                  <TableCell width={150}>Class</TableCell>
                  <TableCell width={150}>Attendance date</TableCell>
                  <TableCell width={150} align="center">Attendance</TableCell>
                  <TableCell width={150} align="center">Sick/Vacation</TableCell>
                  <TableCell width={150} align="center">Absentee Month</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((row, index) => {
                  const absenteeMonthChecked = row.child.absentee_month
                  const attendanceChecked = row.attendance === 'present' && !absenteeMonthChecked

                  return (
                    <StyledTableRow key={row.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.child.lastname} {row.child.firstname}</TableCell>
                      <TableCell>{selectedClassTitle}</TableCell>
                      <TableCell>{dateFormatter(row.date)}</TableCell>
                      <TableCell align="center">
                        {!absenteeMonthChecked && <AttendanceSwitch
                          attendance={row.attendance}
                          color="success"
                          checked={attendanceChecked}
                          onChange={(event) => handleChangeAttendance(row.child_id, event)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />}
                      </TableCell>
                      <TableCell align="center">
                        {!attendanceChecked && !absenteeMonthChecked && <FormControl>
                          <Select
                            value={row.attendance}
                            onChange={(event) => handleChangeAbsentType(row.child_id, event)}
                            size='small'
                            variant='standard'
                          >
                            <MenuItem value='sick'>Sick</MenuItem>
                            <MenuItem value='vacation'>Vacation</MenuItem>
                            <MenuItem value='absentee'>Absentee Month</MenuItem>
                          </Select>
                        </FormControl>}
                      </TableCell>
                      <TableCell width={150} align="center">
                        <Switch
                          checked={absenteeMonthChecked}
                          onChange={(event) => handleChangeAbsenteeMonth(row.child_id, event)}
                          color="warning" />
                      </TableCell>
                      <TableCell></TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>

            <div className='actionsContainer'>
              <Button variant="contained" primary onClick={handleSaveClick}>Save</Button>
            </div>

          </Loader>
        </div>
      </div>
  );
}

export default inject("Auth")(observer(Attendance))
