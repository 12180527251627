import React, {useState, useRef, useEffect} from 'react';
import {inject, observer} from "mobx-react";
import { TextField, Button, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import {modalStyle} from "../../utils/misc";
import appRoutes from "../../utils/appRoutes";
import Api from "../../utils/api";
import {
	api2TeacherDocs,
	api2TeacherDocsAttachment,
	api2TeacherDocsAttachmentDelete,
	api2TeacherDoc
} from "../../utils/operations";
import {toast} from "react-toastify";
import {TEACHER_DOC_DOH_TYPE} from "../../utils/constants";

const MedicalDoc = (props) => {
	const teacherId = props.match.params.id;
	const type = props.match.params.type;
	const docId = props.match.params.docId;

	let pageTitle = 'Medical Record'

	if (type == TEACHER_DOC_DOH_TYPE) {
		pageTitle = 'DOH paperwork'
	}

	const uploadRef = useRef(null);

	const [title, setTitle] = useState('');
	const [startDate, setStartDate] = useState('');
	const [expirationDate, setExpirationDate] = useState('');

	const [attachments, setAttachments] = useState([]);

	const [attachmentTitle, setAttachmentTitle] = useState('');
	const [selectedFile, setSelectedFile] = useState(null);


	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		if (docId) {
			loadData()
		}
	}, [])

	const loadData = async () => {
		try {
			const result = await Api.get(api2TeacherDoc(teacherId, docId))
			const doc = result.data.data

			setTitle(doc.title)
			setStartDate(doc.start_date)
			setExpirationDate(doc.expiration_date)
			setAttachments(doc.attachments)
		} catch (e) {
			toast.error('Something wrong! Can\'t load doc.' )
		}
	}

	const saveData = async () => {
		try {
			let docData = {
				type: type,
				docId: docId ?? '',
				title,
				startDate,
				expirationDate,
				attachments
			}

			const result = await Api.post(api2TeacherDocs(teacherId), docData)
			const newDocId = result.data.id

			if (!docId) {
				props.history.push(appRoutes.teacherMedicalDocEdit(teacherId, newDocId, type))
			} else {
				props.history.push(appRoutes.teacherEdit(teacherId))
			}

			toast.success('Doc was saved!')
		} catch (e) {
			toast.error('Something wrong! Can\'t save doc.' )
		}
	}

	const handleSubmitAttachment = async (event) => {
		event.preventDefault()

		try {
			let attachmentData = new FormData();
			attachmentData.append(`docId`, docId);
			attachmentData.append(`title`, attachmentTitle);
			attachmentData.append(`doc`, selectedFile);

			await Api.post(api2TeacherDocsAttachment(teacherId), attachmentData, {
				headers: {
					"Content-Type": "multipart/form-data",
				}
			})

			toast.success('Attachment was added!')

			loadData()
		} catch (e) {
			toast.error('Something wrong! Can\'t save doc.' )
		}
		handleCloseModal()
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		saveData();
	};

	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const handleDeleteDoc = async () => {
		if (!confirm('Are you sure you would like to delete this document ?')) {
			return;
		}

		try {
			await Api.delete(api2TeacherDoc(teacherId, docId))

			toast.success('Document was deleted!')

			goPreviousPage()
		} catch (e) {
			toast.error('Something wrong! Can\'t delete attachment.' )
		}
	}

	const handleDeleteAttachment = async (attachmentId) => {
		if (!confirm('Are you sure you would like to delete attachment ?')) {
			return;
		}

		try {
			await Api.delete(api2TeacherDocsAttachmentDelete(teacherId, attachmentId), {
				attachmentId: attachmentId,
			})

			toast.success('Attachment was deleted!')

			loadData()
		} catch (e) {
			toast.error('Something wrong! Can\'t delete attachment.' )
		}
	};

	const handleCloseModal = () => {
		setShowModal(false)
		setSelectedFile(null)
		setAttachmentTitle('')
	};

	const goPreviousPage = () => {
		props.history.goBack(appRoutes.teacherEdit(teacherId));
	};

	return (
		<div className='medical' style={{ width: 500, margin: "auto" , paddingBottom: '40px' }}>
			<h2>{pageTitle}</h2>
			<Box
				component="form"
				onSubmit={handleSubmit}
				sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
			>
				<TextField
					size="small"
					variant="standard"
					label="Title"
					value={title}
					onChange={(e) => setTitle(e.target.value)}
					required
				/>

				<TextField
					size="small"
					variant="standard"
					label="Expiration Date"
					type="date"
					value={expirationDate}
					onChange={(e) => setExpirationDate(e.target.value)}
					InputLabelProps={{ shrink: true }}
					required
				/>

				<div className='attachmentsListContainer'>
					{attachments.map((a, index) => {
						return <div key={'attachment-' + index} className='attachmentItem'>
							<div>
								<a href={a.url} target='_blank'>{a.title}</a>
							</div>
							<img
								onClick={() => handleDeleteAttachment(a.id)}
								className='attachmentDelete' src="/images/circle-cross.svg" alt='delete' />
						</div>
					})}
				</div>

				{docId && <div className='addAttachmentBtn' onClick={() => setShowModal(true)}>
					<Fab color="secondary" size='small'><AddIcon/></Fab>
					<div>Add attachments</div>
				</div>}

				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: 2,
						paddingTop: 2,
						paddingBottom: 2
					}}
				>
					<Button type="submit" variant="contained" color="primary" >Save</Button>
					<Button
						type='button'
						variant="outlined"
						color="info"
						onClick={goPreviousPage}
					>Cancel</Button>
					{docId && <Button
						variant="contained"
						color="secondary"
						onClick={handleDeleteDoc}
					>Delete</Button>}
				</Box>
			</Box>

			<Modal
				open={showModal}
				onClose={() => setShowModal(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={modalStyle} className='modalBox'>
					<Box
						sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: '20px 0' }}
						component="form"
						onSubmit={handleSubmitAttachment}
					>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							Add new attachment
						</Typography>

						<TextField
							fullWidth
							size="small"
							variant="standard"
							label="Attachment Title"
							value={attachmentTitle}
							onChange={(e) => setAttachmentTitle(e.target.value)}
							required
						/>

						<div id="modal-modal-description">
							<label htmlFor='add-attachment' className='addAttachmentBtn' >
								<Fab color="secondary" size='small' onClick={() => uploadRef.current.click()}><AddIcon /></Fab>
								<div>Upload (PDF only)</div>
							</label>
							<input
								ref={uploadRef}
								style={{ display: 'none'}} id='add-attachment' type='file'
								onChange={handleFileChange}
							/>
						</div>

						<hr className="modalButtonsDivider" />

						<div className="modalButtons">

							<Button type="submit" variant="contained" color="primary" >Save</Button>
							<Button
								variant="outlined"
								color="info"
								onClick={() => handleCloseModal()}
							>Cancel</Button>
						</div>

					</Box>


				</Box>
			</Modal>
		</div>
	)
}

export default inject("Auth")(observer(MedicalDoc))
