import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import moment from "moment";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';

import TextField from "material-ui/TextField";
import Checkbox from "material-ui/Checkbox";
import DatePicker from "material-ui/DatePicker";
import { grey400 } from "material-ui/styles/colors";

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from "@mui/material";
import SwapVert from '@mui/icons-material/SwapVert';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import StyledTableRow from "../../components/StyledTableRow";

import API from '../../utils/api';
import {api2Organizations, api2PlanBulkAssign, getAllPlans, programToSchool} from "../../utils/operations.js";
import { api2PlanGroups, api2PlanById, api2PlansReorder, api2AllPlans } from '../../utils/operations';
import { moneyFormatter } from '../../utils/misc';

class Programs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      programFilterValue: "",
      data: [],
      name: "",
      price: "",
      type: 1,
      show_plan: 0,
      in_filter: 0,
      suspend: 0,
      suspend_start: null,
      suspend_end: null,
      sort_score: 0,
      editPlanId: "",
      id: null,
      showErrorDialog: false,
      showProgramBulkDialog: false,
      programBulkForceUpdateInvoices: true,
      programBulkSelectedClass: [],
      texterrors: "",
      plans: [],
      planGroups: [],
      organizations: [],
      base: {
        discountChildren: { price: 0 },
        vacationChildren: { price: 0 }
      },
      programIsMonthly: true,
      programtoschool_by_plan_id: [],
      noProgramToSchool: true
    };
  }

  setEditProgramToSchool = (programsToSchool, planId) => {
    const allOrgs = Object.values(this.props.Auth.allOrgs);
    allOrgs.forEach(org => {
      let programToSchool = programsToSchool.find(p => p.school_id === org.id)
      if (!programToSchool) {
        programsToSchool.push({
          school_id: org.id,
          plan_id: planId,
          show_in_invoice: 0
        });
      }
    })
    this.setState({ programtoschool_by_plan_id: programsToSchool });
  }

  setNewProgramToSchool = () => {
    let programsToSchool = [];
    const allOrgs = Object.values(this.props.Auth.allOrgs);
    allOrgs.forEach(org => {
      programsToSchool.push({
        school_id: org.id,
        plan_id: 0,
        show_in_invoice: 1
      });
    })
    this.setState({ programtoschool_by_plan_id: programsToSchool });
  };

  handleOpenErrorDialog = () => this.setState({ showErrorDialog: true })

  handleCloseErrorDialog = () => this.setState({ showErrorDialog: false, texterrors: "" })

  handleOpenProgramBulkDialog = () => this.setState({ showProgramBulkDialog: true })

  handleCloseProgramBulkDialog = () => {
    this.setState({
      showProgramBulkDialog: false,
      programBulkForceUpdateInvoices: true,
      programBulkSelectedClass: [],
    })
  }

  componentDidMount() {
    if (!this.props.Auth.can('programs')) return this.props.history.push("/children");
    this.loadData();
  }

  loadData = async () => {
    const [
      allOrganizations,
      allPlanGroups,
      allPlans,
    ] = await Promise.all([
      API.get(api2Organizations),
      API.get(api2PlanGroups),
      API.get(api2AllPlans, {
        params: {
          withCountByOrganisation: 'childplans',
          with: 'programtoschool_by_plan_id',
          orderBy: 'sort_score',
          organisationId: this.props.Auth.orgId,
        }
      })
    ]);

    this.setState({
      organizations: allOrganizations.data,
      planGroups: allPlanGroups.data,
      plans: allPlans.data
    });
  }

  updateRow = async item => {
    const base_url = getAllPlans + "/" + item.id;
    let data = {
      name: item.name,
      price: Number(item.price),
      type: Number(item.type),
      show_plan: Number(item.show_plan),
      in_filter: Number(item.in_filter),
      suspend: Number(item.suspend),
      suspend_start: item.suspend_start,
      suspend_end: item.suspend_end,
      sort_score: item.sort_score
    };

    await API.patch(base_url, data);
    await this.insertProgramToSchool(item.id);
  };

  insertRow = async item => {
    let data = {
      resource: [
        {
          name: item.name,
          price: Number(item.price),
          type: Number(item.type),
          show_plan: Number(item.show_plan),
          in_filter: Number(item.in_filter),
          suspend: Number(item.suspend),
          suspend_start: item.suspend_start,
          suspend_end: item.suspend_end,
          sort_score: Number(item.sort_score)
        }
      ]
    };

    let res = await API.post(getAllPlans, data);

    if (res.data.resource && res.data.resource.length === 1) {
      await this.insertProgramToSchool(res.data.resource[0].id);
    }

  };

  insertProgramToSchool = async plan_id => {
    let programstoschool = [...this.state.programtoschool_by_plan_id]
    let requests = programstoschool.map(item => {
      item.plan_id = plan_id;
      if (item.hasOwnProperty('id')) {
        return API.patch(programToSchool, {'resource': [item]});
      } else {
        return API.post(programToSchool, {'resource': [item]});
      }
    });
    await Promise.all(requests)
  };

  delData = async planId => {
    const conf = window.confirm(`Are you sure?`)

    if (conf) {
      try {
        await API.delete(api2PlanById(planId))
        toast.success("Program deleted successfully!")
        this.clearData()
        this.loadData()
      } catch (e) {
        toast.error("Warning! There are active kids with this plan!", {position: 'bottom-center'})
        setTimeout(async () => {
          const confForce = window.confirm(`Warning!\nThis action is irreversible!\nThere are active kids with this plan!\nAre you sure?`)
          if (confForce) {
            await API.delete(api2PlanById(planId), {data: { force: true}})
            toast.success("Program deleted successfully!")
            this.clearData()
            this.loadData()
          }
        }, 1500)

      }
    }
  };

  saveData = async () => {
    let suspend_start_db = null;
    let suspend_end_db = null;

    if (this.state.suspend || this.state.suspend === 1) {
      suspend_start_db = this.state.suspend_start
        ? moment(this.state.suspend_start).format("YYYY-MM-DD hh:mm:ss")
        : moment().format("YYYY-MM-DD hh:mm:ss")
      suspend_end_db = this.state.suspend_end
        ? moment(this.state.suspend_end).format("YYYY-MM-DD hh:mm:ss")
        : moment("2050-01-01").format("YYYY-MM-DD hh:mm:ss");
    }

    let item = {
      name: this.state.name,
      price: Number(this.state.price),
      type: Number(this.state.type),
      show_plan: Number(this.state.show_plan),
      in_filter: Number(this.state.in_filter),
      suspend: Number(this.state.suspend),
      suspend_start: suspend_start_db,
      suspend_end: suspend_end_db,
      sort_score: Number(this.state.sort_score)
    };

    try {
      if (this.state.id) {
        item["id"] = this.state.id;
        await this.updateRow(item);
        toast.success("Plan changed successfully!")
      } else {
        await this.insertRow(item);
        toast.success("Plan added successfully!")
      }

      setTimeout(() => {
        this.clearData();
        this.loadData();
      }, 500)
    } catch (e) {
      toast.error("Something wrong. Please try again.")
    }
  };

  handlePlanAssign = async () => {
    const conf = window.confirm(`Are you sure?`)

    if (conf) {
      try {
        await API.post(api2PlanBulkAssign(this.state.id), {
          force_update: this.state.programBulkForceUpdateInvoices,
          classes_ids: this.state.programBulkSelectedClass,
        })
        toast.success("Program assigned successfully!")
        this.handleCloseProgramBulkDialog()
      } catch (e) {
        toast.error("Error! Something wrong!")
      }
    }
  };

  handleChange = event => this.setState({ [event.target.id]: event.target.value });

  validateData = () => {
    let errors = this.state.texterrors;
    let name = this.state.name;
    let price = this.state.price;

    if (isNaN(price)) {
      errors += "Price is not number.\n";
    }

    if (name === "") {
      errors += "Program Name cannot be empty.\n";
    }

    if (price === "") {
      errors += "Price cannot be empty.\n";
    }

    if (errors !== "") {
      this.setState({ texterrors: errors });
      return false;
    } else {
      return true;
    }
  };

  clearData = () => {
    this.setState({
      id: null,
      _etag: null,
      data: [],
      name: "",
      price: "",
      type: 1,
      show_plan: 0,
      in_filter: 0,
      suspend: 0,
      suspend_start: null,
      suspend_end: null,
      sort_score: 0,
      editPlanId: "",
      showErrorDialog: false, // Диалог с ошибками
      texterrors: "", // Список ошибок
      base: {
        discountChildren: { price: 0 },
        vacationChildren: { price: 0 }
      },
      programIsMonthly: true,
      programtoschool_by_plan_id: [],
      noProgramToSchool: true
    });
  };

  addData = () => {
    if (!this.validateData()) {
      this.handleOpenErrorDialog();
    } else {
      const plans = this.state.plans;

      let row = {
        id: this.state.id,
        _etag: this.state._etag,
        name: this.state.name,
        price: Number(this.state.price),
        type: Number(this.state.type),
        show_plan: Number(this.state.show_plan),
        in_filter: Number(this.state.in_filter),
        suspend: Number(this.state.suspend),
        suspend_start: this.state.suspend_start,
        suspend_end: this.state.suspend_end,
        sort_score: this.state.sort_score
      };

      if (this.state.editPlanId !== "") {
        plans[this.state.editPlanId] = row;
        this.setState({ editPlanId: "" });
      }

      this.saveData();
    }
  };

  editData = planId => {
    let plan = this.state.plans.find(p => p.id === planId);
    let programsToSchool = plan.programtoschool_by_plan_id;
    let noProgramToSchool = true;

    if (programsToSchool.length === 0) {
      this.setNewProgramToSchool();
    } else {
      this.setEditProgramToSchool(programsToSchool, plan.id);
      noProgramToSchool = false;
    }

    this.setState({
      id: plan.id,
      name: plan.name,
      price: plan.price,
      type: Number(plan.type),
      show_plan: Number(plan.show_plan),
      in_filter: Number(plan.in_filter),
      suspend: Number(plan.suspend),
      suspend_start: new Date(plan.suspend_start),
      suspend_end: new Date(plan.suspend_end),
      sort_score: Number(plan.sort_score),
      editPlanId: planId,
      noProgramToSchool
    });

    this.windowEnd.scrollIntoView({ behavior: "smooth" });
  };

  programMonthly = () => {
    this.state.type === 1
      ? this.setState({ type: 0 })
      : this.setState({ type: 1 });
  };

  programShow = async () => {
    if (this.state.show_plan === 1) {
      this.setState({
        show_plan: 0,
        programtoschool_by_plan_id: []
      });
    } else {
      const programsToSchool = this.state.programtoschool_by_plan_id;

      if (programsToSchool.length === 0) {
        this.setNewProgramToSchool();
      } else {
        this.setEditProgramToSchool(programsToSchool, this.state.id);
      }

      this.setState({ show_plan: 1 });
    }
  };

  handleClickInFilter = () => {
    this.setState({ in_filter: this.state.in_filter === 1 ? 0 : 1 });
  };

  handleClickSuspend = () => {
    this.setState({ suspend: this.state.suspend === 1 ? 0 : 1 });
  };

  handleSuspendStart = (event, suspend_start) => this.setState({ suspend_start });

  handleSuspendEnd = (event, suspend_end) => this.setState({ suspend_end });

  handleOnDragEnd = (result) => {
    const plans = Array.from(this.state.plans);
    const [reorderedItem] = plans.splice(result.source.index, 1);
    plans.splice(result.destination.index, 0, reorderedItem);
    this.setState({ plans });
    API.post(api2PlansReorder, plans.map(p => p.id))
  }

  changeSchool = (schoolId, value) => {
    let programtoschool_by_plan_id = [...this.state.programtoschool_by_plan_id].map(pr => {
      return pr.school_id === schoolId ? {
        ...pr,
        show_in_invoice: value ? 1 : 0
      } : pr;
    });
    this.setState({ programtoschool_by_plan_id });
  };

  handleChangePlan = planId => async (event) => {
    try {
      const groupId = event.target.value
      await API.patch(api2PlanById(planId), {'plan_group_id': groupId})
      let plans = Array.from(this.state.plans)
      plans = plans.map(plan => {
        plan.plan_group_id = plan.id === planId ? groupId : plan.plan_group_id
        return plan
      })
      this.setState({ plans });
      toast.success("Plan group changed.")
    } catch (e) {
      toast.error("Something wrong. Please try again.")
    }
  }

  handleClickBulkForceUpdateInvoices = () => {
    this.setState({ programBulkForceUpdateInvoices: !this.state.programBulkForceUpdateInvoices });
  };

  handleCheckBoxAllClick = (checked, organizationId) => {
    let selectedClasses = new Set([...this.state.programBulkSelectedClass])
    const checkedOrganization = this.state.organizations.find(org => org.id === organizationId)
    const organizationClasses = checkedOrganization.classes.map(orgClass => orgClass.id)

    if (checked) {
      organizationClasses.forEach(organizationClass => selectedClasses.add(organizationClass))
    } else {
      organizationClasses.forEach(organizationClass => selectedClasses.delete(organizationClass))
    }

    this.setState({ programBulkSelectedClass: [...selectedClasses]})
  }

  isCheckboxChecked = (id) => this.state.programBulkSelectedClass.includes(id)

  handleCheckBoxClick = (id, checked) => {
    let selectedClasses = [...this.state.programBulkSelectedClass]
    if (checked) {
      this.setState({ programBulkSelectedClass: [...selectedClasses, id]})
    } else {
      selectedClasses.splice(selectedClasses.indexOf(id), 1)
      this.setState({ programBulkSelectedClass: selectedClasses})
    }
  }

  render() {
    return (
      <div className="programs" style={{ margin: "auto" }}>
        <h3>Programs</h3>
        <hr />
        <div>
          <TextField
            floatingLabelText="Filter by name"
            fullWidth={true}
            value={this.state.programFilterValue}
            onChange={(event) => {this.setState({programFilterValue: event.target.value})}}
          />
        </div>
        <br />
        <div style={{ overflowX: 'auto'}}>
          <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 20 }}></TableCell>
              <TableCell style={{ width: 50 }}>#</TableCell>
              <TableCell style={{ width: 50 }}>
                Show Plan
              </TableCell>
              <TableCell style={{ width: 50 }}>
                Monthly
              </TableCell>
              <TableCell style={{ width: 50 }}>
                Filter
              </TableCell>
              <TableCell style={{ width: 50 }}>
                Suspend
              </TableCell>
              <TableCell style={{ width: 180 }}>Name</TableCell>
              <TableCell style={{ width: 50 }}>Price</TableCell>
              <TableCell style={{ width: 150 }}>Group</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Total Amount</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={this.handleOnDragEnd}>
            <Droppable droppableId="plans-dnd">
              {(provided) => (
                <TableBody
                  className="plans-dnd"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {this.state.plans
                    .filter(row => row.name.toLowerCase().includes(this.state.programFilterValue.toLowerCase()))
                    .map((row, index) => (
                    <Draggable key={row.id} draggableId={row.id.toString()} index={index}>
                      {(provided) => (
                        <StyledTableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          key={index}>
                          <TableCell {...provided.dragHandleProps} style={{ width: 20 }}>
                            <SwapVert />
                          </TableCell>
                          <TableCell  style={{ width: 30 }}>
                            {index + 1}
                          </TableCell>
                          <TableCell style={{ width: 50 }}>
                            <Checkbox
                              checked={Number(row.show_plan) === 1}
                              disabled
                            />
                          </TableCell>
                          <TableCell style={{ width: 50 }}>
                            <Checkbox checked={Number(row.type) === 1} disabled />
                          </TableCell>
                          <TableCell style={{ width: 50 }}>
                            <Checkbox
                              checked={
                                typeof row.in_filter === "boolean"
                                  ? row.in_filter
                                  : row.in_filter === 1
                              }
                              disabled
                            />
                          </TableCell>
                          <TableCell style={{ width: 50 }}>
                            <Checkbox
                              checked={
                                typeof row.suspend === "boolean"
                                  ? row.suspend
                                  : row.suspend === 1
                              }
                              disabled
                            />
                          </TableCell>
                          <TableCell style={{ width: 180 }}>
                            {row.name}
                          </TableCell>
                          <TableCell style={{ width: 50 }}>
                            {moneyFormatter(row.price)}
                          </TableCell>
                          <TableCell style={{ width: 150 }}>
                              <Select style={{ width: '100%'}}
                                value={row.plan_group_id}
                                onChange={this.handleChangePlan(row.id)}
                                size="small"
                                variant="standard"
                              >
                                {this.state.planGroups.map((planGroup) => (
                                  <MenuItem key={planGroup.id} value={planGroup.id}>{planGroup.title}</MenuItem>
                                ))}
                              </Select>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="text"
                              onClick={() => this.editData(row.id)}
                            >Edit</Button>
                          </TableCell>
                          <TableCell>
                            {moneyFormatter(row.price*row.childplans_count)}
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="text"
                              color="secondary"
                              onClick={() => this.delData(row.id)}
                            >Delete</Button>
                          </TableCell>
                        </StyledTableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
        </div>
        <br />
        <br />
        <hr />
        <h3 style={{ color: grey400 }}>
          {this.state.editPlanId === "" ? 'Add new program' : 'Edit Program: ' + this.state.name}
        </h3>
        <div style={{ flexDirection: "row" }}>
          <TextField
            id="sort_score"
            floatingLabelText="Sort Score"
            style={{ width: 300 }}
            value={this.state.sort_score}
            onChange={this.handleChange}
          />
          <br />
          <Checkbox
            label="Monthly Program"
            checked={this.state.type === 1}
            onCheck={this.programMonthly.bind(this)}
          />
          <br />
          <Checkbox
            label="Show in Filter"
            checked={this.state.in_filter === 1}
            onCheck={this.handleClickInFilter}
          />
          <br />
          <Checkbox
            label="Show in Invoice?"
            checked={this.state.show_plan === 1}
            onCheck={this.programShow.bind(this)}
          />
          <div className="programSchoolGrid" style={{ marginTop: "20px", paddingLeft: "40px"}}>
            {this.state.programtoschool_by_plan_id.map(pr => {
              return <Checkbox
                key={pr.school_id}
                label={this.props.Auth.allOrgs[pr.school_id].name}
                checked={Boolean(pr.show_in_invoice)}
                onCheck={(event) => this.changeSchool(pr.school_id, event.target.checked)}
                disabled={this.state.show_plan === 0}
              />
            })}
          </div>

          <Tooltip
            placement="top"
            title={this.state.editPlanId === "" ? "Active in edit mode" : "Add program to classes"}
          >
            <span>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleOpenProgramBulkDialog}
                disabled={this.state.editPlanId === ""}
              >Bulk program assignment</Button>
            </span>
          </Tooltip>

          <hr />
          <div>
            <Checkbox
              label="Suspend Program"
              checked={this.state.suspend === 1}
              onCheck={this.handleClickSuspend}
            />

            {this.state.suspend === 1 && <div style={{ display: "flex", gap: "20px"}}>
              <DatePicker
                id="suspend_start"
                hintText="Suspend Start Date"
                floatingLabelText="Suspend Start Date"
                container="inline"
                onChange={this.handleSuspendStart}
                textFieldStyle={{ width: "100%" }}
                value={this.state.suspend_start}
                locale="en-US"
                firstDayOfWeek={0}
                style={{ width: 200 }}
                disabled={!this.state.suspend}
              />
              <DatePicker
                id="suspend_end"
                hintText="Suspend End Date"
                floatingLabelText="Suspend End Date"
                container="inline"
                onChange={this.handleSuspendEnd}
                textFieldStyle={{ width: "100%" }}
                value={this.state.suspend_end}
                locale="en-US"
                firstDayOfWeek={0}
                style={{ width: 200 }}
                disabled={!this.state.suspend}
              />
            </div>}
          </div>

          <div
            className="programForm"
            ref={el => this.windowEnd = el}
          >
            <div>
              <TextField
                id="name"
                hintText="Program Name"
                floatingLabelText="Program Name"
                style={{ width: 300 }}
                value={this.state.name}
                onChange={this.handleChange}
              />
            </div>

            <div>
              <TextField
                id="price"
                hintText="Price"
                floatingLabelText="Program Price"
                style={{ width: 200 }}
                fullWidth={true}
                value={this.state.price}
                onChange={this.handleChange}
              />
            </div>

            <div>
              <Button
                variant="outlined"
                color="primary"
                onClick={this.clearData}
              >Clear</Button>
            </div>

            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={this.addData}
              >Save</Button>
            </div>

          </div>
        </div>
        <br />

        <Dialog
          open={this.state.showErrorDialog}
          onClose={this.handleCloseErrorDialog}
        >
          <DialogContent dividers>
            {this.state.texterrors.split("\n").map((i, index) => { return <p key={index}>{i}</p>; })}
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={this.handleCloseErrorDialog}>OK</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.showProgramBulkDialog}
          onClose={this.handleCloseProgramBulkDialog}
        >
          <DialogTitle>
            Bulk Program Assignment
          </DialogTitle>
          <DialogContent dividers>
            <p>Please choose the classes to update children’s profiles and invoices.</p>
            <Checkbox
              label="Update the current month invoices"
              checked={this.state.programBulkForceUpdateInvoices}
              onCheck={this.handleClickBulkForceUpdateInvoices}
            />
            <Divider sx={{ mt: 2, mb: 2 }} />
            <div style={{ overflowY: "auto", maxHeight: "300px" }}>
              {this.state.organizations.map(organization => {
                return <div key={'org-' + organization.id} style={{ marginBottom: "20px"}}>
                  <Checkbox
                    onCheck={event => this.handleCheckBoxAllClick(event.target.checked, organization.id)}
                    label={organization.name}
                    style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px" }}
                  />
                  <div style={{ paddingLeft: "40px", display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px"}}>
                    {organization.classes && organization.classes.map(orgClass => {
                      return <Checkbox
                        key={'orgClass-' + orgClass.id}
                        label={orgClass.name}
                        checked={this.isCheckboxChecked(orgClass.id)}
                        onCheck={event => this.handleCheckBoxClick(orgClass.id, event.target.checked)}
                      />
                    })}
                  </div>

                </div>
              })}
            </div>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between", pl: "24px"}}>
            <Button
              variant="contained"
              color="primary"
              autoFocus
              onClick={this.handlePlanAssign}
            >Assign</Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handleCloseProgramBulkDialog}
            >Cancel</Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

export default inject("Auth")(observer(Programs))
