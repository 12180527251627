import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import React from "react";

export const PlusIcon = () => {
	return (
		<Fab
			color="secondary"
			aria-label="add"
			size='small'
		>
			<AddIcon />
		</Fab>
	)
}
