import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";

import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import API from '../../utils/api';

import {
  getCurrentTeacher,
  getCurrentOrg,
  api2ClassesByOrganizationId
} from "../../utils/operations.js";

import { observer, inject } from "mobx-react";
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import moment from "moment";
import DatePicker from "material-ui/DatePicker";

class NewTeacher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        photo: "",
        photofile: "",
        firstname: "",
        lastname: "",
        birthday: new Date(),
        organisation: "",
        classes: "",
        email: "",
        phone: "",
        address: "",
      },
      organisations: [],
      classes: [],
      currentOrgClasses: [],
      opendialog: false,
      texterrors: "",
      showProgress: false
    };
  }

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    let orgId = this.props.Auth.orgId;

    let that = this;
    axios
      .all([
        API.get(getCurrentOrg + orgId),
        API.get(api2ClassesByOrganizationId(orgId))
      ])
      .then(
        axios.spread(function(organisations, classes) {
          let gotClasses = classes.data.data;
          let gotOrg = organisations.data;
          let relationClasses = [];

          for (let j = 0; j < gotClasses.length; j++) {
            if (gotOrg.id === gotClasses[j].organisation_id) {
              relationClasses.push(gotClasses[j]);
            }
          }
          gotOrg.classes = relationClasses;

          let data = {};
          data.organisation = Number(orgId);

          that.setState({ organisations: [gotOrg] });
          that.setState({ classes: gotClasses });
          that.setState({ data });
          that.setState({ showProgress: false });
        })
      );
  };

  saveData = async () => {
    try {
      const data = {
        resource: [
          {
            firstname: this.state.data.firstname,
            lastname: this.state.data.lastname,
            organisation_id: this.state.data.organisation,
            classes_id: this.state.data.classes,
            email: this.state.data.email,
            phone: this.state.data.phone,
            address: this.state.data.address,
            birthday: moment(this.state.data.birthday).format("YYYY-MM-DD"),
          }
        ]
      };
      await API.post(getCurrentTeacher, data)

      toast.success('New teacher was added successfully!')
      setTimeout(this.props.history.push("/teachers"), 1000);
    } catch (error) {
      let issues = [error.response.data.error.message];
      let texterrors = "";

      for (const key in issues) {
        texterrors += key + ": " + issues[key] + "\n";
      }
      this.setState({ texterrors: texterrors });

      this.handleOpen();
    }
  };

  handleOpen = () => {
    this.setState({ opendialog: true });
  };

  handleClose = () => {
    this.setState({ opendialog: false });
  };

  handleOrgChange = (event, index, value) => {
    const data = this.state.data;
    data.organisation = value;
    data.classes = "";
    this.setState({ data });

    const currentOrgClasses = this.state.organisations[index].classes;
    this.setState({ currentOrgClasses });
  };

  handleClassChange = (event, index, value) => {
    const data = this.state.data;
    data.classes = value;
    this.setState({ data });
  };

  handleChange = event => {
    const data = this.state.data;
    data[event.target.id] = event.target.value;
    this.setState({ data });
  };

  handleBirthday = (event, birthday) => {
    const data = this.state.data;
    data.birthday = birthday;
    this.setState({ data });
  };

  goPreviousPage = () => {
    this.props.history.goBack();
  };

  render() {
    const actions = [
      <FlatButton key='btn-cancel' label="Cancel" primary={true} onClick={this.handleClose} />
    ];
    return (
      <div style={{ maxWidth: 500, margin: "auto" }}>
        <br />
        <TextField
          id="firstname"
          hintText="First Name"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.firstname}
          onChange={this.handleChange}
        />
        <br />
        <TextField
          id="lastname"
          hintText="Last Name"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.lastname}
          onChange={this.handleChange}
        />
        <br />
        <DatePicker
          id="birthday"
          hintText="Birthday"
          floatingLabelText="Birthday"
          container="inline"
          onChange={this.handleBirthday}
          textFieldStyle={{ width: "100%" }}
          value={this.state.data.birthday}
          locale="en-US"
          firstDayOfWeek={0}
        />
        <br />
        <SelectField
          id="organisation"
          floatingLabelText="School"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.organisation}
          onChange={this.handleOrgChange}
        >
          {this.state.organisations.map(row => (
            <MenuItem key={row.id} value={row.id} primaryText={row.name} />
          ))}
        </SelectField>
        <br />
        <SelectField
          id="classes"
          floatingLabelText="Class"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.classes}
          onChange={this.handleClassChange}
        >
          {this.state.classes.map(rows => (
            <MenuItem key={rows.id} value={rows.id} primaryText={rows.name} />
          ))}
        </SelectField>
        <br />
        <TextField
          id="email"
          hintText="Email"
          fullWidth={true}
          value={this.state.data.email}
          onChange={this.handleChange}
        />
        <br />
        <InputMask
          mask="999-999-9999 ext. 999"
          fullWidth={true}
          value={this.state.data.phone}
          onChange={this.handleChange}
          id="phone" hintText="Phone"
          disabled={false}
        >
          {(inputProps) => <TextField {...inputProps} />}
        </InputMask>
        <br />
        <TextField
          id="address"
          hintText="Address"
          fullWidth={true}
          value={this.state.data.address}
          onChange={this.handleChange}
        />
        <br />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
            paddingTop: 20,
            paddingBottom: 20
          }}
        >
          <RaisedButton
            label="Save"
            primary={true}
            style={{ marginRight: 10 }}
            onClick={this.saveData}
          />
          <RaisedButton
            label="Cancel"
            secondary={true}
            style={{ marginLeft: 10 }}
            onClick={this.goPreviousPage}
          />
        </div>
        <Dialog
          title="Form errors:"
          actions={actions}
          modal={false}
          contentStyle={{ width: "40%", maxWidth: "none" }}
          open={this.state.opendialog}
          onRequestClose={this.handleClose}
        >
          <div>
            {// Вывести список ошибок
            this.state.texterrors.split("\n").map((i, index) => {
              return <div key={index}>{i}</div>;
            })}
          </div>
        </Dialog>
      </div>
    );
  }
}

export default inject("Auth")(observer(NewTeacher))
