import React, { Component } from "react";
import RaisedButton from "material-ui/RaisedButton";
import DatePicker from "material-ui/DatePicker";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import AutoComplete from "material-ui/AutoComplete";
import Dialog from "material-ui/Dialog";
import TextField from "material-ui/TextField";
import Toggle from "material-ui/Toggle";
import FlatButton from "material-ui/FlatButton";
import Chip from "material-ui/Chip";
import Checkbox from "material-ui/Checkbox";
import Snackbar from "material-ui/Snackbar";
import IconButton from "material-ui/IconButton";
import ContentClear from "material-ui/svg-icons/content/clear";
import {v4 as uuidv4} from "uuid";

import {
  grey800,
  blue300,
  blue600,
  lightGreenA400,
  grey400
} from "material-ui/styles/colors";

import { observer, inject } from "mobx-react";

import {
  getCurrentOrg,
  getAllPlans,
  getAllCTP,
  getAllChildren,
  getParentsByOrg,
  getCurrentChild,
  getFutureChildPlans,
  getEmergency,
  getSpecials, api2ChildStoreParent, api2Children, api2ClassesByOrganizationId,
} from "../../utils/operations.js";

import InputMask from 'react-input-mask';

import API from '../../utils/api';
import { toast } from 'react-toastify';
import { api2ChildLogFinance } from '../../utils/operations';
import moment from 'moment';
import {checkEmail} from "../../utils/misc";
import {checkIfParentExistWithEmail} from "../../utils/apiUtils";

const style = {
  maxWidth: 500,
  margin: '20px auto',
  padding: 20,
  border: "1px solid lightgrey",
  borderRadius: "20px",
  boxShadow: "0px 0px 20px rgba(0,0,0,0.2)"
};

class NewChild extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgId: 0,
      sibling: false,
      opendialog: false,
      texterrors: [],
      snackBar: false,
      child: {
        firstname: "",
        lastname: "",
        organisation: "",
        classes: "",
        parent: "",
        birthday: new Date(),
        startdate: new Date(),
        dropdate: null,
        status: "",
        photo: false,
        acs: false,
        afterschool: "",
        futuredepo: 0
      },
      school: "",
      classes: [],
      plans: [],
      parents: [],

      addedParents: [],
      parentName: "",
      parentPhone: "",
      parentPhone2: "",
      parentPhone3: "",
      parentEmail: "",
      parentAddress: "",
      parentSendInvitation: false,

      added_emergency: [],
      emergencyPhone: "",
      emergencyName: "",
      emergencyRelation: "",

      search_parent: "",

      additionally: "",
      allergies: "",
      children: null,
      siblingChildId: null,
      siblingParent: null,
      siblingEmergency: null,
      programFilterValue: ''
    };

    this.childrenSource = [];
    this.childrenSourceConfig = {
      text: "textKey",
      value: "valueKey"
    };
  }

  componentDidMount() {
    this.setState({ orgId: this.props.Auth.orgId });

    Promise.all([
      this.loadParents(),
      this.loadClasses(),
      this.loadOrganisation(),
      this.loadPrograms(),
      this.loadChildren()
    ]);
  }

  loadParents = async () => {
    const res = await API.get(getParentsByOrg + this.props.Auth.orgId);

    let parents = [];
    for (let parent of res.data.resource) {
      parents.push(`${parent.firstname} ${parent.lastname} (${parent.phone})`);
    }

    this.setState({ parents });
  };

  loadClasses = async () => {
    const classes = await API.get(api2ClassesByOrganizationId(this.props.Auth.orgId));
    this.setState({ classes: classes.data.data });
  };

  loadOrganisation = async () => {
    const organisation = await API.get(getCurrentOrg + this.props.Auth.orgId);
    this.setState({ school: organisation.data });
  };

  loadPrograms = async () => {
    const programs = await API.get(getAllPlans);
    this.setState({ plans: programs.data.resource });
  };

  loadChildren = async () => {
    let related = `?related=parents_by_childtoparent&filter=organisation_id%3D${this.props.Auth.orgId}`;
    const all_children_db = await API.get(getAllChildren + related);

    const children_db = all_children_db.data.resource.filter(function({ id }) {
      return !this.has(id) && this.add(id);
    }, new Set());

    let children = [];
    for (const child_data of children_db) {
      let parents = " (";
      let parents_data = child_data.parents_by_childtoparent;

      if (parents_data && parents_data.length === 1) {
        // Data for 1 parent
        parents += `${parents_data[0].firstname} ${parents_data[0].lastname})`;
      } else if (parents_data && parents_data.length === 2) {
        parents += `${parents_data[0].firstname} ${parents_data[0].lastname}, `;
        parents += `${parents_data[1].firstname} ${parents_data[1].lastname})`;
      } else {
        parents += " )";
      }

      let child = `${child_data.firstname} ${child_data.lastname}` + parents;
      this.childrenSource.push({ textKey: child, valueKey: child_data.id });

      children.push(child);
    }

    this.setState({ children });
  };

  loadSibling = async childId => {
    let related = "?related=parents_by_childtoparent,emergency_by_child_id";
    const res = await API.get(getCurrentChild + childId + related);

    this.setState({
      siblingParent: res.data.parents_by_childtoparent,
      siblingEmergency: res.data.emergency_by_child_id
    });
  };

  handleOpen = () => {
    this.setState({ opendialog: true });
  };

  handleClose = () => {
    this.setState({ opendialog: false });
  };

  usePhoto = () => {
    let child = this.state.child;
    child.photo = !child.photo;
    this.setState({ child });
  };

  setACS = () => {
    let child = this.state.child;
    child.acs = !child.acs;
    this.setState({ child });
  };

  handleAfterschool = e => {
    let child = this.state.child;
    child.afterschool = e.target.value;
    this.setState({ child });
  };

  handleChange = event => {
    let child = this.state.child;
    child[event.target.id] = event.target.value;
    this.setState({ child });
  };

  handleBirthday = (event, birthday) => {
    const child = this.state.child;
    child.birthday = birthday;
    this.setState({ child });
  };

  handleStartDate = (event, startDate) => {
    const child = this.state.child;
    child.startdate = startDate;
    this.setState({ child });
  };

  handleDropDate = (event, dropDate) => {
    const child = this.state.child;
    child.dropdate = dropDate;
    this.setState({ child });
  };

  handleRemoveDropDate = () => {
    const child = this.state.child;
    child.dropdate = null;
    this.setState({ child });
  };

  handleSchoolChange = (event, index, value) => {
    this.setState({ school: value });
  };

  handleClassChange = (event, index, value) => {
    let child = this.state.child;
    child.classes = value;
    this.setState({ child });
  };

  handleToggle = (e, id) => {
    let plans = [...this.state.plans].map(plan => {
      if (plan.id === id) {
          plan.toggled = e.target.checked
      }
      return plan
    });

    this.setState({ plans });
  };

  autoCompleteSibling = value => {
    let childId = value.valueKey;

    this.loadSibling(childId);

    this.setState({ siblingChildId: childId });
  };


  deleteParent = index => {
    let addedParents = this.state.addedParents;
    addedParents.splice(index, 1);
    this.setState({ addedParents });
  };

  makeParentPayer = index => {
    let addedParents = this.state.addedParents;
    addedParents[index].payer = !addedParents[index].payer;
    this.setState({ addedParents });
  };

  addParent = async () => {
    let email = this.state.parentEmail.length === 0 ? uuidv4() + "@none.com" : this.state.parentEmail;
    let phone = this.state.parentPhone.trim();
    let phone2 = this.state.parentPhone2.trim();
    let phone3 = this.state.parentPhone3.trim();
    let name = this.state.parentName.trim();
    let address = this.state.parentAddress.trim();
    let sendInvitation = this.state.parentSendInvitation;

    if (!checkEmail(email)) {
      return toast.error('Parent Email must be valid!', { autoClose: 5000 })
    }

    const existInAPI = await checkIfParentExistWithEmail(email);
    if (existInAPI) {
      return toast.error('Parent with this email is already existed. Please use another one!', { autoClose: 5000 })
    }

    if (name === "") {
      return toast.error('Parent name cannot be empty', { autoClose: 5000 })
    }

    if (phone === "") {
      return toast.error('You must add one phone number at least', { autoClose: 5000 })
    }

    this.setState({ addedParents: [
        ...this.state.addedParents,
        {
          name: name,
          phone: phone,
          phone2: phone2,
          phone3: phone3,
          email: email,
          address: address,
          payer: false,
          sendInvitation: sendInvitation,
        }
      ]
    });

    this.setState({
      parentEmail: "",
      parentPhone: "",
      parentPhone2: "",
      parentPhone3: "",
      parentName: "",
      parentAddress: "",
      parentSendInvitation: false
    });

  };

  deleteEmergency = index => {
    let added_emergency = this.state.added_emergency;
    added_emergency.splice(index, 1);
    this.setState({ added_emergency });
  };

  addEmergency = () => {
    let added_emergency = this.state.added_emergency;
    let name = this.state.emergencyName.trim();
    let relation = this.state.emergencyRelation.trim();
    let phones = this.state.emergencyPhone.trim();

    if (name === "") {
      this.setState({ texterrors: "Emergency name cannot be empty" });
      this.handleOpen();
    } else if (phones === "") {
      this.setState({ texterrors: "You must add one phone number at least" });
      this.handleOpen();
    } else {
      added_emergency.push({
        name: name,
        relation: relation,
        phones: phones
      });

      this.setState({ added_emergency });
      this.setState({ emergencyName: "", emergencyPhone: "", emergencyRelation: "" });
    }
  };

  handleAdditionally = e => {
    this.setState({ additionally: e.target.value });
  };

  handleAllergies = e => {
    this.setState({ allergies: e.target.value });
  };

  saveParents = async (childId) => {
    try {
      for (const parent of this.state.addedParents) {
        let name = parent.name.trim().split(" ");
        let [firstname, ...rest] = name;
        let lastname = rest.join(" ").trim();

        await API.post(api2ChildStoreParent(childId), {
          firstname: firstname,
          lastname: lastname,
          organisation_id: this.props.Auth.orgId,
          email: parent.email,
          phone: parent.phone,
          phone2: parent.phone2,
          phone3: parent.phone3,
          address: parent.address,
          sendInvitation: parent.sendInvitation,
        })
      }

      return true
    } catch (e) {
      toast.error('Error in parent creation action')
    }

    return false;
  };

  clearData = () => {
    this.setState({
      opendialog: false,
      texterrors: [],
      snackBar: false,
      child: {
        firstname: "",
        lastname: "",
        organisation: "",
        classes: 1,
        parent: "",
        birthday: new Date(),
        startdate: new Date(),
        dropdate: new Date(),
        status: "",
        photo: false,
        acs: false,
        afterschool: ""
      },
      parents: [],
      addedParents: [],
      parent_name: "",
      search_parent: "",
      added_emergency: [],
      additionally: "",
      allergies: "",
      siblingChildId: null,
      siblingParent: null,
      siblingEmergency: null,
      parentName: "",
      parentPhone: "",
      parentPhone2: "",
      parentPhone3: "",
      parentEmail: "",
      emergencyPhone: "",
      emergencyName: "",
      emergencyRelation: ""
    });

    // eslint-disable-next-line react/no-string-refs
    this.refs[`autosibling`].setState({ searchText: "" });
  };

  saveChildPrograms = async childId => {
    let resource = [];
    let plans = this.state.plans;

    for (let i = 0; i < plans.length; i++) {
      if (plans[i].toggled !== undefined && plans[i].toggled === true) {
        resource.push({
          child_id: childId,
          plan_id: plans[i].id
        });
      }
    }

    if (resource.length === 0) {
      resource.push({
        child_id: childId,
        plan_id: 1
      });
    }

    let childplans = { resource: resource };

    await API.post(getFutureChildPlans, childplans);
  };

  saveChildSpecials = async childId => {
    if (this.state.additionally !== "" || this.state.allergies !== "") {
      let specials = {
        resource: [
          {
            child_id: childId,
            additionally: this.state.additionally,
            allergies: this.state.allergies
          }
        ]
      };

      await API.post(getSpecials, specials);
    }
  };

  saveChild = async () => {
    let startdate = this.state.child.startdate
      ? moment(this.state.child.startdate).format("YYYY-MM-DD")
      : null;
    let dropdate = this.state.child.dropdate
      ? moment(this.state.child.dropdate).format("YYYY-MM-DD")
      : null;
    let birthday = this.state.child.birthday
      ? moment(this.state.child.birthday).format("YYYY-MM-DD")
      : null;

    let data =  {
      firstname: this.state.child.firstname,
      lastname: this.state.child.lastname,
      memo: " ",
      organisation_id: this.props.Auth.orgId,
      classes_id: this.state.child.classes,
      startdate: startdate,
      dropdate: dropdate,
      birthday: birthday,
      usephoto: this.state.child.photo,
      acs: this.state.child.acs,
      afterschool: this.state.child.afterschool,
      futuredepo: this.state.child.futuredepo
    };

    try {
      const childRes = await API.post(api2Children, data)
      return childRes.data.data.id;
    } catch (e) {
      toast.error("Error saving child")
      return null;
    }
  };

  logFinance = async (childId) => {
    if (+this.state.child.futuredepo > 0) {
      await API.post(api2ChildLogFinance(childId), {
        amount: +this.state.child.futuredepo,
        type: 'futuredepo'
      })
    }
  }

  saveWithSibling = async () => {
    // Save child data
    const childId = await this.saveChild();

    if (childId) {
      await this.logFinance(childId)

      let ctpParams = "?fields=parent_id&filter=child_id%3D";
      const ctpRes = await API.get(getAllCTP + ctpParams + this.state.siblingChildId);

      if (ctpRes.data.resource && ctpRes.data.resource.length > 0) {
        let ctpResource = [];

        for (const ctp of ctpRes.data.resource) {
          ctpResource.push({
            child_id: childId,
            parent_id: ctp.parent_id
          });
        }

        try {
          await API.post(getAllCTP, { resource: ctpResource })
        } catch (e) {
          toast.error("Error inserting sibling into CTP")
        }
      }

      let emParams = "?filter=child_id%3D";
      const emRes = await API.get(getEmergency + emParams + this.state.siblingChildId);

      if (emRes.data.resource && emRes.data.resource.length > 0) {
        let emResource = [];

        for (const emergency of emRes.data.resource) {
          emResource.push({
            child_id: childId,
            name: emergency.name,
            phone: emergency.phone
          });
        }

        try {
          await API.post(getEmergency, { resource: emResource })
        } catch (e) {
          toast.error("Error inserting sibling into emergency")
        }
      }

      this.saveChildPrograms(childId);
      this.saveChildSpecials(childId);
    } else {
      throw 'Error! Please try again.';
    }
  };

  saveWithOutSibling = async () => {
    const childId = await this.saveChild();
    if (childId) {
      await this.saveParents(childId);

      await this.logFinance(childId)

      if (this.state.added_emergency.length !== 0) {
        const emergencyResource = this.state.added_emergency.map(emergency => {
          return {
            child_id: childId,
            name: emergency.name,
            relation: emergency.relation,
            phone: emergency.phones
          }
        });

        try {
          await API.post(getEmergency, { resource: emergencyResource })
        } catch (e) {
          toast.error("Error insertion emergency")
        }
      }

      this.saveChildPrograms(childId);
      this.saveChildSpecials(childId);
    } else {
      throw 'Error! Please try again.';
    }
  };

  saveInfo = async () => {
    if (this.validateInfo()) {
      try {
        if (this.state.sibling && this.state.siblingChildId) {
          await this.saveWithSibling();
        } else {
          await this.saveWithOutSibling();
        }
        toast.success('Child added successfully!');
        setTimeout(this.props.history.push("/children"), 2000);
      } catch (e) {
        toast.error(e);
      }
    } else {
      this.handleOpen();
    }
  };

  validateInfo = () => {
    let errors = [];

    let child = this.state.child;
    if (child.firstname === "") {
      errors.push(<span>* Child first name cannot be empty</span>);
      errors.push(<br />);
    }
    if (child.lastname === "") {
      errors.push(<span>* Child last name cannot be empty</span>);
      errors.push(<br />);
    }
    if (child.classes === "") {
      errors.push(<span>* Class cannot be empty</span>);
      errors.push(<br />);
    }

    let plans = this.state.plans;
    let atleast = false;
    for (let i = 0; i < plans.length; i++) {
      if (plans[i].toggled !== undefined && plans[i].toggled !== false) {
        atleast = true;
      }
    }
    if (atleast === false) {
      errors.push(<span>* Programs not selected</span>);
      errors.push(<br />);
    }

    if (!this.state.sibling) {
      if (this.state.addedParents.length === 0) {
        errors.push(<span>* You have to add at least one parent</span>);
        errors.push(<br />);
      }
    }

    if (errors.length === 0) {
      return true;
    } else {
      this.setState({ texterrors: errors });
      return false;
    }
  };

  siblingPrepare = () => {
    let sibling = !this.state.sibling;

    // Clean sibling autocomplete
    if (!sibling) {
      this.setState({
        siblingChildId: null,
        siblingParent: null,
        siblingEmergency: null
      });
      // eslint-disable-next-line react/no-string-refs
      this.refs[`autosibling`].setState({ searchText: "" });
    }

    this.setState({ sibling });
  };

  render() {
    const actions = [
      <FlatButton key="ok-btn" label="OK" primary={true} onClick={this.handleClose} />
    ];

    return (
      <div>
        <section style={style}>
          <h3>Child</h3>
          <TextField
            id="firstname"
            hintText="First Name"
            floatingLabelText="First Name"
            fullWidth={true}
            value={this.state.child.firstname}
            onChange={this.handleChange}
          />
          <TextField
            id="lastname"
            hintText="Last Name"
            floatingLabelText="Last Name"
            fullWidth={true}
            value={this.state.child.lastname}
            onChange={this.handleChange}
          />
          <DatePicker
            id="birthday"
            hintText="Birthday"
            container="inline"
            textFieldStyle={{ width: "100%" }}
            floatingLabelText="Birthday"
            onChange={this.handleBirthday}
            value={this.state.child.birthday}
            locale="en-US"
            firstDayOfWeek={0}
          />
          <DatePicker
            id="startdate"
            hintText="Start Date"
            container="inline"
            floatingLabelText="Start Date"
            onChange={this.handleStartDate}
            textFieldStyle={{ width: "100%" }}
            value={this.state.child.startdate}
            locale="en-US"
            firstDayOfWeek={0}
          />
          <div style={{ display: "flex" }}>
            <DatePicker
              style={{flexGrow: 3}}
              id="dropdate"
              hintText="Drop Date"
              container="inline"
              floatingLabelText="Drop Date"
              textFieldStyle={{ width: '100%' }}
              onChange={this.handleDropDate}
              value={this.state.child.dropdate}
              locale="en-US"
              firstDayOfWeek={0}
            />
            <IconButton style={{width: 50}} tooltip="Clear" onClick={this.handleRemoveDropDate}>
              <ContentClear color={blue600} />
            </IconButton>
          </div>
          <SelectField
            floatingLabelText="School"
            fullWidth={true}
            value={this.state.school.id}
            disabled={true}
            onChange={this.handleSchoolChange}
          >
            <MenuItem
              value={this.state.school.id}
              primaryText={this.state.school.name}
            />
          </SelectField>
          <SelectField
            floatingLabelText="Class"
            fullWidth={true}
            value={this.state.child.classes}
            onChange={this.handleClassChange}
          >
            {this.state.classes.map(row => (
              <MenuItem key={row.id} value={row.id} primaryText={row.name} />
            ))}
          </SelectField>
          <Checkbox
            id="photo"
            label="Photo Permission"
            style={{ marginBottom: 16 }}
            defaultChecked={this.state.child.photo}
            onCheck={this.usePhoto}
          />
          <Checkbox
            id="acs"
            label="ACS"
            style={{ marginBottom: 16 }}
            defaultChecked={this.state.child.acs}
            onCheck={this.setACS}
          />
          <TextField
            id="futuredepo"
            hintText="Future Months Deposit"
            floatingLabelText="Future Months Deposit"
            fullWidth={true}
            value={this.state.child.futuredepo}
            onChange={this.handleChange}
          />
          <TextField
            hintText="School Address (for Afterschool Program)"
            floatingLabelText="School Address (for Afterschool Program)"
            fullWidth={true}
            value={this.state.child.afterschool}
            onChange={this.handleAfterschool}
          />
        </section>
        <div style={style}>
          <div className="programsFilter">
            <h3>Programs</h3>
            <div>
              <TextField
                floatingLabelText="Filter by name"
                fullWidth={true}
                value={this.state.programFilterValue}
                onChange={(event) => {this.setState({programFilterValue: event.target.value})}}
              />
            </div>
          </div>
          {this.state.plans
            .filter(row => row.name.toLowerCase().includes(this.state.programFilterValue))
            .map((row) => (
            <Toggle
              key={row.id}
              label={row.name}
              labelStyle={{ color: grey800 }}
              onToggle={e => this.handleToggle(e, row.id)}
              defaultToggled={row.toggled ? row.toggled : false}
            />
          ))}
        </div>
        <div style={style}>
          <Checkbox
            label="Sibling"
            style={{ marginBottom: 16 }}
            defaultChecked={this.state.sibling}
            onCheck={this.siblingPrepare}
          />
          {this.state.sibling && (
            <div>
              <AutoComplete
                ref={`autosibling`}
                floatingLabelText="Sibling (Firstname Lastname)"
                filter={AutoComplete.caseInsensitiveFilter}
                fullWidth={true}
                dataSource={this.childrenSource}
                dataSourceConfig={this.childrenSourceConfig}
                maxSearchResults={10}
                onNewRequest={value => this.autoCompleteSibling(value)}
              />
              <div>
                {this.state.siblingParent && <p>Parents:</p>}
                {this.state.siblingParent &&
                  this.state.siblingParent.map(row => (
                    <p key={row.id}>
                      <span>
                        {row.firstname} {row.lastname}
                      </span>
                      <br />
                      <span style={{ color: grey400 }}>{row.email}</span>
                      <br />
                      <span style={{ color: grey400 }}>{row.phone}</span>
                      <br />
                      <br />
                    </p>
                  ))}
              </div>
              <div>
                {this.state.siblingEmergency && <p>Emergency:</p>}
                {this.state.siblingEmergency &&
                  this.state.siblingEmergency.map(row => (
                    <p key={row.id}>
                      <span>{row.name}</span>
                      <br />
                      <span style={{ color: grey400 }}>{row.phone}</span>
                      <br />
                      <br />
                    </p>
                  ))}
              </div>
              <br />
            </div>
          )}
        </div>
        {!this.state.sibling && (
          <section style={style}>
            <h3>Parents</h3>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '20px'}}>
                {this.state.addedParents.map((item, index) => {
                  return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px'}} key={index}>
                      <Chip
                        backgroundColor={item.payer ? lightGreenA400 : blue300}
                        onRequestDelete={() => this.deleteParent(index)}
                        onClick={() => this.makeParentPayer(index)}
                        style={{ margin: 4 }}
                      >
                        {item.name}
                      </Chip>
                      {item.sendInvitation && <span>(send invitation)</span>}
                    </div>
                  );
                })}
              </div>
              <TextField
                hintText="Parent (Firstname Lastname)"
                floatingLabelText="Parent (Firstname Lastname)"
                id="parent"
                fullWidth={true}
                value={this.state.parentName}
                onChange={e => this.setState({ parentName: e.target.value })}
              />
              <InputMask
                mask="999-999-9999 ext. 999"
                hintText="Phone 1"
                floatingLabelText="Phone 1"
                id="phone"
                fullWidth={true}
                value={this.state.parentPhone}
                onChange={e => this.setState({ parentPhone: e.target.value })}
                disabled={false}
              >
                {(inputProps) => <TextField {...inputProps} />}
              </InputMask>
              <InputMask
                mask="999-999-9999 ext. 999"
                hintText="Phone 2"
                floatingLabelText="Phone 2"
                id="phone"
                fullWidth={true}
                value={this.state.parentPhone2}
                onChange={e => this.setState({ parentPhone2: e.target.value })}
                disabled={false}
              >
                {(inputProps) => <TextField {...inputProps} />}
              </InputMask>
              <InputMask
                mask="999-999-9999 ext. 999"
                hintText="Phone 3"
                floatingLabelText="Phone 3"
                id="phone"
                fullWidth={true}
                value={this.state.parentPhone3}
                onChange={e => this.setState({ parentPhone3: e.target.value })}
                disabled={false}
              >
                {(inputProps) => <TextField {...inputProps} />}
              </InputMask>
              <TextField
                hintText="Email"
                floatingLabelText="Email"
                id="email"
                fullWidth={true}
                value={this.state.parentEmail}
                onChange={e => this.setState({ parentEmail: e.target.value })}
              />
              <TextField
                hintText="Address"
                floatingLabelText="Address"
                id="address"
                fullWidth={true}
                value={this.state.parentAddress}
                onChange={e => this.setState({ parentAddress: e.target.value })}
              />
              <Checkbox
                style={{ marginTop: '20px'}}
                color="primary"
                label="Send payment invitation"
                checked={this.state.parentSendInvitation}
                onCheck={() => this.setState({ parentSendInvitation: !this.state.parentSendInvitation})}
              />
              <RaisedButton
                style={{ marginTop: '20px'}}
                label="Add Parent"
                primary
                fullWidth
                onClick={this.addParent}
              />
          </section>
        )}
        {!this.state.sibling && (
          <section style={style}>
            <h3>Emergency contacts</h3>
              <div style={{ display: "flex", flexDirection: "column", gap: '20px'}}>
                {this.state.added_emergency.map((item, index) => {
                  return (
                    <Chip
                      key={index}
                      backgroundColor={blue300}
                      onRequestDelete={() => this.deleteEmergency(index)}
                      style={{ margin: 4 }}
                    >
                      {item.name} - {item.relation}
                    </Chip>
                  );
                })}
              </div>
              <TextField
                hintText="Firstname Lastname"
                floatingLabelText="Firstname Lastname"
                id="emergency"
                fullWidth={true}
                value={this.state.emergencyName}
                onChange={e => this.setState({ emergencyName: e.target.value })}
              />
              <TextField
                hintText="Relation"
                floatingLabelText="Relation"
                fullWidth={true}
                value={this.state.emergencyRelation}
                onChange={e => this.setState({ emergencyRelation: e.target.value })}
              />
              <InputMask
                mask="999-999-9999 ext. 999"
                hintText="Phone Number"
                floatingLabelText="Phone Number"
                fullWidth={true}
                value={this.state.emergencyPhone}
                disabled={false}
                onChange={e =>
                  this.setState({ emergencyPhone: e.target.value })
                }
              >
                {(inputProps) => <TextField {...inputProps} />}
              </InputMask>
              <RaisedButton
                style={{ marginTop: '20px'}}
                label="Add Emergency Contact"
                primary
                fullWidth
                onClick={this.addEmergency}
              />
          </section>
        )}
        <section style={style}>
          <h3>Additionally</h3>
          <TextField
            hintText="Special Needs"
            floatingLabelText="Special Needs"
            fullWidth={true}
            multiLine={true}
            rows={3}
            value={this.state.additionally}
            onChange={this.handleAdditionally}
          />
          <TextField
            hintText="Allergies"
            floatingLabelText="Allergies"
            fullWidth={true}
            multiLine={true}
            rows={3}
            value={this.state.allergies}
            onChange={this.handleAllergies}
          />
        </section>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
            paddingBottom: 20
          }}
        >
          <RaisedButton
            label="Save Child"
            primary={true}
            style={{ marginRight: 10 }}
            onClick={this.saveInfo}
          />
          <RaisedButton
            label="Clear"
            secondary={true}
            style={{ marginLeft: 10 }}
            onClick={this.clearData}
          />
          <Dialog
            actions={actions}
            modal={false}
            contentStyle={{ width: "30%", maxWidth: "none" }}
            open={this.state.opendialog}
            onRequestClose={this.handleClose}
          >
            {this.state.texterrors}
          </Dialog>
        </div>
        <Snackbar
          open={this.state.snackBar}
          message="Child saved"
          autoHideDuration={3000}
        />
      </div>
    );
  }
}

export default inject("Auth")(observer(NewChild))
