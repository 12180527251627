export default {
  children: '',
  allChildren: '/all-children',
  childEdit: childId => childId ? "/editchild/" + childId : "/editchild/:id",
  addMedicalRecord: childId => childId ? "/editchild/" + childId + "/add-medical-record" : "/editchild/:id/add-medical-record",
  editMedicalRecord:
    (childId, medicalRecordId) => childId ? "/editchild/" + childId + "/edit-medical-record/" +medicalRecordId
      : "/editchild/:id/edit-medical-record/:medicalRecordId",
  childAddVaccineRecord: (childId, vaccineRecordId) => childId ? "/editchild/" + childId + "/add-vaccine-record/" + vaccineRecordId : "/editchild/:id/add-vaccine-record/:vaccineRecordId",
  childEditVaccineRecord:
    (childId, childVaccineRecordId) => childId ? "/editchild/" + childId + "/edit-vaccine-record/" + childVaccineRecordId
      : "/editchild/:id/edit-vaccine-record/:childVaccineRecordId",
  editSpecialNeeds: childId => childId ? "/editchild/" + childId + "/special-needs" : "/editchild/:id/special-needs",
  teacherEdit: teacherId => teacherId ? "/editteacher/" + teacherId : "/editteacher/:id",

  teacherMedicalDoc: (teacherId, type) => teacherId ? "/editteacher/" + teacherId + "/" + type : "/editteacher/:id/:type",
  teacherMedicalDocEdit: (teacherId, docId, type) => teacherId ? "/editteacher/" + teacherId + "/" + type + "/" + docId : "/editteacher/:id/:type/:docId",

  vaccineRecords: '/vaccine-records',
  addVaccineRecord: '/add-vaccine-records',
  editVaccineRecord: (recordId) => recordId ? "/edit-vaccine-records/" + recordId : "/edit-vaccine-records/:recordId",
}
