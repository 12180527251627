import React, { Component } from "react";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";

import { observer, inject } from "mobx-react";

import { getSpecials } from "../../utils/operations.js";
import API from "../../utils/api";

const style = {
  width: 500,
  margin: 20,
  padding: 20,
  textAlign: "center",
  display: "inline-block",
  borderWidth: 1,
  borderRadius: "20px",
  boxShadow: "0px 0px 20px rgba(0,0,0,0.2)"
};

class EditSpecials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgId: 0,
      opendialog: false,
      texterrors: "",
      school: "",
      name: "",
      phone: "",
      id: 0,
      allergies: "",
      specials: "",
      isEdit: false
    };
  }

  componentDidMount() {
    this.loadSpecials();
  }

  loadSpecials = () => {
    let url =
      getSpecials +
      "/?filter=child_id%3D" +
      this.props.Auth.child_id.toString();

    API.get(url).then(res => {
      let specials = res.data.resource;
      if (specials.length > 0) {
        this.setState({ allergies: specials[0].allergies });
        this.setState({ specials: specials[0].additionally });
        this.setState({ id: specials[0].id });
        this.setState({ isEdit: true });
      } else {
        this.setState({ isEdit: false });
      }
    });
  };

  handleOpen = () => this.setState({ opendialog: true })

  handleClose = () => this.setState({ opendialog: false })

  handleChangeAllergies = e => this.setState({ allergies: e.target.value })

  handleChangeSpecials = e => this.setState({ specials: e.target.value })

  saveInfo = () => {
    if (this.state.isEdit && this.state.id > 0) {
      let data = {
        allergies: this.state.allergies,
        additionally: this.state.specials
      };

      API.patch(getSpecials + "/" + this.state.id, data).then(() => {
        const goUrl = "/editchild/" + this.props.Auth.child_id;
        setTimeout(this.props.history.push(goUrl), 2000);
      })
        .catch(function(error) {
          let issues = error.response.data._issues;
          let texterrors = "";

          for (let key in issues) {
            texterrors += key + ": " + issues[key] + "\n";
          }
          this.setState({ texterrors: texterrors });

          this.handleOpen();
        });
    } else {
      let data = {
        resource: [
          {
            child_id: this.props.Auth.child_id,
            allergies: this.state.allergies,
            additionally: this.state.specials
          }
        ]
      };

      API.post(getSpecials, data).then(() => {
        const goUrl = "/editchild/" + this.props.Auth.child_id;
        setTimeout(this.props.history.push(goUrl), 2000);
      })
        .catch(function(error) {
          let issues = error.response.data._issues;
          let texterrors = "";

          for (let key in issues) {
            texterrors += key + ": " + issues[key] + "\n";
          }
          this.setState({ texterrors: texterrors });

          this.handleOpen();
        });
    }
  };

  handleClear = () => {
    this.setState({ allergies: "" });
    this.setState({ specials: "" });
  };

  goPreviousPage = () => {
    const goUrl = "/editchild/" + this.props.Auth.child_id;
    setTimeout(this.props.history.push(goUrl), 1000);
  };

  render() {
    return (
      <div style={{ margin: "auto", width: 700 }}>
        <div style={style}>
          <span>Allergies</span>
          <br />
          <TextField
            hintText="Allergies"
            floatingLabelText="Allergies"
            fullWidth
            multiLine
            rows={3}
            value={this.state.allergies}
            onChange={this.handleChangeAllergies}
          />
          <br />
          <br />
          <span>Special needs</span>
          <br />
          <TextField
            hintText="Special Needs"
            floatingLabelText="Special Needs"
            fullWidth
            multiLine
            rows={3}
            value={this.state.specials}
            onChange={this.handleChangeSpecials}
          />
        </div>
        <div
          style={{
            margin: "auto",
            width: 400,
            paddingTop: 20,
            paddingBottom: 20,
            display: "flex",
            gap: "20px",
          }}
        >
          <RaisedButton label="Save" primary onClick={this.saveInfo} />
          <RaisedButton label="Cancel" primary onClick={this.goPreviousPage} />
          <RaisedButton label="Clear" secondary onClick={this.handleClear} />
        </div>
      </div>
    );
  }
}

export default inject("Auth")(observer(EditSpecials))
