import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import TextField from "material-ui/TextField";
import Toggle from "material-ui/Toggle";
import RaisedButton from "material-ui/RaisedButton";
import AutoComplete from "material-ui/AutoComplete";
import LinearProgress from "material-ui/LinearProgress";
import { divProgressHide, divProgressShow } from "../../css/additional.js";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import {v4 as uuidv4} from "uuid";
import {
  getCurrentOrg,
  getCurrentChild,
  getChildToParent,
  getAllChildren,
  getAllParents, api2ChildStoreParent, api2Parents
} from "../../utils/operations.js";

import { observer, inject } from "mobx-react";

import { blue600 } from "material-ui/styles/colors";
import InputMask from 'react-input-mask';
import API from '../../utils/api';
import { toast } from 'react-toastify';
import {checkIfParentExistWithEmail} from "../../utils/apiUtils";
import {checkEmail} from "../../utils/misc";
import Checkbox from "material-ui/Checkbox";
import {NavLink} from "react-router-dom";
import appRoutes from "../../utils/appRoutes";

const parentSourceConfig = {
  text: "textKey",
  value: "valueKey"
};

class NewParent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        photo: "", // Файл
        photofile: "", // Картинка в аватаре
        firstname: "",
        lastname: "",
        organisation_id: "",
        email: "",
        phone: "",
        phone2: "",
        phone3: "",
        address: "",
        notify_on: true
      },
      emails: [],
      child_id: null,
      children_id: null,
      children: [],
      child: {},
      organisation: {},
      name: [],
      api_email: null,
      parent_id: null,
      opendialog: false, // Диалог с ошибками
      texterrors: "", // Список ошибок
      showProgress: true,
      existInAPI: false,
      sendInvitation: false,
    };
  }

  // Получение location для перехода на другие страницы
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    if (this.props.Auth.addParentToChildId)
      this.props.Auth.addParentToChildId = null;
  }

  loadData = () => {
    let orgId = this.props.Auth.orgId;
    let that = this;

    let data = this.state.data;
    data.organisation_id = orgId;
    this.setState({ data });

    if (this.props.Auth.addParentToChildId) {
      axios.all([
          API.get(getCurrentOrg + orgId),
          API.get(getCurrentChild + this.props.Auth.addParentToChildId),
          API.get(getAllParents)
      ]).then(
        axios.spread(function(organisation, childResult, parents) {
          let child = childResult.data;
          let gotOrg = organisation.data;

          const data = parents.data.resource.filter(function({ id }) {
            return !this.has(id) && this.add(id);
          }, new Set());

          let name = [];

          for (let j = 0; j < data.length; j++) {
            name.push({
              textKey: data[j].firstname + " " + data[j].lastname,
              valueKey: data[j].id
            });
          }

          that.setState({ name });

          that.setState({ organisation: gotOrg });
          that.setState({ child });
        })
      );
    } else {
      axios.all([
          API.get(getCurrentOrg + orgId),
          API.get(getAllChildren + "?filter=organisation_id%3D" + this.props.Auth.orgId.toString())
      ]).then(
        axios.spread(function(organisation, child) {
          let children = child.data.resource;
          let gotOrg = organisation.data;

          for (let i = 0; i < children.length; i++) {
            children[i].name = children[i].firstname + " " + children[i].lastname;
          }

          that.setState({ organisation: gotOrg });
          that.setState({ children });
        })
      );
    }

    this.setState({ showProgress: false });
  };

  saveData = async () => {
    let redirectUrl = "/children"
    let childId = this.props.Auth.child_id;
    let existInAPI = false;
    let email = this.state.data.email.length === 0 ? uuidv4() + "@none.com" : this.state.data.email;

    if (!checkEmail(email)) {
      return toast.error('Email must be valid!', { autoClose: 5000 })
    }

    existInAPI = await checkIfParentExistWithEmail(email);
    if (existInAPI) {
      return toast.error('Parent with this email is already existed. Please use another one!', { autoClose: 5000 })
    }

    this.setState({ showProgress: true });

    let stsData = {
      firstname: this.state.data.firstname,
      lastname: this.state.data.lastname,
      organisation_id: this.state.data.organisation_id,
      email: email,
      phone: this.state.data.phone,
      phone2: this.state.data.phone2,
      phone3: this.state.data.phone3,
      address: this.state.data.address,
      notify_on: this.state.data.notify_on,
      sendInvitation: this.state.sendInvitation,
    };

    try {
      if (this.props.Auth.addParentToChildId) {
        await API.post(api2ChildStoreParent(childId), stsData)
        redirectUrl = "/editchild/" + this.props.Auth.addParentToChildId
      } else {
        await API.post(api2Parents, stsData)
      }

      toast.success('New parent added successfully!');
      setTimeout(this.props.history.push(redirectUrl), 1000);
    } catch (error) {
      toast.error('Error while adding parent');
    }
  };

  // Открыть диалог
  handleOpen = () => {
    this.setState({ opendialog: true });
  };

  handleClose = () => {
    this.setState({ opendialog: false });
  };

  handleOrgChange = (event, index, value) => {
    const data = this.state.data;
    data.organisation = value;
    this.setState({ data });
  };

  handleChange = event => {
    const data = this.state.data;
    data[event.target.id] = event.target.value;
    this.setState({ data });
  };

  goPreviousPage = () => {
    this.props.history.goBack();
  };

  autoCompleteParent = value => {
    this.setState({ parent_id: value.valueKey });
  };

  addParent = () => {
    let stsCTP = {
      resource: [
        {
          child_id: this.props.Auth.addParentToChildId,
          parent_id: this.state.parent_id
        }
      ]
    };

    API.post(getChildToParent, stsCTP)
      .then(() => {
        const editUrl = "/editchild/" + this.props.Auth.addParentToChildId;
        this.props.history.push(editUrl);
      })
      .catch(error => {
        let issues = error.response.data._issues;
        let texterrors = "";

        for (const key in issues) {
          texterrors += key + ": " + issues[key] + "\n";
        }
        this.setState({ texterrors: texterrors });

        this.handleOpen();
      });
  };

  handleToggle = () => {
    let data = this.state.data;
    data.notify_on = !data.notify_on;

    this.setState({ data });
  };

  render() {
    const actions = [
      <FlatButton key='btn-cancel' label="Cancel" primary={true} onClick={this.handleClose} />
    ];

    return (
      <div style={{ maxWidth: 500, margin: "auto" }}>
        <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
          <div style={{ flex: 7 }}>&nbsp;</div>
          <div style={{ flex: 1 }}>
            <Toggle
              label="Toggle notification"
              defaultToggled={this.state.data.notify_on}
              onToggle={this.handleToggle}
              style={{ marginBottom: 16 }}
            />
          </div>
        </div>
        {this.props.Auth.addParentToChildId && (
          <div style={{ display: "inline-block" }}>
            <NavLink to={appRoutes.childEdit(this.props.Auth.addParentToChildId)} style={{ color: blue600 }}>
              {this.state.child.firstname + " " + this.state.child.lastname}
            </NavLink>
          </div>
        )}
        <br />
        {this.props.Auth.addParentToChildId && (
          <div>
            <br />
            <br />
            <span>Add an existing parent:</span>
            <AutoComplete
              floatingLabelText="Parent (Firstname Lastname)"
              filter={AutoComplete.caseInsensitiveFilter}
              fullWidth={true}
              dataSource={this.state.name}
              dataSourceConfig={parentSourceConfig}
              maxSearchResults={10}
              onNewRequest={value => this.autoCompleteParent(value)}
            />
            <br />
            <FlatButton
              label="Add Parent"
              primary={true}
              style={{ marginRight: 10 }}
              onClick={this.addParent}
            />
          </div>
        )}
        <div
          style={this.state.showProgress ? divProgressShow : divProgressHide}
        >
          <LinearProgress mode="indeterminate" />
        </div>
        <br />
        <br />
        {this.props.Auth.addParentToChildId && <span>Add new parent:</span>}
        <TextField
          id="firstname"
          hintText="First Name"
          floatingLabelText="First Name"
          errorText={this.state.data.firstname === "" && "This field is required"}
          fullWidth={true}
          value={this.state.data.firstname}
          onChange={this.handleChange}
        />
        <TextField
          id="lastname"
          hintText="Last Name"
          floatingLabelText="Last Name"
          errorText={this.state.data.lastname === "" && "This field is required"}
          fullWidth={true}
          value={this.state.data.lastname}
          onChange={this.handleChange}
        />
        <TextField
          id="organisation"
          hintText="Organisation"
          floatingLabelText="Organisation"
          fullWidth={true}
          value={this.state.organisation.name}
          disabled={true}
        />
        <TextField
          id="email"
          hintText="Email"
          floatingLabelText="Email"
          fullWidth={true}
          value={this.state.data.email}
          onChange={this.handleChange}
        />
        <InputMask
          mask="999-999-9999 ext. 999"
          fullWidth={true}
          value={this.state.data.phone}
          onChange={this.handleChange}
          id="phone"
          hintText="Phone 1"
          floatingLabelText="Phone 1"
          disabled={false}
        >
          {(inputProps) => <TextField {...inputProps} />}
        </InputMask>
        <InputMask
          mask="999-999-9999 ext. 999"
          fullWidth={true}
          value={this.state.data.phone2}
          onChange={this.handleChange}
          id="phone2"
          hintText="Phone 2"
          floatingLabelText="Phone 2"
          disabled={false}
        >
          {(inputProps) => <TextField {...inputProps} />}
        </InputMask>
        <InputMask
          mask="999-999-9999 ext. 999"
          fullWidth={true}
          value={this.state.data.phone3}
          onChange={this.handleChange}
          id="phone3"
          hintText="Phone 3"
          floatingLabelText="Phone 3"
          disabled={false}
        >
          {(inputProps) => <TextField {...inputProps} />}
        </InputMask>
        <TextField
          id="address"
          hintText="Address"
          floatingLabelText="Address"
          fullWidth={true}
          value={this.state.data.address}
          onChange={this.handleChange}
        />
        <Checkbox
          style={{ marginTop: '20px'}}
          color="primary"
          label="Send payment invitation"
          checked={this.state.sendInvitation}
          onCheck={() => this.setState({ sendInvitation: !this.state.sendInvitation})}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
            paddingTop: 20,
            paddingBottom: 20
          }}
        >
          <RaisedButton label="Save" primary={true} onClick={this.saveData} />
          <RaisedButton label="Cancel" secondary={true} onClick={this.goPreviousPage} />
        </div>
        <Dialog
          title="Form errors:"
          actions={actions}
          modal={false}
          contentStyle={{ width: "40%", maxWidth: "none" }}
          open={this.state.opendialog}
          onRequestClose={this.handleClose}
        >
          <div>
            {// Вывести список ошибок
              this.state.texterrors.split("\n").map((i, index) => {
                return <div key={index}>{i}</div>;
              })}
          </div>
        </Dialog>
      </div>
    );
  }
}

export default inject("Auth")(observer(NewParent))
