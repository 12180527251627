import React, {useEffect, useMemo, useState} from "react";

import { observer, inject } from "mobx-react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from '@mui/material/InputLabel';
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from "@mui/material";
import { dateFormatter} from "../../utils/misc";
import StyledTableRow from "../../components/StyledTableRow";
import RaisedButton from "material-ui/RaisedButton";
import Loader from "../../components/Loader";

import API from "../../utils/api";
import {api2ClassesByOrganizationId, api2Roasters} from "../../utils/operations";

const defaultFilter = {
  date: null,
  name: '',
  classes_id: ''
}

const Rosters = (props) => {
  const orgId = props.Auth.orgId
  const [loading, setLoading] = useState(false)
  const [refreshId, setRefreshId] = useState(1)

  const [classes, setClasses] = useState([])
  const [data, setData] = useState([])

  const [filter, setFilter] = useState(defaultFilter);

  const handleChange = (event) => {
    const name = event.target.name;
    setFilter({ ...filter, [name]: event.target.value });
  };

  const handleChangeDate = (value) => {
    setFilter({ ...filter, date: value });
  }

  useEffect(() => {
    loadData().then();
  }, [refreshId, filter.date])

  const filteredData = useMemo(() => {
    let filtered = data

    if (filter.name) {
      const searchString = filter.name.toLowerCase()
      filtered = filtered.filter(item => { return (item.firstname + item.lastname).toLowerCase().includes(searchString)})
    }

    if (filter.classes_id) {
      filtered = filtered.filter(item => item.classes_id === filter.classes_id)
    }

    return filtered
  }, [data, filter])

  const loadData = async () => {
    setLoading(true)

    const [
      allChildren,
      allClasses
    ] = await Promise.all([
      API.post(api2Roasters(orgId), { filter }),
      API.get(api2ClassesByOrganizationId(orgId)),
    ]);

    setData(allChildren.data.data)
    setClasses(allClasses.data.data)

    setLoading(false)
  };

  const handleClearFilters = () => {
    setFilter(defaultFilter)
    setRefreshId(refreshId + 1)
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="roasters">

        <div className="filters">
          <DatePicker
            slotProps={{ textField: { variant: 'standard' }}}
            name='date'
            views={['year', 'month']}
            label="Year and Month"
            value={filter.date}
            onChange={handleChangeDate}
          />

          <FormControl className='filtersSelector'>
            <InputLabel id="class-selector">Class</InputLabel>
            <Select
              labelId="class-selector"
              name="classes_id"
              value={filter.classes_id}
              onChange={handleChange}
              size="small"
              variant="standard"
            >
              {classes.map(row => (<MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>))}
            </Select>
          </FormControl>

          <TextField
            label='Child Name'
            name='name'
            value={filter.name}
            onChange={handleChange}
            size="small"
            variant="standard"
          />

          <RaisedButton label='Clear' onClick={handleClearFilters} />
        </div>

        <div>
          <Loader loaded={!loading}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Child Name</TableCell>
                  <TableCell>Class</TableCell>
                  <TableCell>Start Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((row, index) => {
                  return (
                    <StyledTableRow key={row.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.firstname} {row.lastname}</TableCell>
                      <TableCell>{row.classes.name}</TableCell>
                      <TableCell>{dateFormatter(row.startdate)}</TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Loader>
        </div>
      </div>
    </LocalizationProvider>
  );
}

export default inject("Auth")(observer(Rosters))
