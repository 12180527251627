import React, { Component } from "react";

import { List, ListItem } from "material-ui/List";
import Divider from "material-ui/Divider";
import { NavLink } from "react-router-dom";

import { observer, inject } from "mobx-react";
import appRoutes from "../utils/appRoutes";

const SuperAdmin = () => {
  return (
    <List className="app-left-menu">
      <ListItem
        primaryText="Accounts"
        style={{ color: "white" }}
        primaryTogglesNestedList={true}
        initiallyOpen={true}
        nestedItems={[
          <NavLink key='acc-1' className="app-left-menu-link level-2" to="/teachers">Teachers</NavLink>,
          <NavLink key='acc-2' className="app-left-menu-link level-2" to="/newchild">Add Child</NavLink>,
          <NavLink key='acc-3' className="app-left-menu-link level-2" to="/parents">Parents</NavLink>,
          <NavLink key='acc-4' className="app-left-menu-link level-3" to="/contact-list">Contact list</NavLink>,
          <NavLink key='acc-5' className="app-left-menu-link level-2" to="/children">Children</NavLink>,
          <NavLink key='acc-6' className="app-left-menu-link level-3" to={appRoutes.allChildren}>All Children</NavLink>,
          <NavLink key='acc-7' className="app-left-menu-link level-2" to="/dob">DOB</NavLink>,
          <NavLink key='acc-8' className="app-left-menu-link level-2" to={appRoutes.vaccineRecords}>Medical Records</NavLink>,
        ]}
      />
      <Divider />
      <NavLink className="app-left-menu-link" to="/organisations">Schools</NavLink>
      <NavLink className="app-left-menu-link" to="/classes">Classes</NavLink>
      <NavLink className="app-left-menu-link" to="/class-rosters">Class Rosters</NavLink>
      <NavLink className="app-left-menu-link" to="/attendance">Attendance Tracker</NavLink>
      <NavLink className="app-left-menu-link" to="/admins">Administrators</NavLink>
      <Divider />
      <ListItem
        primaryText="Finance"
        style={{ color: "white" }}
        primaryTogglesNestedList={true}
        initiallyOpen={false}
        nestedItems={[
          <NavLink key='fin-1' className="app-left-menu-link level-2" to="/programs">Programs</NavLink>,
          <NavLink key='fin-2' className="app-left-menu-link level-2" to="/invoices">Invoices</NavLink>,
          <NavLink key='fin-3' className="app-left-menu-link level-2" to="/transactions">Transactions</NavLink>,
          <NavLink key='fin-4' className="app-left-menu-link level-2" to="/sendarchive">Send Archive</NavLink>,
          <NavLink key='fin-5' className="app-left-menu-link level-2" to="/allfeatureinvoices">Future Invoices</NavLink>,
          <NavLink key='fin-6' className="app-left-menu-link level-2" to="/dashboard">Financial Charts</NavLink>,
          <NavLink key='fin-7' className="app-left-menu-link level-2" to="/schedule">Invoice Schedule</NavLink>,
        ]}
      />
      <Divider />
      <NavLink className="app-left-menu-link" to="/mealmenu">Meal Menu</NavLink>
      <Divider />
      <NavLink className="app-left-menu-link" to="/imex">Import/Export</NavLink>
    </List>
  );
};

const Admin = (props) => {
  const show_finance = props.profile && props.profile.show_finance;
  const show_charts = props.profile && props.profile.show_charts;
  const show_future_invoices = props.profile && props.profile.show_future_invoices;

  const financeItems = [];

  if (show_finance) {
    financeItems.push(<NavLink key={1} className="app-left-menu-link level-2" to="/programs">Programs</NavLink>);
    financeItems.push(<NavLink key={2} className="app-left-menu-link level-2" to="/invoices">Invoices</NavLink>);
    financeItems.push(<NavLink key={3} className="app-left-menu-link level-2" to="/sendarchive">Send Archive</NavLink>);
  }

  if (show_future_invoices) {
    financeItems.push(<NavLink key={4} className="app-left-menu-link level-2" to="/allfeatureinvoices">Future Invoices</NavLink>);
  }

  if (show_charts) {
    financeItems.push(<NavLink key={5} className="app-left-menu-link level-2" to="/dashboard">Financial Charts</NavLink>);
  }

  return (
    <List className="app-left-menu">
      <ListItem
        primaryText="Accounts"
        style={{ color: "white" }}
        primaryTogglesNestedList={true}
        initiallyOpen={false}
        nestedItems={[
          <NavLink key='acc-1' className="app-left-menu-link level-2" to="/teachers">Teachers</NavLink>,
          <NavLink key='acc-2' className="app-left-menu-link level-2" to="/newchild">Add Child</NavLink>,
          <NavLink key='acc-3' className="app-left-menu-link level-2" to="/parents">Parents</NavLink>,
          <NavLink key='acc-4' className="app-left-menu-link level-3" to="/contact-list">Contact list</NavLink>,
          <NavLink key='acc-5' className="app-left-menu-link level-2" to="/children">Children</NavLink>,
          <NavLink key='acc-6' className="app-left-menu-link level-2" to="/dob">DOB</NavLink>,
          <NavLink key='acc-7' className="app-left-menu-link level-2" to={appRoutes.vaccineRecords}>Medical Records</NavLink>,
        ]}
      />
      <Divider />
      <NavLink className="app-left-menu-link" to="/classes">Classes</NavLink>
      <NavLink className="app-left-menu-link" to="/class-rosters">Class Rosters</NavLink>
      <NavLink className="app-left-menu-link" to="/attendance">Attendance Tracker</NavLink>
      <Divider />
      <ListItem
        primaryText="Finance"
        style={{ color: "white" }}
        primaryTogglesNestedList={true}
        initiallyOpen={false}
        nestedItems={financeItems}
      />
      {financeItems && <div>
        <Divider />
        <NavLink className="app-left-menu-link" to="/mealmenu">Meal Menu</NavLink>
      </div>}
    </List>
  );
};

const Default = () => <List />;

class LeftMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      profile: null,
      role: ""
    };
  }

  static getDerivedStateFromProps(props) {
    return { role: props.Auth.role };
  }

  render() {
    switch (this.state.role) {
      case "superadmin":
        return <SuperAdmin />;
      case "admin":
        return <Admin profile={this.props.Auth.profile} />;
      default:
        return <Default />;
    }
  }
}
export default inject("Auth")(observer(LeftMenu))
