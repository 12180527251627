import React, { Component } from "react";
import axios from "axios";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";

import LinearProgress from "material-ui/LinearProgress";
import { divProgressHide, divProgressShow } from "../../css/additional.js";

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from "@mui/material";

import { observer, inject } from "mobx-react";

import {
  getCurrentOrg,
  getTeachersByOrg, api2ClassesByOrganizationId
} from "../../utils/operations.js";
import API from '../../utils/api';
import withStyles from '@mui/styles/withStyles';

const style = {
  marginLeft: 'calc(50% - 20px)',
  marginTop: 30,
};

const ClickableRow = props => {
  const { userID, history, ...restProps } = props;

  const editData = value => {
    const editUrl = "/editteacher/" + value;
    history.push(editUrl);
  };

  return (
    <TableRow {...restProps} onMouseDown={() => editData(userID)} style={{cursor: 'pointer'}}>
      {props.children}
    </TableRow>
  );
};

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(142, 195, 242, 0.4)',
    },
    '&:hover': {
      backgroundColor: '#f5f5f5',
    }
  },
}))(ClickableRow);

class Teachers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      role: "teacher",
      showProgress: true,
    };
  }

  componentDidMount() {
    let orgId = this.props.Auth.orgId;
    this.loadData(orgId);
  }

  loadData = orgId => {
    let that = this;

    axios.all([
      API.get(getTeachersByOrg + orgId),
      API.get(getCurrentOrg + orgId),
      API.get(api2ClassesByOrganizationId(orgId))
      ])
      .then(
        axios.spread(function(teachers, organisations, classes) {
          let data = teachers.data.resource;
          let org = organisations.data;
          let claSet = classes.data.data;

          for (let j = 0; j < data.length; j++) {
            data[j].organisation_name = org.name;

            for (let i = 0; i < claSet.length; i++) {
              if (data[j].classes_id === claSet[i].id) {
                data[j].classes_name = claSet[i].name;
              }
            }
          }

          that.setState({ data });
          that.setState({ showProgress: false });
        })
      );
  };

  editData = value => {
    const editUrl = "/editteacher/" + value;
    this.props.history.push(editUrl);
  };

  newUser = () => {
    const newUrl = "/newteacher/";
    this.props.history.push(newUrl);
  };



  render() {
    const edit = this.props.history;
    return (
      <div style={{ margin: "auto", overflowX: 'scroll' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>School</TableCell>
              <TableCell>Class</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.data.map((row, index) => (
              <StyledTableRow key={index} userID={row.id} history={edit}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row.firstname}</TableCell>
                <TableCell>{row.lastname}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.organisation_name}</TableCell>
                <TableCell>{row.classes_name}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <div
          style={this.state.showProgress ? divProgressShow : divProgressHide}
        >
          <LinearProgress mode="indeterminate" />
        </div>
        <FloatingActionButton
          style={style}
          secondary={true}
          onClick={this.newUser}
        >
          <ContentAdd />
        </FloatingActionButton>
      </div>
    );
  }
}

export default inject("Auth")(observer(Teachers))
