export const parentsUrl = "https://sts.scrabtest.info:5001/parents";

export const authUser = "admin";
export const authPw = "secret";

export const API_KEY = process.env.REACT_APP_API_KEY;
export const APIKeyMenu = process.env.REACT_APP_API_KEY_MENU;
export const uploadAPI = process.env.REACT_APP_API_UPLOAD;

export const baseUrl = process.env.REACT_APP_API_BASE_URL;

const basePath = "sts/_table/";

export const profile = baseUrl + "user/profile";
export const invoicesUrl = baseUrl + basePath + "bills";
export const getTokenUrl = baseUrl + "user/session";
export const getCurrentOrg = baseUrl + basePath + "organisations/";
export const getAllOrg = baseUrl + basePath + "organisations";
export const getCurrentInvoice = baseUrl + basePath + "bills/";
export const getCurrentParent = baseUrl + basePath + "parents/";
export const getCurrentTeacher = baseUrl + basePath + "teachers/";
export const getCurrentChild = baseUrl + basePath + "childs/";
export const getChildToParent = baseUrl + basePath + "childtoparent/";
export const getAllCTP = baseUrl + basePath + "childtoparent";
export const getCurrentChildPlans = baseUrl + basePath + "childplans";
export const getFutureChildPlans = baseUrl + basePath + "futurechildplans";
export const getCurrentPlans = baseUrl + basePath + "plans";
export const getArchive = baseUrl + basePath + "archive";
export const getChildPlans = baseUrl + basePath + "childplans?filter=child_id%3D";
export const getPlans = baseUrl + basePath + "plans?ids=";
export const getAllPlans = baseUrl + basePath + "plans";
export const getAllParents = baseUrl + basePath + "parents";
export const getAllDepos = baseUrl + basePath + "depo_updated";
export const getAllChildren = baseUrl + basePath + "childs";
export const getAllAdmins = baseUrl + basePath + "admins";
export const getAllClasses = baseUrl + basePath + "classes/";
export const getParentsByOrg = baseUrl + basePath + "parents?filter=organisation_id%3D";
export const getClassesByOrg = baseUrl + basePath + "classes?filter=organisation_id%3D";
export const getTeachersByOrg = baseUrl + basePath + "teachers?filter=organisation_id%3D";
export const getChildsByOrg = baseUrl + basePath + "childs?filter=organisation_id%3D";
export const getAdminsByOrg = baseUrl + basePath + "classes?filter=organisation_id%3D";
export const getEmergency = baseUrl + basePath + "emergency";
export const getSpecials = baseUrl + basePath + "specials";
export const getMailing = baseUrl + basePath + "mailing";
export const getSendArchive = baseUrl + basePath + "send_archive";
export const getSumByMonth = baseUrl + basePath + "sum_by_month";
export const programToSchool = baseUrl + basePath + "programtoschool";
export const getNMP = baseUrl + basePath + "nextmonthpayment";
export const monthlyBilled = baseUrl + basePath + "monthly_billed?filter=organisation_id%3D";
export const registerUser = baseUrl + "system/user?send_invite=false";
export const dreamUsers = baseUrl + "system/user";

export const dreamUser = user_id => dreamUsers + "/" + user_id
export const dreamUserWithRoles = user_id => dreamUser(user_id) + "?related=user_to_app_to_role_by_user_id";
export const childFilters =
  baseUrl +
  basePath +
  `childs?fields=
id%2C%20firstname%2C%20lastname%2C%20birthday%2C%20usephoto%2C%20afterschool%2C%20startdate%2C%20dropdate%2C%20acs
&related=parents_by_childtoparent%2Cclasses_by_classes_id%2Cplans_by_childplans%2Cplans_by_futurechildplans`;

export const upload = baseUrl + "import";

export const featureInvoices =
  baseUrl +
  basePath +
  "childs?related=plans_by_futurechildplans,nextmonthpayment_by_child_id,classes_by_classes_id&filter=";

export const menu = baseUrl + "menu/menu/";

export const baseLaravelApiUrl = process.env.REACT_APP_API_BASE_LARAVEL_URL + "api/";

export const getLaravelTest = baseLaravelApiUrl + "test";

export const api2PublicStorage = `${process.env.REACT_APP_API_BASE_LARAVEL_URL}storage/`;

export const api2Admins = baseLaravelApiUrl + "admin";
export const api2AdminByEmail = baseLaravelApiUrl + "admin/getByEmail";
export const api2AdminAvailableOrganizations = admin_id =>
  baseLaravelApiUrl + "admin/" + admin_id + "/getAvailableOrganizations";
export const api2AdminAdditionalOrganizations = admin_id =>
  baseLaravelApiUrl + "admin/" + admin_id + "/additionalOrganizations";

export const api2Children = baseLaravelApiUrl + "child";

export const api2ChildShow = child_id => baseLaravelApiUrl + "child/" + child_id;

export const api2ChildDelete = child_id => baseLaravelApiUrl + "child/" + child_id;

export const api2ChildPatch = child_id => baseLaravelApiUrl + "child/" + child_id;

export const api2ChildTransferParentsToSchool = child_id => baseLaravelApiUrl + "child/" + child_id + "/transferParentsToSchool";

export const api2ChildStoreParent = child_id => baseLaravelApiUrl + "child/" + child_id + "/storeParent";

export const api2ChildEditMedicalRecord = (child_id, medical_record_id) => baseLaravelApiUrl + "child/" + child_id + "/edit-medical-record/" + medical_record_id;

export const api2ChildVaccineRecords = (child_id) => baseLaravelApiUrl + "child/" + child_id + "/vaccine-records";

export const api2ChildEditVaccineRecord = (child_id, vaccine_record_id) => baseLaravelApiUrl + "child/" + child_id + "/vaccine-record/" + vaccine_record_id;

export const api2ChildDeleteMedicalRecord = (child_id, medical_record_id) => baseLaravelApiUrl + "child/" + child_id + "/delete-medical-record/" + medical_record_id;

export const api2ChildStoreMedicalRecord = child_id => baseLaravelApiUrl + "child/" + child_id + "/store-medical-record";

export const api2ChildStoreVaccineRecord = child_id => baseLaravelApiUrl + "child/" + child_id + "/vaccine-record";

export const api2ChildGetFutureInvoice = child_id => baseLaravelApiUrl + "child/" + child_id + "/getFutureInvoice";

export const api2ChildSendFutureInvoice = child_id => baseLaravelApiUrl + "child/" + child_id + "/sendFutureInvoice";

export const api2ChildUpdateInvoice = child_id => baseLaravelApiUrl + "child/" + child_id + "/updateInvoice";

export const api2ChildSendInvitationToCabinet = child_id => baseLaravelApiUrl + "child/" + child_id + "/sendInvitationToCabinet";
export const api2Parents = baseLaravelApiUrl + "parent";
export const api2Parent = parent_id => baseLaravelApiUrl + "parent/" + parent_id;

export const api2VaccineRecords = baseLaravelApiUrl + "vaccine-records";
export const api2VaccineRecord = record_id => baseLaravelApiUrl + "vaccine-records/" + record_id;

export const api2ParentSendInvitationToCabinet = parent_id => baseLaravelApiUrl + "parent/" + parent_id + "/sendInvitationToCabinet";

export const api2ParentRemoteLogin = parent_id => baseLaravelApiUrl + "parent/" + parent_id + "/remoteLogin";

export const api2ChildDeleteInvoice = child_id => baseLaravelApiUrl + "child/" + child_id + "/deleteInvoice";

export const api2ChildEmailInvoice = child_id => baseLaravelApiUrl + "child/" + child_id + "/emailInvoice";

export const api2ChildGetArchive = child_id => baseLaravelApiUrl + "child/" + child_id + "/getArchive";

export const api2ChildLogFinance = child_id => baseLaravelApiUrl + "child/" + child_id + "/logFinance";
export const api2ChildGetFinanceLog =  baseLaravelApiUrl + "child/finance-log";

export const api2ChildSendArchive = child_id => baseLaravelApiUrl + "child/" + child_id + "/sendArchive";
export const api2PlansReorder = baseLaravelApiUrl + "plan/reorder";
export const api2PlanById = plan_id => baseLaravelApiUrl + "plan/" + plan_id;

export const api2PlanBulkAssign = plan_id => baseLaravelApiUrl + "plan/" + plan_id + "/bulk-assign";
export const api2AllPlans = baseLaravelApiUrl + "plan";
export const api2statCreditPlans = baseLaravelApiUrl + "plan/getStatCreditPlans";
export const api2statIncomePlans = baseLaravelApiUrl + "plan/getStatIncomePlans";
export const api2PlanGroups = baseLaravelApiUrl + "plan/getPlanGroups";

export const api2TeacherPatch = teacher_id => baseLaravelApiUrl + "teacher/" + teacher_id;

export const api2TeacherDocs = teacher_id => baseLaravelApiUrl + "teacher/" + teacher_id + "/docs";

export const api2TeacherDoc = (teacher_id, doc_id) => baseLaravelApiUrl + "teacher/" + teacher_id + "/docs/" + doc_id;

export const api2TeacherDocsAttachment = teacher_id => baseLaravelApiUrl + "teacher/" + teacher_id + "/doc-attachments";
export const api2TeacherDocsAttachmentDelete = (teacher_id, attachment_id) => baseLaravelApiUrl + "teacher/" + teacher_id + "/doc-attachments/" + attachment_id;
export const api2Bills = baseLaravelApiUrl + "bill";

export const api2BillById = bill_id => baseLaravelApiUrl + "bill/" + bill_id;
export const api2FutureInvoices = baseLaravelApiUrl + "bill/getFutureInvoices";
export const api2SendOverdueReminder = baseLaravelApiUrl + "bill/sendOverdueReminder";
export const api2SendFailedReminder = baseLaravelApiUrl + "bill/sendFailedReminder";

export const api2SendUnpaidReminder = baseLaravelApiUrl + "bill/sendUnpaidReminder";

export const api2SendUnpaidReminderAll = baseLaravelApiUrl + "bill/sendUnpaidReminderAll";

export const api2StatBilled = baseLaravelApiUrl + "stat/billed";
export const api2StatEnrolled = baseLaravelApiUrl + "stat/enrolled";
export const api2StatPlans = baseLaravelApiUrl + "stat/plans";
export const api2StatPlansCredit = baseLaravelApiUrl + "stat/plansCredits";

export const api2Classes = baseLaravelApiUrl + "class";

export const api2Organizations = baseLaravelApiUrl + "organization";

export const api2ClassesByOrganizationId = organizationId =>
  baseLaravelApiUrl + "organization/" + organizationId + "/classes";
export const api2ChildDroppedByOrganizationId = organizationId =>
  baseLaravelApiUrl + "organization/" + organizationId + "/getDroppedChildren";

export const api2ParentsByOrganizationId = organizationId =>
  baseLaravelApiUrl + "organization/" + organizationId + "/parents";

export const api2OrganizationInvoicesByClass = (organizationId, classId) =>
  baseLaravelApiUrl +
  "organization/" +
  organizationId +
  "/printInvoicesByClass/" +
  classId;

export const api2OrganizationSendInvitationsToCabinet = organizationId =>
  baseLaravelApiUrl +
  "organization/" +
  organizationId +
  "/sendInvitationsToCabinet";

export const api2Roasters = organizationId =>
  baseLaravelApiUrl + "organization/" + organizationId + "/class-rosters";

export const api2Attendance = organizationId =>
  baseLaravelApiUrl + "organization/" + organizationId + "/class-attendance";

export const api2AttendanceItem = attendanceId =>
  baseLaravelApiUrl + "organization/class-attendance-archive/" + attendanceId;

export const api2AttendanceArchive = organizationId =>
  baseLaravelApiUrl + "organization/" + organizationId + "/class-attendance-archive";

export const api2Class = id => baseLaravelApiUrl + "class/" + id;

