import {makeObservable, observable, action, runInAction} from "mobx";

class ChildrenPageStateClass {
  kind = "All"

  filter = {
    upcoming: '',
    acs: '',
    afterschool: '',
    childId: '',
    childName: '',
    childEmail: '',
    program: '',
    credits: '',
    class: '',
    hasSpecials: false,
  }

  sortBy = "id"
  sortWay = "asc"

  constructor() {
    makeObservable(this, {
      kind: observable,
      filter: observable,
      sortBy: observable,
      sortWay: observable,
      setKind: action,
      setFilter: action,
    })
  }

  setKind(kind) {
    runInAction(() => {
      this.kind = kind
    })
  }

  setFilter(items) {
    runInAction(() => {
      this.filter = {
        ...this.filter,
        ...items
      }
    })
  }

  setSorting(sortBy, sortWay) {
    runInAction(() => {
      this.sortBy = sortBy
      this.sortWay = sortWay
    })
  }

}

const ChildrenPageState = new ChildrenPageStateClass();
export default ChildrenPageState;

