import * as React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

export const AttendanceSwitch = styled((props) => {
	let additionalClass = '';
	switch(props.attendance) {
		case 'sick':
			additionalClass = 'MuiSwitch-attendanceSick'
			break;
		case 'vacation':
			additionalClass = 'MuiSwitch-attendanceVacation'
			break;
		case 'absentee':
			additionalClass = 'MuiSwitch-attendanceAbsentee'
			break;
	}

	return <Switch classes={{ root: additionalClass}} focusVisibleClassName=".Mui-focusVisible" {...props} />
})(() => ({
	padding: 10,
	'& .MuiSwitch-switchBase': {
		padding: 6,
	},
	'& .MuiSwitch-thumb': {
		width: 26,
		height: 26,
	},
	'& .MuiSwitch-track': {
		borderRadius: 26 / 2,
	},
	'&.MuiSwitch-attendanceSick .MuiSwitch-thumb' : {
		backgroundColor: '#f44336',
	},
	'&.MuiSwitch-attendanceVacation .MuiSwitch-thumb' : {
		backgroundColor: '#ffcc00',
	},
	'&.MuiSwitch-attendanceAbsentee .MuiSwitch-thumb' : {
		backgroundColor: '#FF8000'
	},
	'&.MuiSwitch-attendanceSick .MuiSwitch-track' : {
		backgroundColor: '#f44336',
	},
	'&.MuiSwitch-attendanceVacation .MuiSwitch-track' : {
		backgroundColor: '#ffcc00',
	},
	'&.MuiSwitch-attendanceAbsentee .MuiSwitch-track' : {
		backgroundColor: '#FF8000'
	},
	'&.MuiSwitch-attendanceSick .Mui-disabled .MuiSwitch-thumb' : {
		backgroundColor: '#dc6d60',
	},
	'&.MuiSwitch-attendanceVacation .Mui-disabled .MuiSwitch-thumb' : {
		backgroundColor: '#c08e48',
	},
}));
