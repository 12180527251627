import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import axios from "axios";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";

import LinearProgress from "material-ui/LinearProgress";
import { divProgressHide, divProgressShow } from "../../css/additional.js";

import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
  getCurrentTeacher,
  getCurrentOrg,
  api2TeacherDocs, api2ClassesByOrganizationId,
} from "../../utils/operations.js";

import API from '../../utils/api';

import InputMask from 'react-input-mask';
import { api2TeacherPatch } from '../../utils/operations';
import { toast } from 'react-toastify';
import {checkEmail} from "../../utils/misc";
import DatePicker from "material-ui/DatePicker";
import moment from "moment/moment";
import {ExpandedField} from "../../components/ExpandedField";
import appRoutes from "../../utils/appRoutes";
import {NavLink} from "react-router-dom";
import {TEACHER_DOC_DOH_TYPE, TEACHER_DOC_MEDICAL_TYPE} from "../../utils/constants";

class EditTeacher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        photo: "",
        photofile: "",
        firstname: "",
        lastname: "",
        birthday: new Date(),
        organisation: "",
        classes: "",
        email: "",
        phone: "",
        address: "",
      },
      docs_medical: [],
      docs_doh: [],
      opendialog: false,
      texterrors: "",
      organisations: [], // Список организаций
      classes: [],
      currentOrgClasses: [],
      showProgress: true,
    };

    this.teacherId = this.props.match.params.id;
  }

  handleOpen = () => {
    this.setState({ opendialog: true });
  };

  handleClose = () => {
    this.setState({ showProgress: false });
    this.setState({ opendialog: false });
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    let orgId = this.props.Auth.orgId;

    const teacherId = this.props.match.params.id;

    axios
      .all([
        API.get(getCurrentTeacher + teacherId),
        API.get(api2TeacherDocs(teacherId)),
        API.get(getCurrentOrg + orgId),
        API.get(api2ClassesByOrganizationId(orgId))
      ])
      .then(
        axios.spread((teacher, docs, organisations, classes) => {
          let data = teacher.data;
          let teacherDocs = docs.data.data;
          let gotClasses = classes.data.data;
          let gotOrg = organisations.data;
          let relationClasses = [];
          let currentOrgClasses = [];

          for (let j = 0; j < gotClasses.length; j++) {
            if (gotOrg.id === gotClasses[j].organisation_id) {
              relationClasses.push(gotClasses[j]);

              if (gotClasses[j].id === data.classes_id) {
                data.classes_name = gotClasses[j].name;
              }
            }

            if (gotOrg.id === data.organisation_id) {
              data.organisation_name = gotOrg.name;
            }

            if (data.organisation_id === gotOrg.id) {
              currentOrgClasses.push(gotClasses[j]);
            }
          }
          gotOrg.classes = relationClasses;

          if (data.photo !== "undefined") {
            data.photofile = data.photo;
            data.photo = "undefined";
          }

          if (data.birthday) {
            data.birthday = new Date(data.birthday);
          }

          this.setState({
            currentOrgClasses,
            organisations: gotOrg,
            classes: gotClasses,
            data,
            docs_medical: teacherDocs.medical,
            docs_doh: teacherDocs.doh,
            showProgress: false
          });
        })
      );
  };

  deleteData = () => {
    const teacherId = this.state.data.id;
    this.setState({ opendialog: false });

    API.delete(getCurrentTeacher + teacherId).then(() => {
      toast.success("Teacher deleted successfully.")
      this.props.history.push("/teachers");
    }).catch(() => {
      toast.error("Something went wrong.")
    });
  };

  saveData = async () => {
    const teacherId = this.props.match.params.id;

    if (!checkEmail(this.state.data.email)) {
      toast.error("Email must be valid!")
      return;
    }

    let data = {
      firstname: this.state.data.firstname,
      lastname: this.state.data.lastname,
      organisation_id: this.state.data.organisation,
      classes_id: this.state.data.classes_id,
      email: this.state.data.email,
      phone: this.state.data.phone,
      address: this.state.data.address,
      birthday: moment(this.state.data.birthday).format("YYYY-MM-DD"),
    };

    this.setState({ showProgress: true });
    try {
      await API.patch(api2TeacherPatch(teacherId), data)

      toast.success("Teacher edited successfully.")
      setTimeout(this.props.history.push("/teachers"), 1000);
    } catch (e) {
      toast.error("Something wrong. Please try again.")
    } finally {
      this.setState({ showProgress: true });
    }

  };

  handleOrgChange = (event, index, value) => {
    const data = this.state.data;
    data.organisation_id = value;
    data.classes = "";
    this.setState({ data });

    const currentOrgClasses = this.state.organisations[index].classes;
    this.setState({ currentOrgClasses });
  };

  handleClassChange = (event, index, value) => {
    const data = this.state.data;
    data.classes_id = value;
    this.setState({ data });
  };

  handleChange = event => {
    const data = this.state.data;
    data[event.target.id] = event.target.value;

    this.setState({ data });
  };

  handleBirthday = (event, birthday) => {
    const data = this.state.data;
    data.birthday = birthday;
    this.setState({ data });
  };

  goPreviousPage = () => {
    this.props.history.goBack();
  };


  render() {
    const actions = [
      <FlatButton key="ok" label="OK" primary={true} onClick={this.deleteData} />,
      <FlatButton key="cancel" label="Cancel" primary={true} onClick={this.handleClose} />
    ];
    return (
      <div style={{ width: 500, margin: "auto" , paddingBottom: '40px' }}>
        <div
          style={this.state.showProgress ? divProgressShow : divProgressHide}
        >
          <LinearProgress mode="indeterminate" />
        </div>
        <br />
        <TextField
          id="firstname"
          hintText="First Name"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.firstname}
          onChange={this.handleChange}
        />
        <br />
        <TextField
          id="lastname"
          hintText="Last Name"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.lastname}
          onChange={this.handleChange}
        />
        <br />
        <DatePicker
          id="birthday"
          hintText="Birthday"
          floatingLabelText="Birthday"
          container="inline"
          onChange={this.handleBirthday}
          textFieldStyle={{ width: "100%" }}
          value={this.state.data.birthday}
          locale="en-US"
          firstDayOfWeek={0}
        />
        <br />
        <SelectField
          id="organisation"
          floatingLabelText="School"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.organisations.id}
          onChange={this.handleOrgChange}
        >
          <MenuItem
            key={this.state.organisations.id}
            value={this.state.organisations.id}
            primaryText={this.state.organisations.name}
          />
        </SelectField>
        <br />
        <SelectField
          id="classes"
          floatingLabelText="Class"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.classes_id}
          onChange={this.handleClassChange}
        >
          {this.state.currentOrgClasses.map(rows => (
            <MenuItem key={rows.id} value={rows.id} primaryText={rows.name} />
          ))}
        </SelectField>
        <br />
        <TextField
          id="email"
          hintText="Email"
          fullWidth={true}
          value={this.state.data.email}
          onChange={this.handleChange}
        />
        <br />
        <InputMask
          mask="999-999-9999 ext. 999"
          fullWidth={true}
          value={this.state.data.phone}
          onChange={this.handleChange}
          id="phone" hintText="Phone"
          disabled={false}
        >
          {(inputProps) => <TextField {...inputProps} />}
        </InputMask>
        <br />
        <TextField
          id="address"
          hintText="Address"
          fullWidth={true}
          value={this.state.data.address}
          onChange={this.handleChange}
        />
        <br />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
            paddingTop: 20,
            paddingBottom: 20
          }}
        >
          <RaisedButton
            label="Save"
            primary
            onClick={this.saveData}
          />
          <RaisedButton
            label="Cancel"
            primary
            style={{ backgroundColor: "#ffd699" }}
            onClick={this.goPreviousPage}
          />
          <RaisedButton
            label="Delete"
            secondary
            onClick={this.handleOpen}
          />
        </div>

        <div>
          <ExpandedField
            title='Medical records'
            style={{ marginTop: '20px'}}
            onAddClick={() => this.props.history.push(appRoutes.teacherMedicalDoc(this.teacherId, TEACHER_DOC_MEDICAL_TYPE))}
            onAddTitle='Add Medical Record'
          >
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Expiration date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.docs_medical.map((row, index) => (
                  <TableRow
                    key={'medical-record-' + index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell scope="row">
                      <NavLink to={appRoutes.teacherMedicalDocEdit(this.teacherId, row.id, TEACHER_DOC_MEDICAL_TYPE)}>{row.title}</NavLink>
                    </TableCell>
                    <TableCell scope="row">{row.expiration_date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ExpandedField>

          <ExpandedField
            title='DOH paperwork'
            style={{ marginTop: '20px'}}
            onAddClick={() => this.props.history.push(appRoutes.teacherMedicalDoc(this.teacherId, TEACHER_DOC_DOH_TYPE))}
            onAddTitle='Add DOH paperwork'
          >
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Expiration date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.docs_doh.map((row, index) => (
                  <TableRow
                    key={'medical-record-' + index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell scope="row">
                      <NavLink to={appRoutes.teacherMedicalDocEdit(this.teacherId, row.id, TEACHER_DOC_DOH_TYPE)}>{row.title}</NavLink>
                    </TableCell>
                    <TableCell scope="row">{row.expiration_date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ExpandedField>

        </div>

        <Dialog
          actions={actions}
          modal={false}
          contentStyle={{ width: "20%", maxWidth: "none" }}
          open={this.state.opendialog}
          onRequestClose={this.handleClose}
        >
          Are you sure you want to delete the teacher?
        </Dialog>
      </div>
    );
  }
}

export default inject("Auth")(observer(EditTeacher))
