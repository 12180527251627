import React, { Component } from "react";
import axios from "axios";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";
import Checkbox from "material-ui/Checkbox";
import Grid from '@mui/material/Grid';

import LinearProgress from "material-ui/LinearProgress";
import { divProgressHide, divProgressShow } from "../../css/additional.js";

import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import { observer, inject } from "mobx-react";
import {dreamUser, dreamUsers, dreamUserWithRoles, getAllAdmins, getCurrentOrg} from "../../utils/operations.js";

import API from '../../utils/api';
import { toast } from 'react-toastify';
import { api2AdminAdditionalOrganizations } from '../../utils/operations';
import { phoneRoleToRoleId, roleIdToPhoneRole } from "../../utils/misc";
import {DREAM_ROLE_ADMIN, DREAM_ROLE_SUPERADMIN} from "../../utils/constants";

class EditAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admin: {
        name: "",
        organisation_id: "",
        email: "",
        password: "",
        show_finance: false,
        edit_finance: false,
        show_charts: false,
        show_future_invoices: false,
      },
      data: {
        phone: roleIdToPhoneRole(DREAM_ROLE_ADMIN),
      },
      role_id: DREAM_ROLE_ADMIN,
      admin_organisations: [],
      organisations: [],
      opendialog: false,
      texterrors: "",
      showProgress: false
    };
  }

  handleOpen = () => {
    this.setState({ opendialog: true });
  };

  handleClose = () => {
    this.setState({ showProgress: false });
    this.setState({ opendialog: false });
  };

  componentDidMount() {
    if (!this.props.Auth.can('superadmin')) return this.props.history.push("/children");
    this.loadData();
  }

  loadData = () => {
    const userId = this.props.match.params.id;

    let that = this;
    axios
      .all([
        API.get(dreamUserWithRoles(userId)),
        API.get(getAllAdmins + "/" + userId),
        API.get(getCurrentOrg),
        API.get(api2AdminAdditionalOrganizations(userId))
      ])
      .then(
        axios.spread(function(user, admin, orgs, adminOrgs) {
          const dfData = user.data;
          const administrator = admin.data;
          const organisations = orgs.data.resource;
          const admin_organisations = adminOrgs.data.organization_id;
          that.setState({ data: dfData, admin: {...administrator, password: ''} , organisations, showProgress: false, admin_organisations });
          that.setState({ role_id: phoneRoleToRoleId(dfData.phone) });
          that.forceUpdate();
        })
      );
  };

  deleteData = async () => {
    this.setState({ opendialog: false });
    try {
      await API.delete(dreamUsers + "/" + this.state.data.id)
      await API.delete(getAllAdmins + "/" + this.state.admin.id)
      toast.success('Administrator deleted successfully!')
      this.props.history.push("/admins");
    } catch (error) {
      toast.error(error)
    }
  };

  checkEmail = email => {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  checkInfo = () => {
    let errors = [];

    let admin = this.state.admin;
    if (admin.name === "") {
      errors.push(<span>* Name cannot be empty</span>);
      errors.push(<br />);
    }
    if (admin.organisation === "") {
      errors.push(<span>* Organisation cannot be empty</span>);
      errors.push(<br />);
    }

    if (!this.checkEmail(admin.email)) {
      errors.push(<span>* Email must be valid</span>);
      errors.push(<br />);
    }

    if (admin.password !== '' && admin.password.length < 6) {
      errors.push(<span>* Password length must be at least 6 symbols</span>);
      errors.push(<br />);
    }

    if (errors.length === 0) {
      return true;
    } else {
      this.setState({ texterrors: errors });
      return false;
    }
  };

  saveInfo = () => {
    if (this.checkInfo()) {
      this.saveData();
    } else {
      this.handleOpen();
    }
  };

  saveData = async () => {
    const user_id = this.state.data.id;
    const role_id = this.state.role_id;

    const userData = {
      name: this.state.admin.name,
      first_name: this.state.admin.name,
      last_name: this.state.admin.name,
      is_active: true,
      email: this.state.admin.email,
      username: this.state.admin.email,
      phone: roleIdToPhoneRole(role_id)
    };

    if (this.state.admin.password !== '') {
      userData['password'] = this.state.admin.password;
    }

    const userRoleData = [...this.state.data.user_to_app_to_role_by_user_id].map(roleItem => {
      return { ...roleItem, role_id:  role_id}
    })

    const adminData = {
      name: this.state.admin.name,
      organisation_id: this.state.admin.organisation_id,
      email: this.state.admin.email,
      show_finance: this.state.admin.show_finance,
      edit_finance: this.state.admin.edit_finance,
      show_charts: this.state.admin.show_charts,
      show_future_invoices: this.state.admin.show_future_invoices,
    };

    const admin_organizations = this.state.admin_organisations.filter(id => id !== this.state.admin.organisation_id)

    try {
      await API.post(api2AdminAdditionalOrganizations(this.state.admin.id), admin_organizations)
      await API.patch(getAllAdmins + "/" + this.state.admin.id, adminData)
      await API.patch(dreamUser(user_id), userData)
      await API.put(dreamUserWithRoles(user_id), {user_to_app_to_role_by_user_id: userRoleData})

      toast.success('Administrator edited successfully!')
      setTimeout(this.props.history.push("/admins"), 1000);
    } catch (error) {
      let issues = error.response.data._issues;
      let textErrors = "";

      for (const key in issues) {
        textErrors += key + ": " + issues[key] + "\n";
      }
      this.setState({ texterrors: textErrors });
      this.handleOpen();
    }
  };

  handleChange = event => {
    const admin = this.state.admin;
    admin[event.target.id] = event.target.value;
    this.setState({ admin });
  };

  handleOrgChange = (event, index, value) => {
    let admin = this.state.admin;
    admin.organisation_id = value;
    this.setState({ admin });
  };

  handleRoleChange = (event, index, value) => {
    if (this.props.Auth.role !== 'superadmin') {
      return
    }

    const phoneRole = roleIdToPhoneRole(value)
    let data = this.state.data
    data.phone = phoneRole
    this.setState({ data })
    this.setState({ role_id: value })
  };

  goPreviousPage = () => {
    const goUrl = "/admins";
    setTimeout(this.props.history.push(goUrl), 1000);
  };

  updateShowFinance = () => {
    if (this.props.Auth.role !== 'superadmin') {
      return
    }
    let admin = this.state.admin;
    admin.show_finance = !admin.show_finance;
    this.setState({ admin });
  };

  updateEditFinance = () => {
    if (this.props.Auth.role !== 'superadmin') {
      return
    }
    let admin = this.state.admin;
    admin.edit_finance = !admin.edit_finance;
    this.setState({ admin });
  };

  handlePermissionsToggle = (permissionName) => {
    if (this.props.Auth.role !== 'superadmin') {
      return
    }

    let admin = this.state.admin;
    admin[permissionName] = !admin[permissionName];
    this.setState({ admin });
  }

  render() {
    const actions = [
      <FlatButton key='btn-cancell' label="Cancel" primary={true} onClick={this.handleClose} />,
      <FlatButton key='btn-ok' label="OK" primary={true} onClick={this.deleteData} />
    ];
    return (
      <div style={{ width: 500, margin: "auto" }}>
        <div
          style={this.state.showProgress ? divProgressShow : divProgressHide}
        >
          <LinearProgress mode="indeterminate" />
        </div>
        <br />
        <TextField
          id="name"
          hintText="Name"
          errorText="This field is required"
          floatingLabelText="Name"
          fullWidth={true}
          value={this.state.admin.name}
          onChange={this.handleChange}
        />
        <br />
        <SelectField
          id="role"
          floatingLabelText="Role"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.role_id}
          onChange={this.handleRoleChange}
        >
          <MenuItem key={'admin'} value={DREAM_ROLE_ADMIN} primaryText={'Admin'} />
          <MenuItem key={'superadmin'} value={DREAM_ROLE_SUPERADMIN} primaryText={'Superadmin'} />
        </SelectField>
        <br />
        <SelectField
          id="organisation"
          floatingLabelText="School"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.admin.organisation_id}
          onChange={this.handleOrgChange}
        >
          {this.state.organisations.map(row => (
            <MenuItem key={row.id} value={row.id} primaryText={row.name} />
          ))}
        </SelectField>
        <br />
        <TextField
          id="email"
          hintText="Email"
          floatingLabelText="Email"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.admin.email}
          onChange={this.handleChange}
        />
        <br />
        <TextField
          type={"password"}
          id="password"
          hintText="Password"
          floatingLabelText="Password"
          fullWidth={true}
          value={this.state.admin.password}
          onChange={this.handleChange}
        />
        <br />
        {this.state.role_id !== DREAM_ROLE_SUPERADMIN && <Grid container spacing={3} style={{ marginTop: '20px'}} >
          <Grid item xs={6}>
            <Checkbox
              label="Show Finance Info"
              checked={this.state.admin.show_finance}
              onCheck={() => this.handlePermissionsToggle('show_finance')}
            />
            <br />
            <Checkbox
              label="Edit Finance"
              checked={this.state.admin.edit_finance}
              onCheck={() => this.handlePermissionsToggle('edit_finance')}
            />
            <br />
          </Grid>
          <Grid item xs={6}>
            <Checkbox
              label="View Future Invoices"
              checked={this.state.admin.show_future_invoices}
              onCheck={() => this.handlePermissionsToggle('show_future_invoices')}
            />
            <br />
            <Checkbox
              label="View Financial Charts"
              checked={this.state.admin.show_charts}
              onCheck={() => this.handlePermissionsToggle('show_charts')}
            />
            <br />
          </Grid>
        </Grid>}
        <SelectField
          multiple={true}
          id="organisation"
          floatingLabelText="Additional schools"
          fullWidth={true}
          value={this.state.admin_organisations}
          onChange={(event, index, value) => { this.setState({admin_organisations: value})}}
        >
          {this.state.organisations.filter(o => o.id !== this.state.admin.organisation_id).map(row => (
            <MenuItem key={row.id} value={row.id} primaryText={row.name} />
          ))}
        </SelectField>
        <br />
        <div
          style={{
            margin: "auto",
            width: 300,
            paddingTop: 20,
            paddingBottom: 20
          }}
        >
          <RaisedButton
            label="Save"
            primary={true}
            style={{ marginRight: 10 }}
            onClick={this.saveInfo}
          />
          <RaisedButton
            label="Cancel"
            primary={true}
            style={{ marginLeft: 10, backgroundColor: "#ffd699" }}
            onClick={this.goPreviousPage}
          />
          <RaisedButton
            label="Delete"
            secondary={true}
            style={{ marginLeft: 10 }}
            onClick={this.handleOpen}
          />
          <Dialog
            actions={actions}
            modal={false}
            contentStyle={{ width: "20%", maxWidth: "none" }}
            open={this.state.opendialog}
            onRequestClose={this.handleClose}
          >
            Delete administrator?
          </Dialog>
        </div>
      </div>
    );
  }
}

export default inject("Auth")(observer(EditAdmin))
