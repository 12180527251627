import React, { Component } from "react";
import axios from "axios";

import Paper from "material-ui/Paper";
import Divider from "material-ui/Divider";

import CircularProgress from "material-ui/CircularProgress";
import { divProgressHide, divProgressShow } from "../css/additional.js";
import { observer, inject } from "mobx-react";

import { uploadAPI, upload } from "../utils/operations.js";

class ImportExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProgress: false,
      showError: false,
      fileUploaded: false
    };
  }

  onFileLoad = (e, file) => {
    this.setState({ showProgress: true });
    let rdata = null;

    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = function(readerEvt) {
      rdata = btoa(readerEvt.target.result);

      let data = {
        resource: [
          {
            name: file.name,
            type: "file",
            is_base64: true,
            content: rdata
          }
        ]
      };

      axios({
        method: "post",
        url: upload,
        data: data,
        headers: {
          "X-DreamFactory-API-Key": uploadAPI,
          "X-DreamFactory-Session-Token": localStorage.getItem("token")
        }
      });
    };

    setTimeout(() => {
      this.setState({
        showProgress: false
      });
    }, 3000);

    setTimeout(() => {
      this.setState({
        showError: true
      });
    }, 3300);

    this.setState({ fileUploaded: true });
  };

  render() {
    return (
      <Paper zDepth={1} style={{ width: 400, margin: "auto" }}>
        <div style={{ paddingLeft: 20 }}>
          <span>Import data (xlsx-file):</span>
          {/*<Upload key={"sdsdsd"} onFileLoad={this.onFileLoad}  />*/}
        </div>
        <br />
        <div style={{ paddingLeft: 20 }}>
          <span style={{ paddingLeft: 20, fontWeight: 800 }}>
            File Uploaded: false
          </span>
        </div>
        <br />
        <div
          style={this.state.showProgress ? divProgressShow : divProgressHide}
        >
          <CircularProgress size={80} thickness={7} />
        </div>
        <div style={this.state.showError ? divProgressShow : divProgressHide}>
          <span style={{ paddingLeft: 20 }}>Error: Columns does not match</span>
        </div>
        <Divider />
        <br />
        <br />
      </Paper>
    );
  }
}

export default inject("Auth")(observer(ImportExport))
