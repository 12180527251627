import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import moment from "moment";
import API from '../utils/api'

import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { getAllChildren } from "../utils/operations";
import ReactToPrint from "react-to-print";
import FlatButton from "material-ui/FlatButton";

import { blue500 } from "material-ui/styles/colors";

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from "@mui/material";

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      month: moment().format("YYYY-MM-DD"),
      modifiers: {},
      modifiersStyles: {},
      allChildren: [],
      children: [],
      orgId: this.props.orgId || 1
    };
  }

  componentDidMount() {
    this.loadData(this.props.month)
  }

  monthChanged = val => {
    let month = moment(val).format("YYYY-MM-DD");
    this.setCurrentMonthChildren(month);
  };

  loadData = (givenMonth = null) => {
    let orgId = this.state.orgId;
    let url =
      getAllChildren +
      `?fields=id%2Cfirstname%2Clastname%2Cbirthday&filter=organisation_id%3D${orgId}&order=birthday%20ASC`;

    API.get(url)
      .then(res => {
        this.setState({ allChildren: res.data.resource});
        this.setCurrentMonthChildren(givenMonth)
      });
  };

  setCurrentMonthChildren = (givenMonth) => {
    let month = givenMonth ? givenMonth : this.state.month ;
    let modifiers = {};
    let modifiersStyles = {};
    let children = [];
    let today = new Date();

    for (let child of this.state.allChildren) {
      if (
        child.birthday &&
        moment(child.birthday).format("MM") === moment(month).format("MM")
      ) {
        children.push({
          childId: child.id,
          firstname: child.firstname,
          lastname: child.lastname,
          birthDate: moment(child.birthday).format("D MMM, YYYY"),
          birthDay: moment(child.birthday).format("D"),
        });

        let curdate = today.getFullYear() + "-" + moment(child.birthday).format("MM-DD");
        let curday= moment(child.birthday).format("D");

        modifiers[curday] = new Date(curdate);

        modifiersStyles[curday] = {
          color: "white",
          backgroundColor: blue500
        };
      }
    }

    children.sort((a, b) => { return a.birthDay - b.birthDay})

    this.setState({ children, modifiers, modifiersStyles });
  }

  render() {
    return (
      <div style={{ maxWidth: 600, margin: 20 }}>
        <DayPicker
          modifiers={this.state.modifiers}
          modifiersStyles={this.state.modifiersStyles}
          canChangeMonth={true}
          showOutsideDays={true}
          onMonthChange={val => this.monthChanged(val)}
        />
        {this.state.children && (
          <Table stickyHeader={true}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 30 }}>#</TableCell>
                <TableCell>First name</TableCell>
                <TableCell>Last name</TableCell>
                <TableCell>Birthday</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.children.map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={{ width: 30 }}>
                    {index + 1}
                  </TableCell>
                  <TableCell>{row.firstname}</TableCell>
                  <TableCell>{row.lastname}</TableCell>
                  <TableCell>{row.birthDate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    );
  }
}

class DOB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgId: props.Auth.orgId,
    };
  }

  componentDidMount() {
    this.props.Auth.snackBar = false;

  }

  render() {
    return (
      <div style={{ margin: "auto" }}>
        <div>
          <ReactToPrint
            trigger={() => (
              <FlatButton
                label="Print"
                style={{ alignSelf: "center" }}
                primary={true}
                onClick={() => {}}
              />
            )}
            content={() => this.componentRef}
          />
          {this.props.Auth.orgId && (
            <Calendar
              ref={el => (this.componentRef = el)}
              orgId={this.state.orgId}
            />
          )}
        </div>
      </div>
    );
  }
}

export default inject("Auth")(observer(DOB))
