import withStyles from "@mui/styles/withStyles";
import {TableRow} from "@mui/material";

export default withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(142, 195, 242, 0.4)',
    },
    '&:hover': {
      backgroundColor: '#f5f5f5',
    }
  },
}))(TableRow);
