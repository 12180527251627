import React from "react";
import {
  DREAM_APP_FILES,
  DREAM_APP_MEDIA,
  DREAM_APP_MENU,
  DREAM_APP_STS,
  DREAM_ROLE_ADMIN,
  DREAM_ROLE_SUPERADMIN
} from "./constants";
import moment from "moment";

export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

export const moneyFormatter = value => {
  value = typeof value === "number" ? value : parseFloat(value);
  value = isNaN(value) ? 0 : value
  return value.toLocaleString("en-US", {
    style: "currency",
    currencyDisplay: "symbol",
    minimumFractionDigits: 2,
    currency: "USD"
  });
}

export const numberFormatter = (value) => {
  value = typeof value === 'number' ? value : parseFloat(value)
  return value.toLocaleString('en-US')
}

export const monthFormatter = (value) => {
  return moment(value).format('MM/YYYY')
}

export const dateFormatter = (value) => {
  return moment(value).format('MM/DD/YYYY')
}

export const dateTimeFormatter = (value) => {
  return moment(value).format('MM/DD/YYYY h:mm A')
}

export const checkParentHasPaymentRegistration = parentData => {
  return parentData.hasOwnProperty("stripe_customer") && parentData.stripe_customer;
}

export const renderTransactionType = (type) => {
  const statuses = {
    depo: 'Cash/Check',
    nextmonthpayment: 'Next Month Payment',
    futuredepo: 'Future Deposit',
    nextyeardepo: 'Next Year Deposit',
    stripe: 'Stripe',
  }

  const statusesClasses = {
  }
  const statusName = statuses.hasOwnProperty(type) ? statuses[type] : type
  const statusClass = statusesClasses.hasOwnProperty(type) ? statusesClasses[type] : ''

  return <span className={statusClass}>{statusName}</span>
}

export const checkEmail = email => {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const base64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const roleIdToPhoneRole = (role_id) => {
  switch (role_id) {
    case DREAM_ROLE_SUPERADMIN:
      return 'superadmin'

    case DREAM_ROLE_ADMIN:
    default:
      return 'admin'
  }
}

export const phoneRoleToRoleId = (phoneRole) => {
  switch (phoneRole) {
    case 'superadmin':
      return DREAM_ROLE_SUPERADMIN

    case 'admin':
    default:
      return DREAM_ROLE_ADMIN
  }
}

export const getUserRolesObject = (admin_id, role_id) => {
  return {
    user_to_app_to_role_by_user_id: [
      {
        app_id: DREAM_APP_STS,
        role_id: role_id,
        // user_id: admin_id
      },
      {
        app_id: DREAM_APP_MEDIA,
        role_id: role_id,
        // user_id: admin_id
      },
      {
        app_id: DREAM_APP_FILES,
        role_id: role_id,
        // user_id: admin_id
      },
      {
        app_id: DREAM_APP_MENU,
        role_id: role_id,
        // user_id: admin_id
      }
    ]
  }
}
