import React, { Component } from "react";

import { APIKeyMenu, menu } from "../utils/operations.js";
import { observer, inject } from "mobx-react";

import RaisedButton from "material-ui/RaisedButton";
import {toast} from "react-toastify";

class MealMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      opendialog: false,
      error: "",
      send: false,
      snackBar: false
    };
  }

  async componentDidMount() {
    if (!this.props.Auth.can('mealmenu')) return this.props.history.push("/children");
    this.props.Auth.snackBar = false;
  }

  uploadMenu = async () => {
    let orgId = this.props.Auth.orgId;
    let formData = new FormData();
    formData.append(`menu${orgId}.pdf`, this.state.file, `menu${orgId}.pdf`);

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        "X-DreamFactory-API-Key": APIKeyMenu,
        "X-DreamFactory-Session-Token": localStorage.getItem("token")
      },
      body: formData
    };

    await fetch(`${menu}/menu${orgId}.pdf`, config)
      .then(() => {
        toast.success('Menu saved!')
      })
      .catch(() => {
        toast.error('Error saving menu!')
      });
  };

  selectFile = e => {
    let file = e.target.files[0];
    let filename = file.name;

    if (!filename.endsWith(".pdf")) return;

    this.setState({ file });
  };

  render() {
    return (
      <div style={{ width: 500, margin: "auto" }}>
        <div style={{ flex: 1 }}>
          <span style={{ color: "gray" }}>Select pdf file with meal menu:</span>
          <br />
          <br />
          <input onChange={this.selectFile} id="file" type="file" />
          <br />
          <br />
          <RaisedButton
            label="Upload Meal Menu"
            disabled={!this.state.file}
            primary={true}
            onClick={this.uploadMenu}
          />
          <br />
        </div>
      </div>
    );
  }
}

export default inject("Auth")(observer(MealMenu))
