import React, {useEffect, useMemo, useState} from "react";
import Divider from "material-ui/Divider";
import LinearProgress from "material-ui/LinearProgress";
import { divProgressHide, divProgressShow } from "../../css/additional.js";

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from "@mui/material";

import {
  api2Children, api2ClassesByOrganizationId
} from "../../utils/operations.js";
import { observer, inject } from "mobx-react";

import API from '../../utils/api';
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import StyledTableRow from "../../components/StyledTableRow";

const ContactList = props => {
  const [selectedClass, setSelectedClass] = useState('');
  const [classes, setClasses] = useState([]);
  const [data, setData] = useState([]);
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    let orgId = props.Auth.orgId;
    loadData(orgId);
  }, [])

  const loadData = async (orgId) => {
    setShowProgress(true)

    const [
      classes,
      children,
    ] = await Promise.all([
      API.get(api2ClassesByOrganizationId(orgId) ),
      API.get(api2Children, {
        params: {
          children_page: true,
          orderBy: 'firstname',
          with: 'parents',
          organisation_id: orgId
        }
      })
    ]);

    setData(children.data)
    setClasses(classes.data.data)
    setShowProgress(false)
  };

  const clearFilter = () => {
    setSelectedClass('')
  };

  const classNameById = useMemo(() => {
    return Object.fromEntries(classes.map((item) => [item.id, item.name]));
  }, [classes])

  const filteredData = useMemo(() => {
    let filtered = data

    if (selectedClass) {
      filtered = filtered.filter(item => item.classes_id === selectedClass)
    }

    return filtered
  }, [data, selectedClass])

  return (
    <div className="contacts" style={{ margin: "auto" }}>

      <div className="contacts_filter no-print">
        <FormControl sx={{ minWidth: '200px'}}>
          <InputLabel>Filter by Class:</InputLabel>
          <Select
            name="class"
            value={selectedClass}
            onChange={event => setSelectedClass(event.target.value)}
            size="small"
            variant="standard"
          >
            {classes.map(row => (
              <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
            ))}
          </Select>
        </FormControl>


        <Button variant="outlined" onClick={clearFilter}>Clear Filters</Button>
        <Button variant="outlined" onClick={() => window.print()}>Print Contact List</Button>
      </div>


      <Divider style={{marginTop: 10}} />

      <div style={{ overflowX: 'scroll'}} id="contact-list-container">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Child</TableCell>
              <TableCell>DOB</TableCell>
              <TableCell>Parent</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Class</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((childItem, index) => {
              const parentFirst = childItem.parents[0]
              const parentSecond = childItem.parents[1]
              return (
                <StyledTableRow key={childItem.id} userID={childItem.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{childItem.firstname} {childItem.lastname}</TableCell>
                  <TableCell>{childItem.birthday}</TableCell>
                  <TableCell>
                    {parentFirst?.firstname} {parentFirst?.lastname}
                    {parentSecond && <><br /><br />{parentSecond.firstname} {parentSecond.lastname}</>}
                  </TableCell>
                  <TableCell>
                    {parentFirst?.email}
                    {parentSecond && <><br /><br />{parentSecond.email}</>}
                  </TableCell>
                  <TableCell>
                    {parentFirst?.phone}
                    {parentSecond && <><br /><br />{parentSecond.phone}</>}
                  </TableCell>
                  <TableCell>{classNameById[childItem.classes_id]}</TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div
        style={showProgress ? divProgressShow : divProgressHide}
      >
        <LinearProgress mode="indeterminate" />
      </div>
    </div>
  );

}

export default inject("Auth")(observer(ContactList))
