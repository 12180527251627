import React, { Component } from 'react';
import Spinner from "react-loader-spinner";

class Loader extends Component {
  render() {
    return (
      <div>
        {this.props.loaded && this.props.children}
        {!this.props.loaded && <div style={{display: 'flex', justifyContent: 'center', padding: '40px'}}>
          <Spinner type="TailSpin" color="#00BFFF" height={80} width={80} />
        </div>}
      </div>
    );
  }
}

export default Loader;
