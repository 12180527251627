import React, {useEffect, useState} from "react";

import { observer, inject } from "mobx-react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {toast} from "react-toastify";
import {
	api2ChildEditVaccineRecord,
	api2ChildStoreVaccineRecord, api2VaccineRecords
} from "../../utils/operations";

import API from '../../utils/api';
import Loader from "../../components/Loader";
import FormControl from "@mui/material/FormControl";

const VaccineRecord = (props) => {
	const childVaccineRecordId = props.match.params.childVaccineRecordId

	const [loading, setLoading] = useState(false)
	const [vaccineData, setVaccineData] = useState([])

	const [vaccineRecordId, setVaccineRecordId] = useState(props.match.params.vaccineRecordId ?? null)
	const [dosesCompleted, setDosesCompleted] = useState(0)
	const [expirationDate, setExpirationDate] = useState(null)

	useEffect(() => {
		if (childVaccineRecordId) {
			loadChildVaccineRecord()
		}

		loadVaccineRecords()
	}, [])

	const loadVaccineRecords = async () => {
		setLoading(true)
		try {
			const response = await API.get(api2VaccineRecords)
			setVaccineData(response.data.data)
		} catch (e) {
			toast.error('Error on data loading!');
		}
		setLoading(false)
	}

	const loadChildVaccineRecord = async () => {
		const childId = props.match.params.id
		setLoading(true)
		try {
			const result = await API.get(api2ChildEditVaccineRecord(childId, childVaccineRecordId))
			const expirationDate = result.data.data.expiration_date
			setExpirationDate(expirationDate ? new Date(result.data.data.expiration_date) : null)
			setDosesCompleted(result.data.data.doses_completed)
			setVaccineRecordId(result.data.data.vaccine_record_id)
		} catch (e) {
			toast.error('Something went wrong!')
		}
		setLoading(false)
	}

	const deleteVaccineRecord = async () => {
		if (!confirm('Are you sure ? Want to delete Vaccine Record ?')) {
			return
		}

		const childId = props.match.params.id
		const goUrl = "/editchild/" + childId;
		const vaccineRecordId = props.match.params.childVaccineRecordId

		setLoading(true)
		try {
			await API.delete(api2ChildEditVaccineRecord(childId, vaccineRecordId))
			toast.success('Vaccine Record successfully deleted!')

			setTimeout(props.history.push(goUrl), 1000);
		} catch (e) {
			toast.error('Something went wrong!')
		}
		setLoading(false)
	}

	const goPreviousPage = () => {
		const childId = props.match.params.id
		const goUrl = "/editchild/" + childId;
		setTimeout(props.history.push(goUrl), 1000);
	};

	const saveVaccineRecord = async (event) => {
		event.preventDefault()

		const childId = props.match.params.id
		const goUrl = "/editchild/" + props.Auth.child_id;

		try {
			await API.post(api2ChildStoreVaccineRecord(childId), {
				id: childVaccineRecordId,
				vaccine_record_id: vaccineRecordId,
				doses_completed: dosesCompleted,
				expiration_date: expirationDate?.toDateString(),
			})

			toast.success('Vaccine Record successfully saved!')
			setTimeout(props.history.push(goUrl), 1000);
		} catch (e) {
			toast.error('Something went wrong!')
		}
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<div style={{ maxWidth: 500, margin: "auto" }}>
				<Loader loaded={!loading}>
					<h3>{childVaccineRecordId ? 'Edit Vaccine Record' : 'New Child\'s Vaccine Record'}</h3>
					<form onSubmit={saveVaccineRecord}>
						<Stack gap="20px">
							<FormControl fullWidth>
								<Select
									label="Vaccine Record"
									name="program"
									value={vaccineRecordId}
									onChange={(event) => setVaccineRecordId(event.target.value)}
									size="small"
									variant="standard"
									required
								>
									{vaccineData.map(row => (
										<MenuItem key={'program-' + row.id} value={row.id} >{row.title}</MenuItem>
									))}
								</Select>
							</FormControl>

							<TextField
								label='Completed Doses'
								value={dosesCompleted}
								onChange={(event) => setDosesCompleted(event.target.value)}
								size="small"
								variant="standard"
								type="number"
								step="1"
							/>

							<DatePicker
								slotProps={{ textField: { variant: 'standard' }}}
								views={['year', 'month', 'day']}
								label="Expiration date"
								value={expirationDate}
								onChange={setExpirationDate}
							/>
						</Stack>

						<Stack direction="row" sx={{ padding: "20px 0"}} gap="20px">
							<Button variant="contained" type="submit">Save</Button>
							<Button variant="outlined" onClick={goPreviousPage}>Cancel</Button>
							{childVaccineRecordId && <Button variant="contained" color="error" onClick={deleteVaccineRecord}>Delete</Button>}
						</Stack>
					</form>
				</Loader>
			</div>
		</LocalizationProvider>
	)
}


export default inject("Auth")(observer(VaccineRecord))
