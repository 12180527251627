import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";
import API from '../../utils/api';

import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import { observer, inject } from "mobx-react";
import { getCurrentOrg, getAllClasses } from "../../utils/operations.js";
import { toast } from 'react-toastify';

class NewClasses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        organisation_id: "",
        name: ""
      },
      organisations: [], // Список организаций
      opendialog: false, // Диалог с ошибками
      texterrors: "" // Список ошибок
    };
  }

  // Получение location для перехода на другие страницы
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  // Получение роли для создания нового пользователя
  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const data = this.state.data;
    let orgId = this.props.Auth.orgId;

    API.get(getCurrentOrg + orgId).then(res => {
      data.name = "";
      data.organisation_id = res.data.id;
      this.setState({ organisations: [res.data] });
      this.setState({ data });
    });
  };

  // Запись данных в базу
  saveData = () => {
    this.setState({ showProgress: true });

    let data = {
      resource: [
        {
          name: this.state.data.name,
          organisation_id: this.state.data.organisation_id
        }
      ]
    };

    API.post(getAllClasses, data).then(res => {
      toast.success('Class successfully added!')
      this.props.history.push("/classes");
    })
    .catch(function(error) {
      console.log(error);
    });
  };

  // Открыть диалог
  handleOpen = () => {
    this.setState({ opendialog: true });
  };

  // Закрыть диалог
  handleClose = () => {
    this.setState({ opendialog: false });
  };

  // Записать Select в state
  handleOrgChange = (event, index, value) => {
    const data = this.state.data;
    data.organisation_id = value;
    this.setState({ data });
  };

  // Записать данные в state
  handleChange = event => {
    const data = this.state.data;
    data[event.target.id] = event.target.value;
    this.setState({ data });
  };

  // Перейти на предыдущую страницу
  goPreviousPage = () => {
    this.props.history.goBack();
  };

  render() {
    const actions = [
      <FlatButton label="Cancel" primary={true} onClick={this.handleClose} />
    ];
    return (
      <div style={{ maxWidth: 500, margin: "auto" }}>
        <p>Class</p>
        <TextField
          id="name"
          hintText="Class Name"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.name}
          onChange={this.handleChange}
        />
        <br />
        <SelectField
          id="organisation"
          floatingLabelText="School"
          errorText="This field is required"
          fullWidth={true}
          value={this.state.data.organisation_id}
          onChange={this.handleOrgChange}
        >
          {this.state.organisations.map(row => (
            <MenuItem key={row.id} value={row.id} primaryText={row.name} />
          ))}
        </SelectField>
        <br />
        <div
          style={{
            display: "flex",
            gap: 10,
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 20,
            paddingBottom: 20
          }}
        >
          <RaisedButton
            label="Save"
            primary={true}
            onClick={this.saveData}
          />
          <RaisedButton
            label="Cancel"
            secondary={true}
            onClick={this.goPreviousPage}
          />
        </div>

        <Dialog
          title="Form errors:"
          actions={actions}
          modal={false}
          contentStyle={{ width: "40%", maxWidth: "none" }}
          open={this.state.opendialog}
          onRequestClose={this.handleClose}
        >
          <div>
            {// Вывести список ошибок
            this.state.texterrors.split("\n").map((i, index) => {
              return <div key={index}>{i}</div>;
            })}
          </div>
        </Dialog>
      </div>
    );
  }
}

export default inject("Auth")(observer(NewClasses))
