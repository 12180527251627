import React, { Component } from "react";
import FlatButton from "material-ui/FlatButton";
import DropDownMenu from "material-ui/DropDownMenu";
import MenuItem from "material-ui/MenuItem";
import { observer, inject } from "mobx-react";

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from "@mui/material";

import API from '../../utils/api';

import TextField from "material-ui/TextField";
import get from "lodash/get";
import { api2ChildGetFutureInvoice, api2FutureInvoices } from '../../utils/operations';
import { toast } from 'react-toastify';
import { moneyFormatter } from '../../utils/misc';
import StyledTableRow from "../../components/StyledTableRow";

const rows = [];

class FutureInvoices extends Component {
  constructor(props) {
    super(props);

    const minDate = new Date();
    const maxDate = new Date();
    minDate.setHours(0, 0, 0, 0);
    maxDate.setHours(0, 0, 0, 0);

    this.state = {
      data: [],
      rowdata: [],
      modifiedData: [],
      immutableData: [],
      filteredItems: [],
      classes: [],
      rows: rows,
      children: [],
      searchText: "",
      errorText: "",
      autoOk: true,
      disableYearSelection: false,
      pricePaid: 0,
      pricePartialPaid: 0,
      priceOverdue: 0,
      orgId: 0,
      search: "",
      searchEmail: "",
      amount: 0,
      paid: 0,
      datefilter: false,
      snackBar: false,
      childDict: {},
      minDate: new Date("08/28/2017"),
      maxDate: new Date("08/28/2017"),
      showClasses: false,
      kind: "all",
      name: [],
      showChild: null,
      searchAuto: "",
      pdfFile: this.props.Auth.pdfFile,
      totalSeptemberDeposits: 0,
      totalDeposits: 0,
      totalAmount: 0,
      totalCredits: 0,
      totalExpected: 0,
      offsetToFix: 0,
      headerFixed: false,
    };

    this.monthNumber = (new Date()).getMonth()
  }

  componentDidMount() {
    if (!this.props.Auth.can('allfeatureinvoices')) return this.props.history.push("/children");
    this.props.Auth.snackBar = false;
    let orgId = this.props.Auth.orgId;
    this.setState({ orgId });
    this.loadData(orgId);

    const appBar = document.querySelector('#app-bar')
    const appBarrHeight = appBar ? appBar.clientHeight : 0
    const pageHeader = document.querySelector('#page-header')
    const pageHeaderHeight = pageHeader ? pageHeader.clientHeight : 0
    this.setState({ offsetToFix: appBarrHeight + pageHeaderHeight});

    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const scrollTop = window.pageYOffset
    const tableHeader = document.querySelector('#table-header')
    if (scrollTop > this.state.offsetToFix) {
      this.setState({ headerFixed: true });
      tableHeader.style.top = scrollTop - 21 - this.state.offsetToFix + 'px'
    } else {
      this.setState({ headerFixed: false });
      tableHeader.style.top = 0
    }
  }

  loadData = async orgId => {
    let data = [];
    let totalAmount = 0;
    let totalCredits = 0;
    let totalExpected = 0;
    let totalSeptemberDeposits = 0;
    let totalDeposits = 0;

    const res = await API.get(api2FutureInvoices, {
        params: {
          organisation_id: orgId,
          with: 'classes,nextmonthpayment,futurePlans',
          withCredits: true
        }
    });

    for (let item of res.data) {
      let childFirstName = item.firstname ? item.firstname : "";
      let childLastName = item.lastname ? item.lastname : "";
      let childClass = item.classes && item.classes.name ? item.classes.name : "";
      let childId = item.id ? item.id : null;
      let amount = 0;
      let nextmonthpayment = 0;

      amount = item.future_plans.length ? item.future_plans.map(p => p.price).reduce((a, b) => a + b) : 0;

      if (item.nextmonthpayment && item.nextmonthpayment.length > 0) {
        nextmonthpayment += item.nextmonthpayment[0].amount;
      }

      totalSeptemberDeposits += item.nextyeardepo;

      totalCredits += item.credits;
      totalDeposits += item.futuredepo;
      totalDeposits += nextmonthpayment;

      totalAmount += amount;
      totalAmount -= item.credits;

      let expected = amount - item.futuredepo - nextmonthpayment - (this.monthNumber === 8 ? item.nextyeardepo : 0);
      totalExpected += expected;

      data.push({
        childFirstName: childFirstName,
        childLastName: childLastName,
        childClass: childClass,
        childId: childId,
        amount: amount,
        credits: item.credits,
        expected: expected,
        futuredepo: item.futuredepo,
        nextyeardepo: item.nextyeardepo,
        nextmonthpayment: nextmonthpayment,
      });
    }

    this.setState({
      data,
      totalAmount,
      totalExpected,
      totalSeptemberDeposits,
      totalDeposits,
      totalCredits,
      filteredItems: data
    });
  };

  switchOrg = () => {
    switch (this.props.Auth.role) {
      case "teacher":
        return <div />;
      case "admin":
        return <div />;
      case "superadmin":
        return (
          <div className="invoicesSelectSchool">
            <div className="invoicesSelectSchool_label">Select default school:</div>
            <DropDownMenu value={this.props.Auth.orgId} onChange={this.handleChandeOrg}>
              {Object.values(this.props.Auth.allOrgs).map((org) => {
                return <MenuItem key={org.id} value={org.id.toString()} primaryText={org.name} />
              })}
            </DropDownMenu>
          </div>
        );
      default:
        return <div />;
    }
  };

  handleChandeOrg = async (event, index, value) => {
    this.props.Auth.setOrgId(value)
    this.props.Auth.getOrgName()
    await this.loadData(value)
  };

  showFutureInvoice = childId => {
    this.viewFutureInvoice(childId);
  };

  viewFutureInvoice = async (childId) => {
    try {
      const result = await API.get(api2ChildGetFutureInvoice(childId))
      setTimeout(() => {
        window.open(result.data.file)
      }, 100);
    } catch (e) {
      toast.error("Something wrong. Please try again.")
    }
  };

  handleChangeSearch = event => {
    let searchString = event.target.value.toLowerCase();

    this.setState({ search: event.target.value });

    let filteredItems = this.state.data;

    filteredItems = filteredItems.filter(item => {
      if (
        get(item, "childLastName", "")
          .toString()
          .toLowerCase()
          .includes(searchString) ||
        get(item, "childFirstName", "")
          .toString()
          .toLowerCase()
          .includes(searchString)
      ) {
        return true;
      }
      return false;
    });
    this.setState({ filteredItems });
  };

  clearFilter = async () => {
    this.setState({
      filteredItems: this.state.data,
      search: ""
    });
  };

  render() {
    return (
      <div style={{ margin: "auto" }}>
        <div id="page-header">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {this.switchOrg()}
            <div>
              <table>
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>Total Amounts:</td>
                    <td>{moneyFormatter(this.state.totalAmount)}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>Total Credits:</td>
                    <td style={{paddingRight: '10px'}}>{moneyFormatter(this.state.totalCredits)}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>Total Deposits:</td>
                    <td>{moneyFormatter(this.state.totalDeposits)}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>September Deposits:</td>
                    <td>{moneyFormatter(this.state.totalSeptemberDeposits)}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>Total Expected:</td>
                    <td>{moneyFormatter(this.state.totalExpected)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <br />
          <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
            <TextField
              hintText="Name"
              value={this.state.search}
              onChange={this.handleChangeSearch}
              style={{ marginLeft: 20 }}
            />

            <FlatButton
              label="Clear Filter"
              style={{ marginLeft: 10 }}
              primary={true}
              onClick={this.clearFilter}
            />
          </div>
        </div>
        <div style={{ overflowX: "scroll"}}>
          <Table>
            <TableHead id="table-header-container">
              <TableRow id="table-header" className={this.state.headerFixed ? 'fixed' : ''}>
                <TableCell>#</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Class</TableCell>
                <TableCell>Amounts</TableCell>
                <TableCell>Credits</TableCell>
                <TableCell>Deposits</TableCell>
                <TableCell>September Deposits</TableCell>
                <TableCell>Expected Payment</TableCell>
                <TableCell width={150}>Future Invoice</TableCell>
              </TableRow>
            </TableHead>
          <TableBody>
            {this.state.filteredItems.map((row, index) => (
              <StyledTableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row.childFirstName}</TableCell>
                <TableCell>{row.childLastName}</TableCell>
                <TableCell>{row.childClass}</TableCell>
                <TableCell>{moneyFormatter(row.amount)}</TableCell>
                <TableCell>{moneyFormatter(row.credits)}</TableCell>
                <TableCell>{moneyFormatter(row.futuredepo + row.nextmonthpayment)}</TableCell>
                <TableCell>{moneyFormatter(row.nextyeardepo)}</TableCell>
                <TableCell>{moneyFormatter(row.expected)}</TableCell>

                <TableCell>
                  <FlatButton
                    label="View"
                    style={{ alignSelf: "center" }}
                    primary={true}
                    onClick={() => this.showFutureInvoice(row.childId)}
                  />
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        </div>
      </div>
    );
  }
}

export default inject("Auth")(observer(FutureInvoices))
